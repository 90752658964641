import {Component, Input} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service';
import {
  AccountActivityRuleModalData,
  AccountActivityRule,
  SourceTransactionTypeEnum,
  AdjustmentTransactionTypeEnum,
} from '../../accounting/service/accounting-models';

const sourceTransactionTypes = ['Credit', 'Charge', 'Credit Adjustment', 'Charge Adjustment'];
const STTypeLabels: Record<SourceTransactionTypeEnum, string> = {
  [SourceTransactionTypeEnum.Credit]: 'Credit',
  [SourceTransactionTypeEnum.CreditAdjustment]: 'Credit Adjustment',
  [SourceTransactionTypeEnum.Charge]: 'Charge',
  [SourceTransactionTypeEnum.ChargeAdjustment]: 'Charge Adjustment',
};
@Component({
  selector: 'account-activity-rule',
  templateUrl: './account-activity-rule.component.html',
  styleUrls: ['./account-activity-rule.component.scss'],
})
export class AccountActivityRuleComponent implements GlobalModalChild<AccountActivityRuleModalData>, AccountActivityRuleModalData {
  globalModalParent: GlobalModalParent;
  @Input() activityRules: AccountActivityRule[];
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() editMode: boolean;
  @Input() user;
  @Input() program;
  @Input() accountDefinitions;
  @Input() selectedAccountDefinition;
  _allChartOfAccounts;
  @Input() set allChartOfAccounts(allChartOfAccounts) {
    this._allChartOfAccounts = allChartOfAccounts;
    this.editActivityRules.destinationAccount = this.accountDefinitions.filter(
      acc => acc.id == this.editActivityRules.destinationAccountId
    );
    this.editActivityRules.destinationAccount = this.editActivityRules.destinationAccount[0];
    this.destinationChartOfAccounts = this.allChartOfAccounts.filter(
      coa => coa.id == this.editActivityRules.adjustmentTransactionChartOfAccounts
    );
    this.selectableDestinationSubCat = this.destinationChartOfAccounts[0].chargeCategories;
    for (const destSubcat of this.selectableDestinationSubCat) {
      const chosenSubcat = destSubcat.subCategories.filter(cat => cat.id == this.editActivityRules.adjustmentTransactionSubCategory);
      if (chosenSubcat.length > 0) {
        this.editActivityRules.adjustmentTransactionSubCategory = chosenSubcat[0];
      }
    }
  }
  get allChartOfAccounts() {
    return this._allChartOfAccounts;
  }
  _activityRule: AccountActivityRule;
  @Input() set activityRule(activityRule: AccountActivityRule) {
    this._activityRule = activityRule;
    this.editActivityRules = Object.assign({}, activityRule);
  }
  get activityRule() {
    return this._activityRule;
  }
  _chartOfAccount;
  @Input() set chartOfAccount(chartOfAccount: any) {
    this._chartOfAccount = chartOfAccount;
    this.editActivityRules.sourceTransactionType = this.editActivityRules.sourceTypeName;
    this.editActivityRules.adjustmentTransactionType = this.editActivityRules.destinationTypeName;
    this.editActivityRules.sourceTypeName = sourceTransactionTypes.includes(this.editActivityRules.sourceTransactionType)
      ? this.editActivityRules.sourceTransactionType
      : null;
    if (this.editActivityRules.sourceTypeName === STTypeLabels[SourceTransactionTypeEnum.Credit]) {
      this.selectableSourceSubCat = this.chartOfAccount.creditCategories;
      for (const sourceSubcat of this.selectableSourceSubCat) {
        const chosenSubcat = sourceSubcat.subCategories.filter(cat => cat.id == this.editActivityRules.sourceTransactionSubCategory);
        if (chosenSubcat.length > 0) {
          this.editActivityRules.sourceTransactionSubCategory = chosenSubcat[0];
        }
      }
    } else {
      this.selectableSourceSubCat = this.chartOfAccount.chargeCategories;
      for (const sourceSubcat of this.selectableSourceSubCat) {
        const chosenSubcat = sourceSubcat.subCategories.filter(cat => cat.id == this.editActivityRules.sourceTransactionSubCategory);
        if (chosenSubcat.length > 0) {
          this.editActivityRules.sourceTransactionSubCategory = chosenSubcat[0];
        }
      }
    }
  }
  get chartOfAccount() {
    return this._chartOfAccount;
  }
  editActivityRules: any;
  selectableSourceSubCat = null;
  selectableDestinationSubCat = null;
  destinationChartOfAccounts = null;

  // eslint-disable-next-line
  constructor() {}

  changeSourceTransactionType(type) {
    if (type === STTypeLabels[SourceTransactionTypeEnum.Credit]) {
      this.selectableSourceSubCat = this.chartOfAccount.creditCategories;
    } else {
      this.selectableSourceSubCat = this.chartOfAccount.chargeCategories;
    }
  }

  changeDestinationSource(account) {
    if (account && account.subledgerMappingId) {
      this.destinationChartOfAccounts = this.allChartOfAccounts.filter(coa => coa._id == account.subledgerMappingId);
      this.destinationChartOfAccounts = this.destinationChartOfAccounts[0];
      this.editActivityRules.adjustmentTransactionSubCategory = null;
      this.selectableDestinationSubCat = this.destinationChartOfAccounts.chargeCategories;
    }
  }

  compareValue(o1, o2) {
    if (o1._id == o2._id) {
      return true;
    } else {
      return false;
    }
  }
  compareText(o1, o2) {
    if (o1 == o2) {
      return true;
    } else {
      return false;
    }
  }

  cancel() {
    this.globalModalParent.closePopup();
  }

  delete() {
    if (this.editMode == true) {
      const accountIndex = this.selectedAccountDefinition.activityRules.findIndex(x => x.id === this.editActivityRules.id);
      this.selectedAccountDefinition.activityRules[accountIndex].deletedOn = new Date();
      this.selectedAccountDefinition.activityRules[accountIndex].deletedBy = this.user;
    }
    if (this.updateFunction) {
      this.updateFunction();
    }
    this.globalModalParent.closePopup();
  }

  onSubmit() {
    const newActivityRule: AccountActivityRule = {
      displayName: `${this.editActivityRules.sourceTransactionSubCategory.name} --> ${this.editActivityRules.destinationAccount.name}/${this.editActivityRules.adjustmentTransactionSubCategory.name}`,
      sourceAccount: this.selectedAccountDefinition.id,
      sourceTransactionType: this.editActivityRules.sourceTransactionType,
      sourceTransactionSubCategory: this.editActivityRules.sourceTransactionSubCategory._id,
      sourceTransactionChartOfAccounts: this.editActivityRules.sourceTransactionSubCategory.chartOfAccountsId,
      destinationAccount: this.editActivityRules.destinationAccount.id,
      adjustmentTransactionType: this.editActivityRules.adjustmentTransactionType,
      adjustmentTransactionSubCategory: this.editActivityRules.adjustmentTransactionSubCategory._id,
      adjustmentTransactionChartOfAccounts: this.editActivityRules.adjustmentTransactionSubCategory.chartOfAccountsId,
    };
    if (this.editMode == true) {
      const accountIndex = this.selectedAccountDefinition.activityRules.findIndex(x => x.id === this.editActivityRules.id);
      newActivityRule.modifiedBy = this.user;
      newActivityRule.modifiedOn = new Date();
      newActivityRule.id = this.editActivityRules.id;
      this.selectedAccountDefinition.activityRules[accountIndex] = newActivityRule;
    } else {
      newActivityRule.createdOn = new Date();
      newActivityRule.createdBy = this.user;
      this.selectedAccountDefinition.activityRules.push(newActivityRule);
    }
    if (this.updateFunction) {
      this.updateFunction();
    }
    this.globalModalParent.closePopup();
  }
}
