import {Component, OnInit, Injector, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormBuilder, FormArray, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EnhancedRentTransactions, Program} from '../../../housing-core/services/housing-models';
import {CoreService} from '../../../../core/service/core.service';
import {GlobalModalService} from '../../../../kanso-common/core/service/globalmodal.service';
import {ISubLedgerMap, AccountDefinitionType} from '../../../accounting/service/accounting-models';

@Component({
  selector: 'app-program-enhanced-rent',
  templateUrl: './program-enhanced-rent.component.html',
  styleUrls: ['./program-enhanced-rent.component.scss'],
})
export class ProgramEnhancedRentComponent implements OnInit {
  @Input('enhancedRentsTrx') enhancedRentBehaviorTransactions: EnhancedRentTransactions[];
  @Input('enhancedRentsAdj') enhancedRentBehaviorAdjustments: EnhancedRentTransactions[];
  @Input('program') program: Program;
  @Input('tenantLedgers') tenantLedgers;
  @Input('landlordLedger') landlordLedger;
  @Input('utilityLedger') utilityLedger;
  @Input('maintenanceLedger') maintenanceLedger;
  @Input('otherLedger') otherLedger;
  @Input('subLedgerMaps') subLedgerMaps: ISubLedgerMap[];
  @Output() enhancedRentChanged = new EventEmitter<any>();
  passbookRateFormGroup: FormGroup;
  minTTPFormGroup: FormGroup;
  allowedTransactionLedgerAccounts = [];
  allowedAdjustmentLedgerAccounts = [];
  allowedERBTransactionTypes = [];
  allowedERBTAdjustmentTypes = [];
  updatedProgram;
  selectedRentCalcOption;
  selectedRentCalcDetails = [];
  loading = true;
  isSaving = false;

  eRBAdjustmentform = this.fb.group({
    eRBAdjustments: this.fb.array([]),
  });
  eRBTransactionform = this.fb.group({
    eRBTransactions: this.fb.array([]),
  });

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public coreService: CoreService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (!this.program.enhancedRentToggle) {
      this.program.enhancedRentToggle = false;
    }
    this.loadEnhancedRentTransactions();
  }

  get eRBAdjustments() {
    return this.eRBAdjustmentform.controls.eRBAdjustments as FormArray;
  }

  get eRBTransactions() {
    return this.eRBTransactionform.controls.eRBTransactions as FormArray;
  }

  addERBTransaction(adjustmentFlag) {
    this.enhancedRentChanged.emit();
    if (adjustmentFlag) {
      const transactionForm = this.fb.group({
        ledgerType: [0, Validators.required],
        accountDefinition: [this.tenantLedgers[0].id, Validators.required],
        transactionType: [null, Validators.required],
        createdDay: ['1', [Validators.max(28), Validators.min(1)]],
        currentMonth: [true, Validators.required],
        adjustment: [true, Validators.required],
      });
      this.eRBAdjustments.push(transactionForm);
      this.allowedAdjustmentLedgerAccounts.push(this.tenantLedgers);
      this.allowedERBTAdjustmentTypes.push(this.subLedgerMaps.find(s => s._id == this.tenantLedgers[0].subledgerMappingId));
      this.enhancedRentBehaviorAdjustments.push({
        ledgerType: 0,
        accountDefinition: this.tenantLedgers[0].id,
        accountName: this.tenantLedgers[0].name,
        transactionType: this.allowedERBTAdjustmentTypes[0],
        createdDay: '1',
        currentMonth: true,
        adjustment: true,
      });
    } else {
      const transactionForm = this.fb.group({
        ledgerType: [0, Validators.required],
        accountDefinition: [this.tenantLedgers[0].id, Validators.required],
        transactionType: [null, Validators.required],
        createdDay: ['1', [Validators.max(28), Validators.min(1)]],
        currentMonth: [true, Validators.required],
        adjustment: [false, Validators.required],
      });
      this.eRBTransactions.push(transactionForm);
      this.allowedTransactionLedgerAccounts.push(this.tenantLedgers);
      this.allowedERBTransactionTypes.push(this.subLedgerMaps.find(s => s._id == this.tenantLedgers[0].subledgerMappingId));
      this.enhancedRentBehaviorTransactions.push({
        ledgerType: 0,
        accountDefinition: this.tenantLedgers[0].id,
        accountName: this.tenantLedgers[0].name,
        transactionType: this.allowedERBTransactionTypes[0],
        createdDay: '1',
        currentMonth: true,
        adjustment: false,
      });
    }
  }

  deleteERBTransaction(index: number, adjustmentFlag) {
    if (adjustmentFlag) {
      this.eRBAdjustments.removeAt(index);
      this.allowedAdjustmentLedgerAccounts.splice(index, index + 1);
      this.allowedERBTAdjustmentTypes.splice(index, index + 1);
    } else {
      this.eRBTransactions.removeAt(index);
      this.allowedTransactionLedgerAccounts.splice(index, index + 1);
      this.allowedERBTransactionTypes.splice(index, index + 1);
    }
    this.enhancedRentChanged.emit();
  }
  compareWith(x1: any, x2: any): boolean {
    return x1._id == x2.id;
  }

  compareWith2(x1: any, x2: any): boolean {
    return x1 == x2;
  }

  compareWithSubCategory(x1: any, x2: any): boolean {
    return x1.subCategoryName == x2.subCategoryName;
  }

  loadEnhancedRentTransactions() {
    for (const adjustment of this.enhancedRentBehaviorAdjustments) {
      const transactionForm = this.fb.group({
        ledgerType: [Number(adjustment.ledgerType), Validators.required],
        accountDefinition: [adjustment.accountDefinition, Validators.required],
        transactionType: [adjustment.transactionType, Validators.required],
        createdDay: [adjustment.createdDay, [Validators.max(31), Validators.min(1)]], // Validators.pattern("^[0-9]*$")
        currentMonth: [adjustment.currentMonth, Validators.required],
        adjustment: [true, Validators.required],
      });
      switch (Number(adjustment.ledgerType)) {
        case AccountDefinitionType.TENANT:
          this.allowedAdjustmentLedgerAccounts.push(this.tenantLedgers);
          break;
        case AccountDefinitionType.LANDLORD:
          this.allowedAdjustmentLedgerAccounts.push(this.landlordLedger);
          break;
        case AccountDefinitionType.UTILITY:
          this.allowedAdjustmentLedgerAccounts.push(this.utilityLedger);
          break;
        case AccountDefinitionType.MAINTENANCE:
          this.allowedAdjustmentLedgerAccounts.push(this.maintenanceLedger);
          break;
        case AccountDefinitionType.OTHER:
          this.allowedAdjustmentLedgerAccounts.push(this.otherLedger);
          break;
        default:
          this.allowedAdjustmentLedgerAccounts.push(this.tenantLedgers);
          break;
      }
      const selectedCoa = this.program.accountDefinitions.find(a => a.id == adjustment.accountDefinition);
      this.allowedERBTAdjustmentTypes.push(this.subLedgerMaps.find(s => s._id == selectedCoa.subledgerMappingId));
      this.eRBAdjustments.push(transactionForm);
    }
    for (const transaction of this.enhancedRentBehaviorTransactions) {
      const transactionForm = this.fb.group({
        ledgerType: [Number(transaction.ledgerType), Validators.required],
        accountDefinition: [transaction.accountDefinition, Validators.required],
        transactionType: [transaction.transactionType, Validators.required],
        createdDay: [transaction.createdDay, [Validators.max(31), Validators.min(1)]], // Validators.pattern("^[0-9]*$")
        currentMonth: [transaction.currentMonth, Validators.required],
        adjustment: [false, Validators.required],
      });
      switch (Number(transaction.ledgerType)) {
        case AccountDefinitionType.TENANT:
          this.allowedTransactionLedgerAccounts.push(this.tenantLedgers);
          break;
        case AccountDefinitionType.LANDLORD:
          this.allowedTransactionLedgerAccounts.push(this.landlordLedger);
          break;
        case AccountDefinitionType.UTILITY:
          this.allowedTransactionLedgerAccounts.push(this.utilityLedger);
          break;
        case AccountDefinitionType.MAINTENANCE:
          this.allowedTransactionLedgerAccounts.push(this.maintenanceLedger);
          break;
        case AccountDefinitionType.OTHER:
          this.allowedTransactionLedgerAccounts.push(this.otherLedger);
          break;
        default:
          this.allowedTransactionLedgerAccounts.push(this.tenantLedgers);
          break;
      }
      const selectedCoa = this.program.accountDefinitions.find(a => a.id == transaction.accountDefinition);
      this.allowedERBTransactionTypes.push(this.subLedgerMaps.find(s => s._id == selectedCoa.subledgerMappingId));
      this.eRBTransactions.push(transactionForm);
    }
  }

  onTrackById(index: number, item: FormGroup) {
    return index;
  }

  eRBTLedgerTypeChange(ledgerType, index, adjustmentFlag) {
    let accDef = null;
    this.enhancedRentChanged.emit();
    if (adjustmentFlag) {
      switch (ledgerType) {
        case AccountDefinitionType.TENANT:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.tenantLedgers;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.tenantLedgers[0].id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        case AccountDefinitionType.LANDLORD:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.landlordLedger;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.landlordLedger[0].id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        case AccountDefinitionType.UTILITY:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.utilityLedger;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.utilityLedger[0].id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        case AccountDefinitionType.OTHER:
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
          this.allowedAdjustmentLedgerAccounts[index] = this.otherLedger;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.otherLedger[0].id);
          this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        default:
          this.allowedAdjustmentLedgerAccounts[index] = this.tenantLedgers;
          break;
      }
    } else {
      switch (ledgerType) {
        case AccountDefinitionType.TENANT:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.tenantLedgers;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.tenantLedgers[0].id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        case AccountDefinitionType.LANDLORD:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.landlordLedger;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.landlordLedger[0].id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        case AccountDefinitionType.UTILITY:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.utilityLedger;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.utilityLedger[0].id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        case AccountDefinitionType.OTHER:
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('accountDefinition').setValue(null);
          (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
          this.allowedTransactionLedgerAccounts[index] = this.otherLedger;
          accDef = this.program.accountDefinitions.find(ac => ac.id == this.otherLedger[0].id);
          this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
          break;
        default:
          this.allowedTransactionLedgerAccounts[index] = this.tenantLedgers;
          break;
      }
    }
  }

  eRBTAccountDefinitionChange(accDefId, index, adjustmentFlag) {
    if (adjustmentFlag) {
      (<FormArray>this.eRBAdjustmentform.get('eRBAdjustments')).controls[index].get('transactionType').setValue(null);
      const accDef = this.program.accountDefinitions.find(ac => ac.id == accDefId);
      this.allowedERBTAdjustmentTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
      this.enhancedRentBehaviorAdjustments[index].accountName = accDef.name;
    } else {
      (<FormArray>this.eRBTransactionform.get('eRBTransactions')).controls[index].get('transactionType').setValue(null);
      const accDef = this.program.accountDefinitions.find(ac => ac.id == accDefId);
      this.allowedERBTransactionTypes[index] = this.subLedgerMaps.find(s => s._id == accDef.subledgerMappingId);
      this.enhancedRentBehaviorTransactions[index].accountName = accDef.name;
    }
    this.enhancedRentChanged.emit();
  }

  elementChanged() {
    this.enhancedRentChanged.emit();
  }

  eRBTDateChanged(t) {
    this.enhancedRentChanged.emit();
    if (t > 28) {
      t = 28;
    }
    if (t <= 0) {
      t = 1;
    }
  }
}
