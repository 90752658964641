import {Injectable} from '@angular/core';
import {
  BatchHeaderInput,
  BatchTrailerInput,
  FileHeaderInput,
  FileTrailerInput,
  VendorACH,
  NACHALineType,
  PPDAndCCDEntryInput,
  PPDCCDAndWEBAddendaRecordsInput,
  WebAndTelEntryInput,
  AuthorityACH,
  ACHTransactions,
  HousingAuthority,
  AuthorityData,
  VendorData,
  modifiedACHTransactionsObject,
  CheckingTransactionCodes,
  SavingTransactionCodes,
  ServiceClassCodes,
  SECcodes,
  vendorDataWithPlainTextKey,
} from './nacha-model';
import {
  BatchHeaderRecord,
  BatchTrailerRecord,
  FileHeaderRecord,
  FileTrailerRecord,
  PPDAndCCDEntry,
  PPDCCDAndWEBAddendaRecords,
  WebAndTelEntry,
} from './nacha.object';
import _ from 'lodash';
import {CoreService} from 'src/app/core/service/core.service';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {map, take} from 'rxjs/operators';
import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {EncryptionService} from './encryption.service';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';
import {AchService} from 'src/app/shared/services/ach-service';
import {dataWithPlainTextKey, decryptInputObject} from './encryption-model';

@Injectable({
  providedIn: 'root',
})
export class GenerateNACHAService extends BaseService {
  apollo: Apollo;
  constructor(
    public http: HttpClient,
    public coreService: CoreService,
    private apolloProvider: Apollo,
    public housingServices: HousingServices,
    public achService: AchService,
    public encryptionService: EncryptionService
  ) {
    super(http);
    this.apollo = this.apolloProvider;
    const headers: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...headers}},
      'nacha'
    );
  }

  //function to collect necessary data for buliding nacha file
  async ACHDatabuilder(
    ACHtransactions: ACHTransactions[]
  ): Promise<{
    housingAuthority: HousingAuthority;
    authorityACH: AuthorityACH;
    decryptedAuthority: AuthorityData;
    modifiedACHTransactionsObject: modifiedACHTransactionsObject;
  }> {
    try {
      //get HA data, HA AchData, & transaction data w/ Ach Data
      const [housingAuthorityData, [housingAuthorityACH, decryptedHaData], modifiedACHTransactionsDataObject] = await Promise.all([
        this.getHousingAuthorityData(),
        this.getHousingAuthorityAchData(),
        this.getModifiedACHTransactionsObject(ACHtransactions),
      ]);
      return {
        housingAuthority: housingAuthorityData,
        authorityACH: housingAuthorityACH,
        decryptedAuthority: decryptedHaData,
        modifiedACHTransactionsObject: modifiedACHTransactionsDataObject,
      };
    } catch (error) {
      console.log(error);
      this.coreService.displayError('There was an error when retrieving ACH information');
    }
  }

  private async getHousingAuthorityAchData(): Promise<[AuthorityACH, AuthorityData]> {
    const housingAuthorityACH = await this.achService.getAuthorityAch().toPromise();
    const decryptedHaData = await this.getDecryptedAuthorityData(
      housingAuthorityACH.id,
      housingAuthorityACH.dataKey,
      housingAuthorityACH.dataVector
    );
    return [housingAuthorityACH, decryptedHaData];
  }

  private async getHousingAuthorityData(): Promise<HousingAuthority> {
    //Help us store the IRHA informationin cache with take and automaticly destroy when we not using it;
    //Avoid perform this call mutiple time as well when user are trying to generate file mutiple time;
    return this.housingServices
      .getHousingAuthority()
      .pipe(take(1))
      .pipe(
        map(result => {
          const housingAuthority = result.body;
          const modifiedHousingAuthority: HousingAuthority = {
            name: housingAuthority.name,
            fedTaxId: housingAuthority.fedTaxId,
          };
          return modifiedHousingAuthority;
        })
      )
      .toPromise();
  }

  private async getDecryptedAuthorityData(id, dataKey, dataVector): Promise<AuthorityData> {
    const authorityDataRaw = await this.achService.getEncryptedAuthorityAch(id).toPromise();
    const authorityData = authorityDataRaw.node;
    //perform decryption action here before return the data;
    authorityData.tokenData = await this.encryptionService.decryptData(authorityData.tokenData, dataKey, dataVector);

    return authorityData;
  }

  // This can be remove if connect VendorACH to transactions;
  private async getModifiedACHTransactionsObject(ACHtransactions: ACHTransactions[]): Promise<modifiedACHTransactionsObject> {
    const idList: string[] = [];

    ACHtransactions.forEach(t => {
      idList.push(t.vendorId);
    });

    const uniqueVendorIdList = _.uniq(idList);

    const vendorAchData: VendorACH[] = await this.achService.getVendorACHData(uniqueVendorIdList).toPromise();

    const achIdList: string[] = [];

    vendorAchData.forEach(v => {
      achIdList.push(v.id);
    });

    // Kimono data
    const uniqVendorAchList: string[] = _.uniq(achIdList);
    const vendorData: VendorData[] = await this.achService.getEncryptedVendorsData(uniqVendorAchList).toPromise();

    const decryptInputObjects: decryptInputObject[] = vendorAchData
      .map(vendorAchObj => {
        const vendorDataObj = vendorData.find(v => v.id === vendorAchObj.id);
        if (vendorDataObj) {
          return {
            encryptedData: vendorDataObj.accountNumberData,
            dataKey: vendorAchObj.dataKey,
            dataVector: vendorAchObj.dataVector,
          };
        }
        return null;
      })
      .filter(obj => obj !== null) as decryptInputObject[];

    const decryptedObjects: dataWithPlainTextKey[] = await this.encryptionService.decryptDataCollection(decryptInputObjects);

    const decryptVendorDataCollection: vendorDataWithPlainTextKey[] = vendorAchData
      .map(vendorAchObj => {
        const vendorPlainTextDataObj = decryptedObjects.find(x => x.dataVector == vendorAchObj.dataVector);
        if (vendorPlainTextDataObj) {
          return {
            vendorId: vendorAchObj.vendorId,
            routingNumber: vendorAchObj.routingNumber,
            consumerAcct: vendorAchObj.consumerAcct,
            checkingAcct: vendorAchObj.checkingAcct,
            payTo: '',
            balanceToBePaid: 0,
            data: vendorPlainTextDataObj.data,
            dataVector: vendorAchObj.dataVector,
            plainTextKey: '',
          };
        }
        return null;
      })
      .filter(obj => obj !== null) as vendorDataWithPlainTextKey[];

    const ppdTransactionCollection = [];
    const ccdTransactionCollection = [];

    for (const data of decryptVendorDataCollection) {
      for (const transaction of ACHtransactions) {
        if (data.vendorId === transaction.vendorId) {
          data.payTo = transaction.payTo;
          data.balanceToBePaid += transaction.balanceToBePaid;
        }
      }

      if (data.consumerAcct) {
        ppdTransactionCollection.push(data);
      } else {
        ccdTransactionCollection.push(data);
      }
    }

    return {
      ppdTransactionCollection: ppdTransactionCollection,
      ccdTransactionCollection: ccdTransactionCollection,
    };
  }

  NACHAFileBuilder(
    housingAuthority: HousingAuthority,
    authorityACH: AuthorityACH,
    decryptedAuthority: AuthorityData,
    modifiedACHTransactionsObject: modifiedACHTransactionsObject, // Front end model only model
    batchId: number
  ) {
    try {
      let rowCount = 0;
      let NACHAFile = '';
      let countOfTransactionsInBatches = 0;
      let sumOfEntryHash = 0;
      let sumOfDebitInBatches = 0;
      let sumOfCreditInBatches = 0;
      let batchCount = 0;
      // File Header test
      const fileheaderCommand: FileHeaderInput = {
        immediateOriginName: housingAuthority.name.substring(0, 23), // housing authority name
        immediateDestinationName: authorityACH.odfiName, // Housing authority' bank name
        immediateOriginNumber: decryptedAuthority.tokenData, // Federal tax Id of housing authority 9 digits
        immediateDestinationRoutingNumber: authorityACH.odfiRoutingNumber, // Routing number of the banks
        fileIdModifier: 'A', //Alphanumeric field, upper-case A-Z and 0-9 allowed. Start with A and increment for each file created during the calendar day.
        // TODO: must create service to generate unique ID for this field('A-Z', '0-9')
      };
      rowCount += 1;
      NACHAFile += this.generateNACHALine(fileheaderCommand, NACHALineType.FileHeaderRecord);

      //For Vendor with PPD
      if (modifiedACHTransactionsObject.ppdTransactionCollection.length > 0) {
        batchCount += 1;
        const ppdNACHABatch = this.NACHABatchGenerator(
          batchId,
          authorityACH.odfiRoutingNumber,
          housingAuthority.name,
          decryptedAuthority.tokenData,
          modifiedACHTransactionsObject.ppdTransactionCollection,
          SECcodes.prearrangedPaymentOrDeposit,
          batchCount
        );
        rowCount += ppdNACHABatch.batchRowCount;
        sumOfEntryHash += ppdNACHABatch.batchEntryHash;
        sumOfDebitInBatches += ppdNACHABatch.sumOfDebit;
        sumOfCreditInBatches += ppdNACHABatch.sumOfCredit;
        countOfTransactionsInBatches += ppdNACHABatch.countOfTransactions;
        NACHAFile += ppdNACHABatch.NACHABatchFile;
      }

      //For Vendor with CCD
      if (modifiedACHTransactionsObject.ccdTransactionCollection.length > 0) {
        batchCount += 1;
        const ccdNACHABatch = this.NACHABatchGenerator(
          batchId,
          authorityACH.odfiRoutingNumber,
          housingAuthority.name,
          decryptedAuthority.tokenData,
          modifiedACHTransactionsObject.ccdTransactionCollection,
          SECcodes.corporateCreditOrDebit,
          batchCount
        );
        rowCount += ccdNACHABatch.batchRowCount;
        sumOfEntryHash += ccdNACHABatch.batchEntryHash;
        sumOfDebitInBatches += ccdNACHABatch.sumOfDebit;
        sumOfCreditInBatches += ccdNACHABatch.sumOfCredit;
        countOfTransactionsInBatches += ccdNACHABatch.countOfTransactions;
        NACHAFile += ccdNACHABatch.NACHABatchFile;
      }

      //File Trailer Record
      rowCount += 1;
      let remainderLine = 0;
      if (rowCount % 10 != 0) {
        remainderLine = 10 - (rowCount % 10);
        rowCount += remainderLine;
      }
      const fileTrailerCommand: FileTrailerInput = {
        batchCount: batchCount,
        blockCount: rowCount / 10, // Count of the number of blocks of 10 rows within the file
        entryOrAddendaCount: countOfTransactionsInBatches, // total transaction in all the batches within the file
        entryHash: sumOfEntryHash, // this the sum entryHash field in batch trailer
        totalDebitEntry: sumOfDebitInBatches,
        totalCreditEntry: sumOfCreditInBatches,
      };
      NACHAFile += `\n${this.generateNACHALine(fileTrailerCommand, NACHALineType.FileTrailerRecord)}`;
      if (remainderLine > 0) {
        for (let i = 0; i < remainderLine; i++) {
          NACHAFile += `\n${'9'.repeat(94)}`;
        }
      }

      return NACHAFile;
    } catch (error) {
      console.log(error);
    }
  }

  private NACHABatchGenerator(
    batchId: number,
    odfiRoutingNumber: string,
    housingAuthorityName: string,
    housingAuthorityToken: string,
    modifiedACHTransactions: vendorDataWithPlainTextKey[],
    secCodesType: string,
    batchOrder: number
  ) {
    let NACHABatchLine = '';
    let batchAreaRowCount = 0;
    let sumOfDebitInBatch = 0;
    const sumOfCreditInBatch = 0;
    let sumOfDfiIdentification = 0;
    const batchHeaderCommand: BatchHeaderInput = {
      serviceClassCode: ServiceClassCodes.debits, // must be one of Service class code enum and identifies whether the batch contains both debit and credit transactions, only credit transactions, or only debit transactions.
      companyName: housingAuthorityName.substring(0, 16),
      companyIdentification: housingAuthorityToken, // company account number
      standardEntryClassCode: secCodesType, // PPD or CCD
      companyEntryDescription: `ACH${batchId}`, // 'ACH' + batchId.toString
      effectiveEntryDate: new Date().toString(), // Can be change due the day this will being debit
      originatorStatusCode: '1', // can be "1" as always for ACH direct deposit need more verification
      originatingDFIIdentification: odfiRoutingNumber, //routing number but only take first 8
      batchNumber: batchOrder, // starting from 1 and increment for each batch
    };
    batchAreaRowCount += 1;
    NACHABatchLine += `\n${this.generateNACHALine(batchHeaderCommand, NACHALineType.BatchHeaderRecord)}`;

    modifiedACHTransactions.forEach((transaction, index) => {
      const PPDAndCCDEntryRecordCommand: PPDAndCCDEntryInput = {
        transactionCode: transaction.checkingAcct ? CheckingTransactionCodes.debit : SavingTransactionCodes.debit,
        receivingDFIIdentification: transaction.routingNumber, //routing number but only take first 8
        dFIAccountNumber: transaction.data, // account number of recipents
        amount: this.coreService.getAbsoluteValue(transaction.balanceToBePaid * 100),
        receivingIndividualOrCompanyName: transaction.payTo,
        traceNumber: transaction.routingNumber, // The first 8 digits of your Originating DFI number, followed by the entry detail sequence number
        checkDigit: transaction.routingNumber, // routing number but only take the last digits
        sequenceNumber: index + 1,
      };
      sumOfDebitInBatch += PPDAndCCDEntryRecordCommand.amount;
      sumOfDfiIdentification += Number(PPDAndCCDEntryRecordCommand.receivingDFIIdentification.substring(0, 8));
      batchAreaRowCount += 1;
      NACHABatchLine += `\n${this.generateNACHALine(PPDAndCCDEntryRecordCommand, NACHALineType.PPDAndCCDEntry)}`;
    });

    // Batch Trailer Record
    const batchTrailerCommand: BatchTrailerInput = {
      serviceClassCode: ServiceClassCodes.debits, // from researh ACH Direct Deposit are usually comprised of ACH credit transactions (200)
      entryOrAddendaCount: modifiedACHTransactions.length, // total transaction count
      entryHash: sumOfDfiIdentification, // this the sum of receiving DFI Identification on transactions;
      totalDebitEntry: sumOfDebitInBatch, // sum of debit amount in transaction, can be update if we also use debit
      totalCreditEntry: sumOfCreditInBatch, // sum of credit amount in transaction, if we have mutiple batches, this number will be sum diffrently by logic mock data have one set pf transactions;
      companyIdentification: housingAuthorityToken, // company account number
      originatingDFIIdentification: odfiRoutingNumber, //routing number but only take first 8
      batchNumber: batchOrder,
    };
    batchAreaRowCount += 1;
    NACHABatchLine += `\n${this.generateNACHALine(batchTrailerCommand, NACHALineType.BatchTrailerRecord)}`;

    return {
      batchRowCount: batchAreaRowCount,
      batchEntryHash: sumOfDfiIdentification,
      sumOfDebit: sumOfDebitInBatch,
      sumOfCredit: sumOfCreditInBatch,
      countOfTransactions: modifiedACHTransactions.length,
      NACHABatchFile: NACHABatchLine,
    };
  }

  generateNACHALine(
    inputCommand:
      | FileHeaderInput
      | FileTrailerInput
      | BatchHeaderInput
      | BatchTrailerInput
      | PPDAndCCDEntryInput
      | PPDCCDAndWEBAddendaRecordsInput
      | WebAndTelEntryInput,
    type: number
  ): string {
    let outputString;
    switch (type) {
      case NACHALineType.FileHeaderRecord:
        outputString = this.mergeStringFromObject(new FileHeaderRecord(<FileHeaderInput>inputCommand));
        break;
      case NACHALineType.FileTrailerRecord:
        outputString = this.mergeStringFromObject(new FileTrailerRecord(<FileTrailerInput>inputCommand));
        break;
      case NACHALineType.BatchHeaderRecord:
        outputString = this.mergeStringFromObject(new BatchHeaderRecord(<BatchHeaderInput>inputCommand));
        break;
      case NACHALineType.BatchTrailerRecord:
        outputString = this.mergeStringFromObject(new BatchTrailerRecord(<BatchTrailerInput>inputCommand));
        break;
      case NACHALineType.PPDAndCCDEntry:
        outputString = this.mergeStringFromObject(new PPDAndCCDEntry(<PPDAndCCDEntryInput>inputCommand));
        break;
      case NACHALineType.PPDCCDAndWEBAddendaRecords:
        outputString = this.mergeStringFromObject(new PPDCCDAndWEBAddendaRecords(<PPDCCDAndWEBAddendaRecordsInput>inputCommand));
        break;
      case NACHALineType.WebAndTelEntry:
        outputString = this.mergeStringFromObject(new WebAndTelEntry(<WebAndTelEntryInput>inputCommand));
        break;
    }
    return outputString;
  }

  private mergeStringFromObject(object): string {
    let combinationString = '';
    for (const key in object) {
      // Check if the property value is a string
      if (typeof object[key] === 'string') {
        combinationString += object[key];
      }
    }
    return combinationString;
  }

  queryHousingAuthorityAchs() {
    return gql`
      query HousingAuthorityAchs($siteId: String) {
        housingAuthorityAchs(where: {siteId: {eq: $siteId}}) {
          edges {
            node {
              id
              bankTokenDisplay
              dataKey
              dataVector
              odfiRoutingNumber
              odfiName
              achDescription
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      }
    `;
  }

  queryVendorAchsWithIds() {
    return gql`
      query VendorAchs($ids: [UUID!]!) {
        vendorAchs(where: {vendorId: {in: $ids}}) {
          edges {
            node {
              id
              accountNumberDisplay
              dataKey
              dataVector
              routingNumber
              consumerAcct
              checkingAcct
              customerId
              siteId
              vendorId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      }
    `;
  }
}
