import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {FetchResult} from '@apollo/client/core';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';

export interface TenantPortalConfigurationInput {
  id: string;
  bankPortalURL: string;
  paymentServicePrivateKey: string;
  paymentServicePublicKey: string;
  siteId: string;
}

export interface SageClientData {
  id: string;
  endpointUrl: string;
  companyId: string;
  dataKey: string;
  dataVector: string;
}

export interface EncryptedSageData {
  id: string;
  userId: string;
  userPassword: string;
  siteId: string;
  customerId: string;
}

export interface CreateSageDataCommand {
  endpointUrl: string;
  companyId: string;
  dataKey: string;
  dataVector: string;
  createdBy: string;
  userId: string;
  userPassword: string;
  customerId: string;
  siteId: string;
}
export interface UpdateSageDataCommand {
  id: string;
  endpointUrl: string;
  companyId: string;
  dataKey: string;
  dataVector: string;
  modifiedBy: string;
  userId: string;
  userPassword: string;
  customerId: string;
  siteId: string;
}

export class IntegrationsService extends BaseService {
  apollo: Apollo;
  _http: any;
  constructor(public http: HttpClient, private apolloProvider: Apollo) {
    super(http);
    this.apollo = this.apolloProvider;
    const commonHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
    };

    this.apollo.create(
      {
        cache: new InMemoryCache(),
        uri: `${sessionStorage.getItem('BASE_SERVICE_URI')}/tenantportal/graphql`,
        headers: {...commonHeaders},
      },
      'stripeIntegration'
    );

    const sageHeaders: any = {...commonHeaders, ...{'x-token': this.header.headers['x-token']}};
    this.apollo.create(
      {
        cache: new InMemoryCache(),
        uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'),
        headers: {...sageHeaders},
      },
      'sageIntegration'
    );

    const sageEncryptedHeaders: any = {
      ...commonHeaders,
      ...{'x-token': this.header.headers['x-token']},
      ...{'x-api-key': sessionStorage.getItem('KIMONO_SERVICE_API_KEY')},
    };
    this.apollo.create(
      {
        cache: new InMemoryCache(),
        uri: `${sessionStorage.getItem('KIMONO_SERVICE_URI')}/kimono/graphql`,
        headers: {...sageEncryptedHeaders},
      },
      'sageEncryptedData'
    );
  }

  getStripeData<T>(): Observable<any> {
    return this.apollo
      .use('stripeIntegration')
      .query({
        query: this.queryPaymentServicePublicKey(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          return response.data.paymentServicePublicKey[0];
        })
      );
  }

  queryPaymentServicePublicKey() {
    return gql`
      query PaymentServicePublicKey {
        paymentServicePublicKey {
          id
          siteId
          paymentServicePrivateKey
          paymentServicePublicKey
          bankPortalURL
        }
      }
    `;
  }

  updateTenantPortalConfiguration<T>(input: any): Observable<FetchResult<T>> {
    return this.apollo.use('stripeIntegration').mutate({
      mutation: gql`
        mutation SetPaymentProcessingKeys($input: ConfigurationInput) {
          setPaymentProcessingKeys(input: $input) {
            clientMutationId
            errorMessage
            isSuccessful
          }
        }
      `,
      variables: {
        input,
      },
    });
  }

  getSageClientData<T>(): Observable<any> {
    return this.apollo
      .use('sageIntegration')
      .query({
        query: this.querySageClientData(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          return response.data.sageClientData.edges[0];
        })
      );
  }

  querySageClientData() {
    return gql`
      query SageClientData {
        sageClientData {
          edges {
            cursor
            node {
              id
              endpointUrl
              companyId
              dataKey
              dataVector
            }
          }
        }
      }
    `;
  }

  getEncryptedSageClientData<T>(sageId): Observable<any> {
    return this.apollo
      .use('sageEncryptedData')
      .query({
        query: this.queryEncryptedSageClientData(),
        fetchPolicy: 'no-cache',
        variables: {
          ids: sageId,
        },
      })
      .pipe(
        map((response: any) => {
          return response.data.sageClientDataData.edges[0].node;
        })
      );
  }

  queryEncryptedSageClientData() {
    return gql`
      query SageClientDataData($ids: [UUID!]!) {
        sageClientDataData(where: {id: {in: $ids}}) {
          edges {
            node {
              id
              userId
              userPassword
              siteId
              customerId
            }
          }
        }
      }
    `;
  }

  createSageData<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('sageIntegration').mutate({
      mutation: gql`
        mutation CreateSageClientData($command: CreateSageClientDataCommandInput!) {
          createSageClientData(command: $command) {
            commandName
            status
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateSageData<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('sageIntegration').mutate({
      mutation: gql`
        mutation UpdateSageClientData($command: UpdateSageClientDataCommandInput!) {
          updateSageClientData(command: $command) {
            commandName
            status
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
}
