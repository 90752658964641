import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/globalmodal.service';
import * as moment from 'moment';
import {FeeTableValueObject} from 'src/app/shared/services/program.object';
import {FeeTableValue} from 'src/app/shared/services/program.model';

@Component({
  selector: 'app-rentcalc-option-popup',
  templateUrl: './rentcalc-option-popup.component.html',
  styleUrls: ['./rentcalc-option-popup.component.scss'],
})
export class RentcalcOptionPopupComponent implements GlobalModalChild<any> {
  @Input() index: number;
  @Input() chargeType: string;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  _rentCalcOption: any;
  @Input() set rentCalcOption(setRentCalcOption: any) {
    this._rentCalcOption = setRentCalcOption;
    this.editRentCalcOption = Object.assign({}, setRentCalcOption);
    if (this.editRentCalcOption.feeTableValues.length !== 11) {
      const feeTableValuesArray = [];
      for (let index = 0; index < 11; index++) {
        const existFee = this.editRentCalcOption.feeTableValues.find((ftv: FeeTableValue) => ftv.householdSize == index);
        if (existFee !== undefined && index == existFee.householdSize) {
          feeTableValuesArray.push(existFee);
        } else {
          feeTableValuesArray.push(new FeeTableValueObject(index));
        }
      }
      this.editRentCalcOption.feeTableValues = feeTableValuesArray;
    }
  }

  get rentCalcOption() {
    return this._rentCalcOption;
  }

  editRentCalcOption;
  globalModalParent: GlobalModalParent;

  getHeader() {
    switch (this.chargeType) {
      case 'AdminFees':
        return 'Admin Fee Details';
      case 'MinimumCharges':
        return 'Minimum Rent Details';
      case 'MaximumCharges':
        return 'Maximum Charge Details';
      case 'UtilityAllowances':
        return 'Utility Allowance Details';
      case 'FairMarketRents':
        return ' Fair Market Rent Details';
      case 'FlatRents':
        return 'Flat Rent Details';
    }
    return;
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  save() {
    this.rentCalcOption = Object.assign(this._rentCalcOption, this.editRentCalcOption);
    this.rentCalcOption.effectiveDate = moment(this.rentCalcOption.effectiveDate).format('YYYY/MM/DD');
    this.updateFunction(this.rentCalcOption, this.index);
    this.closeDialog();
  }
}
