import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CoreService} from 'src/app/core/service/core.service';
import {FeatureConfigurationsService, SageExport} from 'src/app/shared/services/feature-configurations.service';

@Component({
  selector: 'app-month-end',
  templateUrl: './month-end.component.html',
  styleUrls: ['./month-end.component.scss'],
})
export class MonthEndComponent {
  @Input() statement;
  @Output() sageToggleSet = new EventEmitter<boolean>();
  sageToggle = null;
  isBusy = false;
  busyText = '';
  error = false;
  posted = false;
  private SiteId = sessionStorage.getItem('SITEID') || '';
  private CustomerId = sessionStorage.getItem('CUSTOMERID') || '';

  constructor(private featureConfigurationsService: FeatureConfigurationsService, public coreService: CoreService) {
    this.getToggleInformation();
  }

  sendRequest() {
    this.isBusy = true;
    this.busyText = 'Sending Sage Export, please wait.';
    const endOfMonth = new Date(this.statement.statementYear, this.statement.statementMonth + 1, 0).getDate();
    try {
      this.featureConfigurationsService.querySageData().subscribe(result => {
        if (result && result.length > 0) {
          const command: SageExport = {
            sageClientDataId: result[0].id,
            siteId: this.SiteId,
            customerId: this.CustomerId,
            periodStart: `${this.statement.statementMonth}/1/${this.statement.statementYear}`,
            periodEnd: `${this.statement.statementMonth}/${endOfMonth}/${this.statement.statementYear}`,
          };
          this.featureConfigurationsService.sendSageExport(command).subscribe(
            result => {
              if (result && result.length > 0) {
                this.isBusy = false;
                this.posted = true;
              } else {
                this.isBusy = false;
                this.coreService.displayError('Error: no Sage response received.');
              }
            },
            error => {
              this.isBusy = false;
              this.coreService.displayError(`Error sending Sage Export: ${error.error}`);
            }
          );
        } else {
          this.isBusy = false;
          this.coreService.displayError('Error: Sage Information not found');
        }
      });
    } catch (error) {
      this.isBusy = false;
    }
  }

  getToggleInformation() {
    if (this.sageToggle == null) {
      this.featureConfigurationsService.getFeatureConfigurations().subscribe(result => {
        this.featureConfigurationsService.getConfigurationsValuesBySiteId(this.SiteId).subscribe(configValues => {
          const featureConfig = result[0].find(fc => fc.subdomain == 'sageIntacct');
          if (featureConfig) {
            const selectedValue = configValues[0].find(config => config.featureConfigurationId === featureConfig.id);
            if (selectedValue && featureConfig.subdomain == 'sageIntacct' && selectedValue.booleanValue == true) {
              this.sageToggle = true;
              this.sageToggleSet.emit(true);
            } else {
              this.sageToggle = false;
            }
          }
        });
      });
    }
  }
}
