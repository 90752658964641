import {Component, Input, OnChanges, Injector, SimpleChanges, OnInit} from '@angular/core';
import {GlobalModalService, GlobalModalParent, GlobalModalChild} from 'src/app/kanso-common/core/service/globalmodal.service';
import {ControlContainer, NgForm} from '@angular/forms';

import {AccountingService} from '../../../accounting/service/accounting.service';
import {CoreService} from 'src/app/core/service/core.service';
import {
  Authority,
  Program,
  Project,
  IRentCalcDetails,
  Unit,
  UnitModalData,
  UnitUiSections,
  Vendor,
} from '../../../housing-core/services/housing-models';
import {UnitSetupComponent} from '../unit-setup/unit-setup.component';
import _ from 'lodash';
import {ComplexSaveResult, CustomAttribute, CustomAttributesValue, ICustomAttributesValue} from 'src/app/core/service/core-models';
import {HousingServices} from '../../../housing-core/services/housing-services.service';
import {ProgramsService} from 'src/app/shared/services/programs.service';

export interface augmentedVendor extends Vendor {
  name: string;
}
@Component({
  selector: 'unit-setup-handler',
  templateUrl: './unit-setup-handler.component.html',
  styleUrls: ['./unit-setup-handler.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class UnitSetupHandlerComponent implements GlobalModalChild<UnitModalData>, OnInit {
  globalModalParent: GlobalModalParent;
  @Input() editMode: boolean;
  _unit: Unit;
  @Input() set unit(setUnit: Unit) {
    this._unit = setUnit;
    this.editUnit = Object.assign({}, setUnit);
  }
  get unit() {
    return this._unit;
  }
  _unitId: string;
  @Input() set unitId(setUnitId: string) {
    this._unitId = setUnitId;
  }
  get unitId() {
    return this._unitId;
  }
  editUnit: Unit;
  programList: Program[];
  projectList: Project[];
  defaultRentCalc: IRentCalcDetails;
  housingAuthority: Authority;
  isBusy = true;
  busyText = 'Loading Unit';
  unitPathProgramId: string;
  unitPathProjectId: string;
  unitPathUnitId: string;
  unitPathCustomAttributeFlag: string;

  constructor(
    public accountingService: AccountingService,
    public programService: ProgramsService,
    public housingServices: HousingServices,
    public coreService: CoreService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {}

  ngOnInit(): void {
    console.log('handler init');
    this.LoadEditScreen();
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  async LoadEditScreen() {
    try {
      const unitPath = window.location.pathname.split('/');
      switch (true) {
        case unitPath.length == 4:
          this.unitPathUnitId = unitPath[2];
          break;
        case unitPath.length == 5 && unitPath[4] == 'all-units':
          this.unitPathProgramId = '';
          this.unitPathProjectId = '';
          this.unitPathUnitId = 'Create';
          this.unitPathCustomAttributeFlag = '';
          break;
        default:
          this.unitPathProgramId = unitPath[3];
          this.unitPathProjectId = unitPath[4];
          this.unitPathUnitId = unitPath[5];
          this.unitPathCustomAttributeFlag = unitPath[6];
      }
      if (this.unitPathUnitId == 'Create') {
        this.setBusy('Creating New Unit');
        this.unit = new Unit();
        await this.getProgramProjectData();
      } else {
        this.setBusy('Loading Unit');
        await this.getProgramProjectData();
        await this.getUnit();
        this.defaultRentCalc = await this.accountingService.getDefaultRentCalc(this.unit);
      }
      await this.getHousingAuthority();
      this.isBusy = false;
      this.openEditUnit(this.unit);
    } catch (error) {
      console.log(error);
    }
  }

  async getUnit() {
    try {
      const result = await this.accountingService.getUnit(this.unitPathUnitId).toPromise();
      this.unit = Object.assign({}, result[0]);
    } catch (error) {
      this.coreService.displayError(error, this.redirect, 'Error fetching unit details');
    }
  }

  async getHousingAuthority() {
    try {
      const result = await this.housingServices.getHousingAuthority().toPromise();
      this.housingAuthority = result.body;
    } catch (error) {
      this.coreService.displayError(error, this.redirect, 'Error fetching Housing Authority data');
    }
  }

  async getProgramProjectData() {
    try {
      const programResult = await this.programService.getPrograms().toPromise();
      this.programList = programResult;
      const projectResult = await this.accountingService.getAllProjects().toPromise();
      this.projectList = projectResult.body;
    } catch (error) {
      this.coreService.displayError(error, this.redirect, 'Error fetching Program and Project data');
    }
  }

  openEditUnit(unit?: Unit) {
    try {
      this.globalModalService.openModal<UnitModalData>(UnitSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
        unit: this.unit,
        editMode: unit.id ? true : false,
        programs: this.programList,
        projects: this.projectList,
        housingAuthority: this.housingAuthority,
        defaultRentCalc: this.defaultRentCalc,
        openPanel: this.unitPathCustomAttributeFlag ? UnitUiSections.CustomAttributes : UnitUiSections.GeneralInfo,
        cancelFunction: this.cancel,
        updateFunction: this.saveUnit,
      });
    } catch (error) {
      console.log(error);
    }
  }

  cancel = () => {
    this.redirect();
  };

  saveUnit = async (unit: Unit) => {
    let isError = false;
    try {
      //convert existing custom attribute records to CustomAttribute values
      this.setBusy('Saving Unit');
      this.unit.customAttributes = this.accountingService.convertAttributes(this.unit.customAttributes, this.unit.id);
      unit.customAttributes = this.accountingService.convertAttributes(unit.customAttributes, this.unit.id);

      const response: ComplexSaveResult = await this.accountingService.saveUnit(unit, this.unit.id == '' ? null : this.unit);

      if (!response.success) {
        isError = true;
        this.coreService.displayError(response.errorMessage, this.redirect, 'Error saving unit');
      }
    } catch (error) {
      this.coreService.displayError(error, this.redirect, 'Error saving unit');
    } finally {
      this.isBusy = false;
      if (!isError) {
        this.redirect();
      }
    }
  };

  redirect = () => {
    if (this.unitPathUnitId && this.unitPathUnitId != '' && this.unitPathUnitId != 'Create') {
      window.location.href = `${window.location.origin}/units/${this.unitPathUnitId}/overview`;
    } else {
      window.location.href = `${window.location.origin}/occupancy/units/reports-center/all-units`;
    }
  };
}
