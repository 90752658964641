<section class="tabs__panel layout-column integrations-container">
    <busy-notice
        [isBusy]="isBusy"
        [busyText]="busyText"
        headerText="KANSO">
    </busy-notice>
    <div class="page-header">
        <h2>Integration Configurations</h2>
    </div>
    <div *ngIf="error" class="layout-row">
        {{errorMsg}}
    </div>
    <form #editIntegrationsForm="ngForm">
        <div *ngIf="!isBusy && !error"> 
            <div *ngIf="showStripeInt" class="section-header layout-row removeSectionHeader">
                <a class="account__details-collapse">
                    <mat-icon class="collapse-icon" 
                        [ngClass]="{'collapse-icon--open' : tenantPortalConfigurationPanel}"
                        (click)="tenantPortalConfigurationPanel = !tenantPortalConfigurationPanel">chevron_right
                    </mat-icon>
                </a>
                <div class="rowPaddingLeft">
                    Tenant Portal Configuration
                </div>
            </div>
            <mat-expansion-panel *ngIf="showStripeInt" [expanded]="tenantPortalConfigurationPanel" class="hidePanel">
                <div class="togglePadding">
                    <mat-slide-toggle
                        [(ngModel)]="isStripe"
                        name ="isStripe">
                        {{isStripe ? 'Intake payments via Stripe' : 'Intake payments via custom bank portal'}}
                    </mat-slide-toggle>
                </div>
                <div *ngIf = "isStripe" class="rowPaddingLeft">
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="largeInputBox">
                            <mat-label>Stripe Publishable API Key</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="tenantPortalConfiguration.paymentServicePublicKey"
                                #publicKey="ngModel"
                                name="publicKey"
                                id="publicKeyInput" 
                                aria-label="Payment Service Public Key">
                        </mat-form-field>
                    </div>
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="largeInputBox">
                            <mat-label>Stripe Secret API Key</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="tenantPortalConfiguration.paymentServicePrivateKey"
                                #privateKey="ngModel"
                                name="privateKey"
                                id="privateKey" 
                                aria-label="Payment Service Private Key">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf = "!isStripe" class="rowPaddingLeft">
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="largeInputBox">
                            <mat-label>Bank Payment Portal URL</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="tenantPortalConfiguration.bankPortalURL"
                                #bankPortalURL="ngModel"
                                name="bankPortalURL"
                                id="bankPortalURLInput" 
                                aria-label="Bank Portal URL">
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
            <div *ngIf="showSageInt" class="section-header layout-row removeSectionHeader">
                <a class="account__details-collapse">
                    <mat-icon class="collapse-icon" 
                        [ngClass]="{'collapse-icon--open' : sageIntacctConfigurationsPanel}"
                        (click)="sageIntacctConfigurationsPanel = !sageIntacctConfigurationsPanel">chevron_right
                    </mat-icon>
                </a>
                <div class="rowPaddingLeft">
                    Sage Intacct Configuration
                </div>
            </div>
            <mat-expansion-panel *ngIf="showSageInt" [expanded]="sageIntacctConfigurationsPanel" class="hidePanel">
                <div class="layout-row rowPaddingLeft">
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="medInputBox">
                            <mat-label>Web Services Sender ID</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="sageEncryptedData.senderId"
                                #senderId="ngModel"
                                name="senderId"
                                id="senderIdInput" 
                                aria-label="Sender ID">
                        </mat-form-field>
                    </div>
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="medInputBox">
                            <mat-label>Sender Password</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="sageEncryptedData.senderPassword"
                                #senderPassword="ngModel"
                                name="senderPassword"
                                id="senderPasswordInput" 
                                aria-label="Sender Password">
                        </mat-form-field>
                    </div>
                </div>
                <div class="layout-row rowPaddingLeft">
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="medInputBox">
                            <mat-label>User Id</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="sageEncryptedData.userId"
                                #userId="ngModel"
                                name="userId"
                                id="userIdInput" 
                                aria-label="User ID">
                        </mat-form-field>
                     </div>
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="medInputBox">
                            <mat-label>User Password</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="sageEncryptedData.userPassword"
                                #userPassword="ngModel"
                                name="userPassword"
                                id="userPassword" 
                                aria-label="User Password">
                        </mat-form-field>
                    </div>
                </div>
                <div class="layout-row rowPaddingLeft">
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="medInputBox">
                            <mat-label>Company ID</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="sageClientData.companyId"
                                #companyId="ngModel"
                                name="companyId"
                                id="companyId" 
                                aria-label="Company ID">
                        </mat-form-field>
                    </div>
                </div>
                <div class="layout-row rowPaddingLeft">
                    <div class="layout-align-start-center">
                        <mat-form-field appearance="outline" class="largeInputBox">
                            <mat-label>End Point URL</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="sageClientData.endpointUrl"
                                #endpointUrl="ngModel"
                                name="endpointUrl"
                                id="endpointUrl" 
                                aria-label="End Point URL">
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <mat-card-actions>
            <button
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancelIntegrationsForm()" 
                [disabled]="editIntegrationsForm.pristine"
                id="cancelButton">
                Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"
                (click)="saveIntegrationsForm()"
                [disabled]="!editIntegrationsForm.valid || editIntegrationsForm.pristine"
                id="saveButton">
                Save
            </button>
        </mat-card-actions>
    </form>
</section>
