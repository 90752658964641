import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {Authority} from 'src/app/custom/housing-core/services/housing-models';
import {ProgramFeeTable, ProgramSettings} from 'src/app/shared/services/program.model';
import {ProgramFeeStatus, ProgramFeeTableObject} from 'src/app/shared/services/program.object';
import {RentcalcOptionPopupComponent} from '../../rentcalc-option-popup/rentcalc-option-popup.component';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import _ from 'lodash';

@Component({
  selector: 'app-program-rent-calc',
  templateUrl: './program-rent-calc.component.html',
  styleUrls: ['./program-rent-calc.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ProgramRentCalcComponent implements OnInit {
  @Input('rentCalc') rentCalc: ProgramSettings;
  @Input('programId') programId: string;
  @Input('programType') programType: string;
  @Input('housingAuthority') housingAuthority: Authority;
  selectedRentCalcFeeTables: ProgramFeeTable[];
  selectedRentCalcOption: string;
  displayedColumns = [
    'effectiveDate',
    'bedroom0',
    'bedroom1',
    'bedroom2',
    'bedroom3',
    'bedroom4',
    'bedroom5',
    'bedroom6',
    'bedroom7',
    'bedroom8',
    'action',
  ];
  bedroomSizes = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  selectedProgramFeeTableSnapShot?: ProgramFeeTable;

  constructor(public globalModalService: GlobalModalService, protected injector: Injector) {}
  // eslint-disable-next-line
  ngOnInit(): void {}

  validateInput(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const input = event.key;

    // Prevent any input that is not a digit or a decimal point
    if (!allowedKeys.includes(input)) {
      event.preventDefault();
    }

    // Prevent more than one decimal point
    const currentValue = (event.target as HTMLInputElement).value;
    if (input === '.' && currentValue.includes('.')) {
      event.preventDefault();
    }
  }

  changeDisplayedRentCalcOption() {
    this.selectedRentCalcFeeTables = this.rentCalc.programFeeTable.filter(
      pft => pft.feeTableType == this.selectedRentCalcOption && pft.status != ProgramFeeStatus.Delete
    );
  }

  newRentCalcOption() {
    this.globalModalService.openModal(RentcalcOptionPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      chargeType: this.selectedRentCalcOption,
      rentCalcOption: new ProgramFeeTableObject(this.selectedRentCalcOption),
      updateFunction: (pft: ProgramFeeTable) => {
        pft.id = this.generateId();
        pft.status = ProgramFeeStatus.Create;
        this.rentCalc.programFeeTable.push(pft);
        this.changeDisplayedRentCalcOption();
      },
    });
  }

  openRentCalcOptionsDialog(index) {
    this.selectedProgramFeeTableSnapShot = _.cloneDeep(this.selectedRentCalcFeeTables[index]);
    this.selectedProgramFeeTableSnapShot.feeTableValues.sort((a, b) => a.householdSize - b.householdSize);
    this.selectedProgramFeeTableSnapShot.effectiveDate = new Date(this.selectedProgramFeeTableSnapShot.effectiveDate);
    this.globalModalService.openModal(RentcalcOptionPopupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      chargeType: this.selectedRentCalcOption,
      rentCalcOption: this.selectedProgramFeeTableSnapShot,
      updateFunction: (pft?: ProgramFeeTable) => {
        this.rentCalc.programFeeTable = this.rentCalc.programFeeTable.map(pt => {
          if (pt.id == pft.id) {
            if (pft.id.includes('id-to-delete')) {
              pft.status = ProgramFeeStatus.CreateAndModified;
            } else {
              pft.status = ProgramFeeStatus.Modified;
            }
            return pft;
          }
          return pt;
        });
        this.changeDisplayedRentCalcOption();
        this.selectedProgramFeeTableSnapShot = null;
      },
    });
  }

  deleteRentCalcOptions(index) {
    const pft = this.selectedRentCalcFeeTables[index];
    if (pft.id.includes('id-to-delete')) {
      this.rentCalc.programFeeTable = this.rentCalc.programFeeTable.filter(pt => pt.id !== pft.id);
    } else {
      this.rentCalc.programFeeTable = this.rentCalc.programFeeTable.map(pt => {
        if (pt.id == pft.id) {
          pft.status = ProgramFeeStatus.Delete;
          return pft;
        }
        return pt;
      });
    }
    this.changeDisplayedRentCalcOption();
  }

  getAmount(element: ProgramFeeTable, size: number): number {
    const value = element.feeTableValues.find((v: any) => v.householdSize === size);
    return value ? value.amount : 0;
  }

  generateId() {
    return 'id-to-delete' + Date.now() + '-' + Math.floor(Math.random() * 1000);
  }
}
