/* eslint-disable no-sequences */
import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {FetchResult} from '@apollo/client/link/core/types';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
//import { string } from 'bson';

import * as lodash from 'lodash';
import {
  MongoResults,
  MongoReport,
  Query,
  KansoActionResult,
  ICustomAttributesValue,
  CustomAttributesValue,
  ComplexSaveResult,
  CustomAttribute,
} from '../../../core/service/core-models';
import {CoreService} from '../../../core/service/core.service';
import {BaseService} from '../../../kanso-common/core/service/base.service';
import {
  TenantLedgeReport,
  AccountRecord,
  HouseholdAction,
  ChargeAndPaymentHistory,
  RepaymentAgreement,
  Transaction,
  TransactionRequestType,
  LoanTermDetails,
  TransactionQuery,
  ISubLedgerMap,
  Deposit,
  GeneralLedgerAccount,
  UnitSortFields,
  ActionFormQuery,
  ActionFormsArray,
} from './accounting-models';
import {
  IEffectiveByBedroom,
  ICreateUnitAttributeCommand,
  ICreateUnitFeeCommand,
  ICreateUnitOverrideCommand,
  IDeleteUnitOverrideCommand,
  IUnitAttributeCommand,
  IUnitOverrideCommand,
  IUpdateUnitAttributeCommand,
  IUpdateUnitOverrideCommand,
  Program,
  Project,
  RentCalc,
  IRentCalcDetails,
  UnitOverride,
  Unit,
  UnitCreateCommand,
  UnitUpdateCommand,
  UnitRentCalc,
  IUnitOverride,
  IUpdateUnitFeeCommand,
  IDeleteUnitFeeCommand,
  IUnit,
  IUnitFee,
} from '../../housing-core/services/housing-models';
import _ from 'lodash';
import {ProgramsService} from 'src/app/shared/services/programs.service';

export interface IDataResponse {
  status: string;
  failureReason?: string;
}
export interface IUnitOverrideDataResponse extends IDataResponse {
  affectedEntity: IUnitOverride;
}

export interface IUnitDataResponse extends IDataResponse {
  affectedEntity: IUnit;
}

export interface IUpdateUnitDataResponse {
  updateUnit: IUnitDataResponse;
}
export interface ICreateUnitDataResponse {
  createUnit: IUnitDataResponse;
}
export interface IUpdateUnitAttributeDataResponse {
  updateUnitAttributeValue: IDataResponse;
}
export interface ICreateUnitAttributeDataResponse {
  createUnitAttributeValue: IDataResponse;
}
export interface ICreateUnitOverrideDataResponse {
  createUnitOverride: IUnitOverrideDataResponse;
}

export interface IUpdateUnitOverrideDataResponse {
  updateUnitOverride: IUnitOverrideDataResponse;
}

export interface IDeleteUnitOverrideDataResponse {
  deleteUnitOverride: IUnitOverrideDataResponse;
}

export interface IUnitFeeDataResponse extends IDataResponse {
  affectedEntity: IUnitFee;
}
export interface ICreateUnitFeeDataResponse {
  createUnitFee: IDataResponse;
}

export interface IUpdateUnitFeeDataResponse {
  updateUnitFee: IDataResponse;
}

export interface IDeleteUnitFeeDataResponse {
  deleteUnitFee: IDataResponse;
}

@Injectable({providedIn: 'root'})
export class AccountingService extends BaseService {
  apollo: Apollo;
  _http: any;

  constructor(
    public http: HttpClient,
    public coreService: CoreService,
    private apolloProvider: Apollo,
    public programService: ProgramsService
  ) {
    super(http);
    this.apollo = this.apolloProvider;
    const vendorHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...vendorHeaders}},
      'unit'
    );
  }
  apiTransactions = '/api/accounting/transactions/';
  envDat = {
    siteId: sessionStorage.getItem('SITEID'),
    customerId: sessionStorage.getItem('CUSTOMERID'),
  };

  // getLedgerAccounts(filterSet: object): Observable<MongoReport<TenantLedgeReport>> {
  //   return this.coreService.getReport<TenantLedgeReport>({ reportName: 'TenantLedger', filterSet });
  //      return this.coreService.getReport<TenantLedgeReport>({ reportName: 'tenant-ledger-report', filterSet }).pipe(
  //        map(res => this.markPrimaryAccounts(res)));
  //  }

  markPrimaryAccounts(tenantLedgerAccounts: TenantLedgeReport[]): TenantLedgeReport[] {
    // Mark isPrimary as false on each tlr since the isPrimary used for the UI logic is different. Name collision.
    tenantLedgerAccounts.forEach(report => (report.isPrimary = false));
    const ledgerMarkedAccounts = tenantLedgerAccounts.map(report => {
      // Tenant is always primary
      if (report.unitTenantStatus === 'Tenant' || this._isAssigned(report)) {
        report.sortOrder = 0;
        report.isPrimary = true;
      }
      // If there is not unit, but there is an active loan, it is primary
      if (lodash.isNil(report.unit)) {
        report.sortOrder = 1;
        report.isPrimary = this.isActiveLoan(report);
      }
      // Each record is primary if the report itself is, and it is an AR account
      report.accountRecords.forEach((record: AccountRecord) => {
        record.isPrimary = report.isPrimary === true && record.isAR === true;
      });
      return report;
    });
    return lodash.orderBy(ledgerMarkedAccounts, ['sortOrder', 'moveOutDate'], ['asc', 'desc']);
  }

  _isAssigned(report) {
    if (report.householdActions.some(a => a.action === 'Move Out' && !a.deletedOn)) {
      return false;
    }
    return report.householdActions.some(a => a.action === 'Assignment' && !a.deletedOn);
  }

  isActiveLoan(report) {
    const loanAssignment = report.householdActions.find(a => a.action === 'Loan Assignment');
    if (!loanAssignment) {
      return false;
    } // Ledger has no unit, and no active loan.
    if (!loanAssignment || !loanAssignment.term || !loanAssignment.term.details) {
      return false;
    } // Loan assignment has no details
    if (loanAssignment.term.details.stopOn) {
      return false;
    } // Loan assignment is stopped
    const associatedAccount = report.accountRecords.find(a => a.accountId === loanAssignment.term.details.principalAccountId);
    if (!associatedAccount) {
      return false;
    } // No principal account on a loan assignment action
    if (associatedAccount.closingBalance === 0) {
      return false;
    } // No balance, loan inactive.
    return true;
  }

  setCurrentLoan(actionId: string, householdId: string, loanAction) {
    const loan = this.chargeAndPaymentHistoryReport(actionId, householdId);
  }

  chargeAndPaymentHistoryReport(actionId: string, householdId: string) {
    return this.apiPost<MongoResults<ChargeAndPaymentHistory>>(`/api/accounting/loan/history`, {
      filterSet: {
        householdId: householdId,
        actionId: actionId,
      },
    });
  }

  getAllPrograms(): Observable<KansoActionResult<Program[]>> {
    return this.apiGet<KansoActionResult<Program[]>>(`${this.apiPath}/accounting/api/programs`);
  }

  getProgram(id: string): Observable<KansoActionResult<Program>> {
    return this.apiGet<KansoActionResult<Program>>(`${this.apiPath}/accounting/api/programs/${id}`);
  }

  // updateProgram(program: Program): Observable<KansoActionResult<Program>> {
  //   return this.apiPut<KansoActionResult<Program>>(`${this.apiPath}/accounting/api/programs/${program._id}`, program);
  // }

  // createProgram(program: Program): Observable<KansoActionResult<Program>> {
  //   return this.apiPost<KansoActionResult<Program>>(`${this.apiPath}/accounting/api/programs`, program);
  // }

  // getProjectNames(): Observable<Project[]> {
  //   return this.apiGet<Project[]>('/api/projects/names');
  // }

  getAllProjects(): Observable<KansoActionResult<Project[]>> {
    return this.apiGet<KansoActionResult<Project[]>>(`${this.apiPath}/accounting/api/projects`);
  }

  getProject(id: string): Observable<KansoActionResult<Project>> {
    return this.apiGet<KansoActionResult<Project>>(`${this.apiPath}/accounting/api/projects/${id}`);
  }

  updateProject(project: Project): Observable<KansoActionResult<Project>> {
    return this.apiPut<KansoActionResult<Project>>(`${this.apiPath}/accounting/api/projects/${project._id}`, project);
  }

  createProject(project: Project): Observable<KansoActionResult<Project>> {
    return this.apiPost<KansoActionResult<Project>>(`${this.apiPath}/accounting/api/projects`, project);
  }

  getProjectsByProgram(id: string): Observable<Project[]> {
    return this.apiPost<[Project]>('/api/projects/getprojectbyprogram', {programId: id});
  }

  getAmortization(householdId: string, actionId: string) {
    return this.apiPost<any>(`/api/accounting/loan/amortization/${householdId}/${actionId}`);
  }

  stopLoan(householdId: string, actionId: string) {
    return this.apiDelete<any>(`/api/accounting/accounts/${householdId}/${actionId}/stop-loan`);
  }

  saveLoan(request: LoanTermDetails) {
    return this.apiPost<any>(`/api/accounting/loan`, request);
  }

  latestActionByTypeAndUnit(actions: HouseholdAction<any>[], actionType, unitId) {
    return lodash
      .chain(actions)
      .filter(action => !action.deletedOn)
      .filter(action => !actionType || action.action.toLowerCase() === actionType.toLowerCase())
      .filter(action => !unitId || (action.unit && action.unit === unitId))
      .sortBy(['effectiveDate', 'createdOn'])
      .last()
      .value();
  }

  getLedgers(householdId: string) {
    return this.apiGet(`/api/accounting/tenantLedgerAccounts/${householdId}`);
  }

  updateRecurringTranscation(householdId: string, request) {
    return this.apiPut(`/api/households/${householdId}/recurring-transaction/${request._id}`, request);
  }

  removeRecurringTransaction(householdId: string, recurTxId) {
    return this.apiDelete(`/api/households/${householdId}/recurring-transaction/${recurTxId}`);
  }

  saveNewRecurringTransaction(householdId: string, request) {
    return this.apiPost(`/api/households/${householdId}/recurring-transaction`, request);
  }

  findSubCategory(subCatId, categories) {
    return lodash
      .chain(categories)
      .map(cat => cat.subCategories)
      .flatten()
      .find(subCat => subCatId === subCat._id)
      .value();
  }

  // deleteTransaction(transactionId: string): Observable<MongoResults<Transaction>> {
  //   return this.apiDelete(this.apiTransactions + transactionId());
  // }

  // getTransaction(transactionId: string): Observable<Transaction> {
  //   return this.apiGet<Transaction>(this.apiTransactions + transactionId());
  // }

  // getRelatedTransactions(transactionId: string): Observable<MongoResults<Transaction>> {
  //   return this.apiGet<MongoResults<Transaction>>(this.apiTransactions + transactionId() + "/related-transactions");
  // }

  // updateTransaction(transactionId: string, transaction: Transaction): Observable<Transaction> {
  //   return this.apiPut<Transaction>(this.apiTransactions + transactionId(), transaction);
  // }

  queryTransactions(query: TransactionQuery): Observable<MongoResults<Transaction>> {
    return this.apiPost<MongoResults<Transaction>>(this.apiTransactions + 'query', query);
  }

  createTransaction(transaction: Transaction): Observable<Transaction> {
    return this.apiPost<Transaction>(this.apiTransactions, transaction);
  }

  pad(n, z?) {
    z = z || '0';
    n = n + '';
    return n.length >= 7 ? n : new Array(7 - n.length + 1).join(z) + n;
  }

  formatSequenceNumberForDisplay(transaction: Transaction): string {
    if (transaction && transaction.sequence && transaction.type) {
      switch (transaction.type) {
        case TransactionRequestType.Credit:
          return 'P' + this.pad(transaction.sequence);
        case TransactionRequestType.Charge:
          return 'C' + this.pad(transaction.sequence);
        case TransactionRequestType.Adjustment:
          return 'A' + this.pad(transaction.sequence);
        case TransactionRequestType.Transfer:
          return 'T' + this.pad(transaction.sequence);
      }
    }
  }
  // deleteRepaymentAgreement(repaymentagreementtermsId: string): Observable<MongoResults<RepaymentAgreement>> {
  //   return this.apiDelete('/api/accounting/repaymentagreementterms/' + depositrepaymentagreementtermsIdId());
  // }

  // getRepaymentAgreement(repaymentagreementtermsId: string): Observable<MongoResults<RepaymentAgreement>> {
  //   return this.apiGet('/api/accounting/repaymentagreementterms/' + depositrepaymentagreementtermsIdId());
  // }

  // updateRepaymentAgreement(repaymentagreementtermsId: string, repaymentagreementterms: RepaymentAgreement): Observable<MongoResults<RepaymentAgreement>> {
  //   return this.apiPut('/api/accounting/repaymentagreementterms/' + depositrepaymentagreementtermsIdId(), repaymentagreementterms);
  // }

  queryRepaymentAgreements(query: any): Observable<MongoResults<RepaymentAgreement>> {
    return this.apiPost('/api/accounting/repaymentagreementterms/query', query);
  }

  getAllHouseholdRepaymentAgreements(householdId: string) {
    return this.queryRepaymentAgreements({household: householdId});
  }

  createRepaymentAgreement(agreement: RepaymentAgreement) {
    return this.apiPost('/api/accounting/repaymentagreementterms', agreement);
  }

  ///
  // Chart of Accounts
  ///

  createSubLedgerMap(subLedgerMap: ISubLedgerMap): Observable<KansoActionResult<ISubLedgerMap>> {
    return this.apiPost<KansoActionResult<ISubLedgerMap>>(`${this.apiPath}/accounting/api/subledgermaps`, subLedgerMap);
  }

  getAllSubLedgerMaps(): Observable<KansoActionResult<ISubLedgerMap[]>> {
    return this.apiGet<KansoActionResult<ISubLedgerMap[]>>(`${this.apiPath}/accounting/api/subledgermaps`);
  }

  getSubLedgerMap(subLedgerMapId: string): Observable<KansoActionResult<ISubLedgerMap>> {
    return this.apiGet<KansoActionResult<ISubLedgerMap>>(`${this.apiPath}/accounting/api/subledgermaps/${subLedgerMapId}`);
  }

  updateSubLedgerMap(subLedgerMap: ISubLedgerMap): Observable<KansoActionResult<ISubLedgerMap>> {
    return this.apiPut<KansoActionResult<ISubLedgerMap>>(`${this.apiPath}/accounting/api/subledgermaps/${subLedgerMap._id}`, subLedgerMap);
  }

  deleteSubLedgerMap(subLedgerMapId: string): Observable<KansoActionResult<ISubLedgerMap>> {
    return this.apiDelete<KansoActionResult<ISubLedgerMap>>(`${this.apiPath}/accounting/api/subledgermaps/${subLedgerMapId}`);
  }

  // Units Setup

  // getAllUnitsO(): Observable<KansoActionResult<Unit[]>> {
  //   return this.apiGet<KansoActionResult<Unit[]>>(`${this.apiPath}/accounting/api/units`);
  // }

  getAllUnits<T>(
    skipCache: boolean,
    siteId: string,
    customerId: string,
    pageSize = 10,
    cursor: string,
    direction: string,
    searchText = '',
    sortField: UnitSortFields = UnitSortFields.Address
  ): Observable<any> {
    const data = [];
    let pageInfo: any;
    let totalCount: number;
    const queryText = this.queryUnits(pageSize, cursor, direction, searchText, sortField);
    return this.apollo
      .use('unit')
      .query({
        query: this.queryUnits(pageSize, cursor, direction, searchText, sortField),
        variables: {
          siteId,
          customerId,
        },
        fetchPolicy: skipCache ? 'network-only' : 'cache-first',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.units.edges) {
            try {
              const rawUnit = edge.node;
              const omnisearch = this.buildUnitOmniSearch(rawUnit);
              let eachUnit = {
                program: rawUnit.programId,
                project: rawUnit.projectId,
                omnisearch,
              };
              eachUnit = {...rawUnit, ...eachUnit};
              data.push(eachUnit);
            } catch (error) {
              console.log(error);
            }
          }
          pageInfo = response.data.units.pageInfo;
          totalCount = response.data.units.totalCount;
          return {data, pageInfo, totalCount};
        })
      );
  }

  private buildUnitOmniSearch(unit: Unit) {
    const omnisearch = [];
    if (unit.streetAddress) {
      omnisearch.push(unit.streetAddress.toLowerCase());
    }
    if (unit.unitNumber) {
      omnisearch.push(unit.unitNumber.toLowerCase());
    }
    if (unit.apartmentNumber) {
      omnisearch.push(unit.apartmentNumber.toLowerCase());
    }
    if (unit.city) {
      omnisearch.push(unit.city.toLowerCase());
    }
    if (unit.postalCode) {
      omnisearch.push(unit.postalCode);
    }
    if (unit.ppuCode) {
      omnisearch.push(unit.ppuCode.toLowerCase());
    }
    return omnisearch;
  }

  private buildSortClause(sortField: UnitSortFields) {
    let returnValue = ' order: [{ ';
    switch (sortField) {
      case UnitSortFields.Address:
        returnValue += 'streetAddress: ASC } ]';
        break;
      case UnitSortFields.Program:
        returnValue += 'program: { name: ASC } }]';
        break;
      case UnitSortFields.Project:
        returnValue += 'project: { name: ASC } }]';
        break;
    }
    return returnValue;
  }

  private buildWhereClause(filter: string) {
    let returnValue = '';
    if (filter.length > 0) {
      returnValue = 'where: { or: [';
      returnValue += `{ streetAddress: { contains: "${filter}" } },`;
      returnValue += `{ unitNumber: { contains: "${filter}" } },`;
      returnValue += `{ apartmentNumber: { contains: "${filter}" } },`;
      returnValue += `{ city: { contains: "${filter}" } },`;
      returnValue += `{ postalCode: { contains: "${filter}" } },`;
      returnValue += `{ ppuCode: { contains: "${filter}" } }`;
      returnValue += ']}';
    }
    return returnValue;
  }

  private queryUnits(
    pageSize: number,
    cursor = '',
    direction = 'forward',
    searchText = '',
    sortField: UnitSortFields = UnitSortFields.Address
  ) {
    try {
      const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
      const cursorFilter = lodash.isEmpty(cursor) ? '' : filter;
      const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;
      const whereClause = this.buildWhereClause(searchText);
      const sortClause = this.buildSortClause(sortField);
      return gql`
      query Units {
        units(${pageSizeFilter},${sortClause}, ${cursorFilter} ${whereClause}) {
          edges {
              node {
                  id
                customerId
                siteId
                ppuCode
                unitNumber
                streetAddress
                apartmentNumber
                city
                programId
                projectId
              }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `;
    } catch (error) {
      console.log(error);
    }
  }

  getUnit<T>(id: string): Observable<any> {
    // fetch specific unit and always go to server
    //i.e. override cache with network policy
    try {
      const data = [];
      return this.apollo
        .use('unit')
        .query({
          query: this.queryUnit(),
          variables: {
            id,
          },
          fetchPolicy: 'network-only',
        })
        .pipe(
          map((response: any) => {
            for (const edge of response.data.units.edges) {
              const rawUnit = edge.node;
              let eachUnit = {
                program: rawUnit.programId,
                project: rawUnit.projectId,
                overrideRentCalc: (rawUnit as Unit).unitOverrides ? true : false,
                rentCalc: (rawUnit as Unit).unitOverrides ? new UnitRentCalc(rawUnit as Unit) : null,
              };
              eachUnit = {...rawUnit, ...eachUnit};
              data.push(eachUnit);
            }
            return data;
          })
        );
    } catch (error) {
      console.log(error);
    }
  }

  private queryUnit() {
    return gql`
      query Units($id: String) {
        units(first: 1, where: {id: {eq: $id}}) {
          edges {
            node {
              id
              customerId
              siteId
              programId
              projectId
              ppuCode
              unitNumber
              bin
              description
              streetAddress
              apartmentNumber
              entranceNumber
              city
              state
              postalCode
              postalCodePlus4
              county
              numberOfBedrooms
              numberOfBathrooms
              isHandicapAccessible
              squareFootage
              yearConstructed
              is1937Unit
              latitude
              longitude
              photoUrl
              convertedFromProgramId
              convertedFromProjectId
              isNonInventoryUnit
              landlordId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              utilityVendorId
              propertyId
              unitRealEstateId
              utilityAllowanceId
              totalDevelopmentCost
              dateOfFullAvailability
              country
              picUnitNumber
              buildingName
              buildingNumber
              isCasExcluded
              dceThresholdExceeded
              utilityVendorId
              customAttributes {
                id
                textValue
                booleanValue
                dateTimeValue
                entityInstanceId
                entityAttributeId
                siteId
                attribute {
                  id
                  attributeType
                  name
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
              unitOverrides {
                id
                unitId
                calcKey
                assistanceCalcKey
                elderlyDeductionAge
                allowableDependentDeduction
                allowableElderlyDisabilityDeduction
                totalTenantPaymentPercentage
                nearElderAge
                observeNahasda30Percent
                incomeBased
                incomeLimitAreaId
                taxCreditIncomeLimitAreaId
                rentLimitAreaId
                minTTP
                incomeBased
                unitFees {
                  id
                  unitOverridesId
                  feeTableType
                  effectiveDate
                  amount
                  createdOn
                  createdBy
                }
              }
            }
          }
        }
      }
    `;
  }

  updateUnit<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('unit').mutate({
      mutation: gql`
        mutation updateUnitMutation($command: UpdateUnitCommandInput) {
          updateUnit(command: $command) {
            commandName
            status
            affectedEntity {
              id
              customerId
              siteId
              programId
              projectId
              ppuCode
              unitNumber
              bin
              description
              streetAddress
              apartmentNumber
              entranceNumber
              city
              state
              postalCode
              postalCodePlus4
              county
              numberOfBedrooms
              numberOfBathrooms
              isHandicapAccessible
              squareFootage
              yearConstructed
              is1937Unit
              latitude
              longitude
              photoUrl
              convertedFromProgramId
              convertedFromProjectId
              isNonInventoryUnit
              landlordId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              propertyId
              unitRealEstateId
              totalDevelopmentCost
              dateOfFullAvailability
              country
              picUnitNumber
              buildingName
              buildingNumber
              utilityVendorId
              housingType
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createUnit<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('unit').mutate({
      mutation: gql`
        mutation createUnitMutation($command: CreateUnitCommandInput!) {
          createUnit(command: $command) {
            commandName
            status
            failedOn
            failureReason
            affectedEntity {
              id
              programId
              projectId
              unitNumber
              description
              streetAddress
              city
              state
              createdOn
              createdBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createUnitCustomAttribute<T>(command: ICreateUnitAttributeCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation CreateUnitAttributeValue($command: CreateUnitAttributeValueCommandInput!) {
            createUnitAttributeValue(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                textValue
                booleanValue
                dateTimeValue
                entityInstanceId
                entityAttributeId
                siteId
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateUnitCustomAttribute<T>(command: IUpdateUnitAttributeCommand): Observable<FetchResult<T>> {
    return this.apollo.use('unit').mutate({
      mutation: gql`
        mutation UpdateUnitAttributeValue($command: UpdateUnitAttributeValueCommandInput!) {
          updateUnitAttributeValue(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              textValue
              booleanValue
              dateTimeValue
              entityInstanceId
              entityAttributeId
              siteId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  // unit override & fee mutations
  createUnitOverride<T>(command: ICreateUnitOverrideCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation CreateUnitOverride($command: CreateUnitOverrideCommandInput!) {
            createUnitOverride(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                id
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateUnitOverride<T>(command: IUpdateUnitOverrideCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation UpdateUnitOverride($command: UpdateUnitOverrideCommandInput!) {
            updateUnitOverride(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                id
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  deleteUnitOverride<T>(command: IDeleteUnitOverrideCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation DeleteUnitOverride($command: DeleteUnitOverrideCommandInput!) {
            deleteUnitOverride(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  createUnitFee<T>(command: ICreateUnitFeeCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation CreateUnitFee($command: CreateUnitFeeCommandInput!) {
            createUnitFee(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                id
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateUnitFee<T>(command: IUpdateUnitFeeCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation UpdateUnitFee($command: UpdateUnitFeeCommandInput!) {
            updateUnitFee(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                id
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  deleteUnitFee<T>(command: IDeleteUnitFeeCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('unit').mutate({
        mutation: gql`
          mutation DeleteUnitFee($command: DeleteUnitFeeCommandInput!) {
            deleteUnitFee(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  async saveUnit(unitToSave: Unit, unitExisting?: Unit): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };
    let overrideResponse: ComplexSaveResult = _.clone(response);
    let attributeResponse: ComplexSaveResult = _.clone(response);
    if (unitExisting) {
      //check for dirty unit
      const unitDirty = !this.coreService.areEqual(unitToSave, unitExisting, false, ['overrideRentCalc']);
      if (unitDirty) {
        const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
        const command = this.buildUpdateUnitMutation(unitToSave, unitExisting);
        const result = await this.updateUnit(command).toPromise();
        if ((result.data as IUpdateUnitDataResponse).updateUnit.status !== 'SUCCESS') {
          response.success = false;
          response.errorMessage = `${(result.data as IUpdateUnitDataResponse).updateUnit.failureReason}\n`;
          return response;
        } else {
          response.affectedEntity = (result.data as IUpdateUnitDataResponse).updateUnit.affectedEntity;
        }
      }
      //update existing
      //check for dirty attributes
      unitToSave.customAttributes = unitToSave.customAttributes.sort((a, b) => a.entityAttributeId.localeCompare(b.entityAttributeId));
      unitExisting.customAttributes = unitExisting.customAttributes.sort((a, b) => a.entityAttributeId.localeCompare(b.entityAttributeId));
      const attributesDirty = !this.coreService.areEqual(unitToSave.customAttributes, unitExisting.customAttributes, true);
      if (attributesDirty) {
        attributeResponse = await this.saveCustomAttributes(unitToSave, unitExisting);
        if (!attributeResponse.success) {
          return attributeResponse;
        }
      }
      if (
        unitToSave.overrideRentCalc !== unitExisting.overrideRentCalc ||
        (unitToSave.overrideRentCalc == true && unitToSave.numberOfBedrooms != unitExisting.numberOfBedrooms) ||
        (unitToSave.overrideRentCalc == true && !this.coreService.areEqual(unitToSave.rentCalc, unitExisting.rentCalc, true))
      ) {
        overrideResponse = await this.saveUnitOverrides(unitToSave, unitExisting);
        if (!overrideResponse.success) {
          return overrideResponse;
        }
      }
    } else {
      //create new unit
      const command = this.buildCreateUnitMutation(unitToSave);
      const result = await this.createUnit(command).toPromise();
      if ((result.data as ICreateUnitDataResponse).createUnit.status !== 'SUCCESS') {
        (response.success = false), (response.errorMessage = `${(result.data as ICreateUnitDataResponse).createUnit.failureReason}\n`);
      } else {
        response.affectedEntity = (result.data as ICreateUnitDataResponse).createUnit.affectedEntity;
      }
      unitToSave.id = (result.data as ICreateUnitDataResponse).createUnit.affectedEntity.id;
      if (unitToSave.customAttributes.length > 0) {
        attributeResponse = await this.saveCustomAttributes(unitToSave);
        if (!attributeResponse.success) {
          return attributeResponse;
        }
      }
      if (unitToSave.overrideRentCalc) {
        overrideResponse = await this.saveUnitOverrides(unitToSave);
        if (!overrideResponse.success) {
          return overrideResponse;
        }
      }
    }
    return response;
  }

  async getDefaultRentCalc(unit: Unit): Promise<IRentCalcDetails> {
    // Override goes program -> project -> unit
    const programResult = await this.programService.getProgram(unit.program).toPromise();
    const program = programResult[0];
    const projectResult = this.getProject(unit.project).toPromise();
    const project: Project = (await projectResult).body;
    program.programSettings.id = null;
    if (project.rentCalc) {
      project.rentCalc._id = null;
    }
    return project.rentCalc ? project.rentCalc : program.programSettings;
  }

  convertAttributes(attributesToConvert: CustomAttribute[] | ICustomAttributesValue[], unitId?: string): CustomAttributesValue[] {
    const convertedAttributeValues: CustomAttributesValue[] = [];
    attributesToConvert.forEach((a): void => {
      const value = new CustomAttributesValue(a);
      value.entityInstanceId = unitId ? unitId : a.id;
      try {
        convertedAttributeValues.push(value as CustomAttributesValue);
      } catch (error) {
        console.log(error);
      }
    });
    return convertedAttributeValues;
  }

  private buildUpdateUnitMutation(unitToSave: Unit, unitExisting: Unit): UnitUpdateCommand {
    //NOTE: leaving commented out console.log statements for debugging
    const unitToSaveKeys = Object.keys(unitToSave);
    const unitExistingKeys = Object.keys(unitExisting);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: UnitUpdateCommand = {
      id: unitToSave.id,
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      modifiedBy: currentuser,
    };

    // any prop that does  not match add to command object
    for (const key of unitToSaveKeys) {
      // Check if the property is of type 'object' or 'array'
      const isObject = this.coreService.isObject(unitToSave[key]) || this.coreService.isObject(unitExisting[key]);
      const isArray = Array.isArray(unitToSave[key]);
      if (isArray || isObject) {
        //console.log(`skipping: ${key} existing value:${unitExisting[key]} new value:${unitToSave[key]}` )
        continue; // Skip properties that are objects or arrays
      }
      // console.log(`key: ${key}`)
      // console.log(`existing value:${unitExisting[key]}`)
      // console.log(`new value:${unitToSave[key]}`)
      if (!Object.prototype.hasOwnProperty.call(unitExisting, key) || unitToSave[key] !== unitExisting[key]) {
        const commandKey = key == 'nonInventoryUnit' ? 'isNonInventoryUnit' : key;
        command[commandKey] = unitToSave[key];
        const landlordString = 'landlordId';
        const utilityVendorString = 'utilityVendorId';
        const clearLandlordCommand = 'clearLandlord';
        const clearUtilityVendorCommand = 'clearUtilityVendor';

        if (key == landlordString && this.coreService.isNullOrEmptyString(unitToSave[key])) {
          command[clearLandlordCommand] = true;
        } else if (key == utilityVendorString && this.coreService.isNullOrEmptyString(unitToSave[key])) {
          command[clearUtilityVendorCommand] = true;
        }
      }
    }
    return command;
  }

  private buildCreateUnitMutation(unitToSave: Unit): UnitCreateCommand {
    const unitToSaveKeys = Object.keys(unitToSave);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: UnitCreateCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      programId: unitToSave.program,
      projectId: unitToSave.project,
      streetAddress: unitToSave.streetAddress,
      createdBy: currentuser,
    };
    for (const key of unitToSaveKeys) {
      const isObject = this.coreService.isObject(unitToSave[key]);
      const isArray = Array.isArray(unitToSave[key]);
      // Check if the property is of type 'object' or 'array'
      if (unitToSave[key] && !isObject && !isArray) {
        // unit model defines both program & project id fields w/o Id suffix
        // This is a tech debt story https://hdslabs.atlassian.net/browse/KANSO-2836
        if (key !== 'program' && key !== 'project' && key !== 'overrideRentCalc') {
          const commandKey = key == 'nonInventoryUnit' ? 'isNonInventoryUnit' : key;
          command[commandKey] = unitToSave[key];
        }
      }
    }
    // check for required boolean fields and default to false if not included
    const requiredBooleans: string[] = ['is1937Unit', 'isNonInventoryUnit', 'isHandicapAccessible', 'isCasExcluded'];
    for (const prop of requiredBooleans) {
      if (!Object.prototype.hasOwnProperty.call(command, prop)) {
        command[prop] = false;
      }
    }
    return command;
  }

  private buildCreateUnitOverrideMutation(unitToSave: Unit): ICreateUnitOverrideCommand {
    const overrideToSave = new UnitOverride(unitToSave.rentCalc);
    const overrideToSaveKeys = Object.keys(overrideToSave);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: ICreateUnitOverrideCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      unitId: unitToSave.id,
      createdBy: currentuser,
    };
    for (const key of overrideToSaveKeys) {
      const isArray = Array.isArray(overrideToSave[key]);
      if (overrideToSave[key] && !isArray && !_.isNil(overrideToSave[key])) {
        command[key] = overrideToSave[key];
      }
    }
    return command;
  }

  private buildUpdateUnitOverrideMutation(unitToSave: Unit, unitExisting: Unit): IUpdateUnitOverrideCommand {
    const overrideToSave = new UnitOverride(unitToSave.rentCalc);
    const overrideExisting = new UnitOverride(unitExisting.rentCalc);
    const overrideToSaveKeys = Object.keys(overrideToSave);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: IUpdateUnitOverrideCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      id: unitToSave.rentCalc._id,
      modifiedBy: currentuser,
    };
    for (const key of overrideToSaveKeys) {
      const isArray = Array.isArray(overrideToSave[key]);
      if (
        overrideToSave[key] &&
        !isArray &&
        !_.isNil(overrideToSave[key]) &&
        !this.coreService.areEqual(overrideToSave[key], overrideExisting[key])
      ) {
        command[key] = overrideToSave[key];
      }
    }
    return command;
  }

  private buildDeleteUnitOverrideMutation(unitOverrideIdToDelete: string): IDeleteUnitOverrideCommand {
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: IDeleteUnitOverrideCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      id: unitOverrideIdToDelete,
      deletedBy: currentuser,
    };
    return command;
  }

  private buildCreateUnitFeeMutation(unitToSave: Unit, feeToSave: IEffectiveByBedroom, feeTableType: string): ICreateUnitFeeCommand {
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: ICreateUnitFeeCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      unitOverridesId: unitToSave.rentCalc._id,
      feeTableType,
      createdBy: currentuser,
      effectiveDate: new Date(feeToSave.effectiveDate),
      amount: feeToSave.value[unitToSave.numberOfBedrooms],
    };
    return command;
  }

  private buildUpdateUnitFeeMutation(unitToSave: Unit, feeToSave: IEffectiveByBedroom, feeTableType: string): IUpdateUnitFeeCommand {
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: IUpdateUnitFeeCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      id: feeToSave.id,
      modifiedBy: currentuser,
      effectiveDate: new Date(feeToSave.effectiveDate),
      amount: feeToSave.value[unitToSave.numberOfBedrooms],
    };
    return command;
  }

  private buildDeleteUnitFeeMutation(feeToSave: IEffectiveByBedroom): IDeleteUnitFeeCommand {
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: IDeleteUnitFeeCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      id: feeToSave.id,
      deletedBy: currentuser,
    };
    return command;
  }

  async saveCustomAttributes(unit: Unit, unitExisting?: Unit): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };
    for (const attribute of unit.customAttributes) {
      const customAttributeValue: ICustomAttributesValue = attribute as CustomAttributesValue;
      const attributeCommand: IUnitAttributeCommand = {
        ...this.envDat,
        textValue: customAttributeValue.textValue,
        booleanValue: customAttributeValue.booleanValue,
        dateTimeValue: customAttributeValue.dateTimeValue,
      };
      switch (true) {
        case !customAttributeValue.id &&
          (customAttributeValue.booleanValue !== null ||
            customAttributeValue.dateTimeValue !== null ||
            customAttributeValue.textValue !== null): {
          //new attribute w/ value
          const createAttributeCommand: ICreateUnitAttributeCommand = {
            ...attributeCommand,
            entityAttributeId: customAttributeValue.entityAttributeId,
            entityInstanceId: unit.id,
          };
          const result = await this.createUnitCustomAttribute(createAttributeCommand).toPromise();
          if ((result.data as ICreateUnitAttributeDataResponse).createUnitAttributeValue.status !== 'SUCCESS') {
            (response.success = false),
              (response.errorMessage += `${(result.data as ICreateUnitAttributeDataResponse).createUnitAttributeValue.failureReason}\n`);
          }

          break;
        }
        case !!attribute.id: {
          //existing attribute check if dirty
          const existingAttribute = (unitExisting.customAttributes as ICustomAttributesValue[]).find(a => a.id == attribute.id);
          const attributeValueFortest = new CustomAttributesValue(existingAttribute as CustomAttributesValue);
          if (!this.coreService.areEqual(attributeValueFortest, attribute)) {
            // update attribute
            const updateAttributeCommand: IUpdateUnitAttributeCommand = {
              ...attributeCommand,
              id: attribute.id,
            };
            const result = await this.updateUnitCustomAttribute(updateAttributeCommand).toPromise();
            if ((result.data as IUpdateUnitAttributeDataResponse).updateUnitAttributeValue.status !== 'SUCCESS') {
              (response.success = false),
                (response.errorMessage += `${(result.data as IUpdateUnitAttributeDataResponse).updateUnitAttributeValue.failureReason}\n`);
            }
          }
          break;
        }
      }
    }
    return response;
  }
  async saveUnitOverrides(unitToSave: Unit, unitExisting?: Unit): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };
    if (unitExisting?.rentCalc) {
      //update or delete
      if (!unitToSave.overrideRentCalc && unitExisting.overrideRentCalc) {
        //delete unit overide
        const command = this.buildDeleteUnitOverrideMutation(unitExisting.unitOverrides.id);
        const result = await this.deleteUnitOverride(command).toPromise();
        if ((result.data as IDeleteUnitOverrideDataResponse).deleteUnitOverride.status !== 'SUCCESS') {
          (response.success = false),
            (response.errorMessage = `${(result.data as IDeleteUnitOverrideDataResponse).deleteUnitOverride.failureReason}\n`);
          return response;
        }
      } else {
        if (!this.coreService.areEqual(unitToSave.rentCalc, unitExisting.rentCalc)) {
          //update unit override
          const command = this.buildUpdateUnitOverrideMutation(unitToSave, unitExisting);
          const result = await this.updateUnitOverride(command).toPromise();
          if ((result.data as IUpdateUnitOverrideDataResponse).updateUnitOverride.status !== 'SUCCESS') {
            (response.success = false),
              (response.errorMessage = `${(result.data as IUpdateUnitOverrideDataResponse).updateUnitOverride.failureReason}\n`);
            return response;
          }
        }
        //create/update/delete unit fees
        await this.saveUnitOverrideFees(unitToSave, unitExisting);
      }
    } else {
      //create new override & unitFees
      const command = this.buildCreateUnitOverrideMutation(unitToSave);
      const result = await this.createUnitOverride(command).toPromise();
      if ((result.data as ICreateUnitOverrideDataResponse).createUnitOverride.status !== 'SUCCESS') {
        (response.success = false),
          (response.errorMessage = `${(result.data as ICreateUnitOverrideDataResponse).createUnitOverride.failureReason}\n`);
        return response;
      }
      unitToSave.rentCalc._id = (result.data as ICreateUnitOverrideDataResponse).createUnitOverride.affectedEntity.id;
      await this.saveUnitOverrideFees(unitToSave);
    }
    return response;
  }

  async saveUnitOverrideFees(unitToSave: Unit, unitExisting?: Unit): Promise<ComplexSaveResult> {
    //create new override Fees
    const feeTableTypes = [
      'adminFees',
      'flatRents',
      'minimumCharges',
      'maximumCharges',
      'noteRateRents',
      'utilityAllowances',
      'fairMarketRents',
      'basicRents',
    ];

    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };
    const overrideRentCalc: IRentCalcDetails = unitToSave.rentCalc;
    if (unitExisting?.unitOverrides?.unitFees) {
      //compare new to old and update, create or delete
      const overrideRentCalcExisting: IRentCalcDetails = unitExisting.rentCalc;

      for (const feeTableType of feeTableTypes) {
        if (overrideRentCalc[feeTableType].length > 0) {
          // check new for new or changed
          for (const unitFee of overrideRentCalc[feeTableType]) {
            if (unitFee.id) {
              //existing check for changes
              const existingFee = overrideRentCalcExisting[feeTableType].find(f => f.id == unitFee.id);
              const bedroomSizeChanged = unitToSave.numberOfBedrooms != unitExisting.numberOfBedrooms;
              if (!this.coreService.areEqual(unitFee, existingFee) || bedroomSizeChanged) {
                //update fee
                if (bedroomSizeChanged) {
                  unitFee.value[unitToSave.numberOfBedrooms] =
                    unitFee.value[unitToSave.numberOfBedrooms] != 0
                      ? unitFee.value[unitToSave.numberOfBedrooms]
                      : existingFee.value[unitExisting.numberOfBedrooms];
                }
                const command = this.buildUpdateUnitFeeMutation(unitToSave, unitFee, this.coreService.capitalizeFirstLetter(feeTableType));
                const result = await this.updateUnitFee(command).toPromise();
                if ((result.data as IUpdateUnitFeeDataResponse).updateUnitFee.status !== 'SUCCESS') {
                  (response.success = false),
                    (response.errorMessage = `${(result.data as IUpdateUnitFeeDataResponse).updateUnitFee.failureReason}\n`);
                }
              }
            } else {
              //new fee save
              const command = this.buildCreateUnitFeeMutation(unitToSave, unitFee, this.coreService.capitalizeFirstLetter(feeTableType));
              const result = await this.createUnitFee(command).toPromise();
              if ((result.data as ICreateUnitFeeDataResponse).createUnitFee.status !== 'SUCCESS') {
                (response.success = false),
                  (response.errorMessage = `${(result.data as ICreateUnitFeeDataResponse).createUnitFee.failureReason}\n`);
              }
            }
          }
          //check existing for deleted
          for (const unitFee of overrideRentCalcExisting[feeTableType]) {
            const feeToSave = overrideRentCalc[feeTableType].find(f => f.id == unitFee.id);
            if (!feeToSave) {
              //delete fee
              const command = this.buildDeleteUnitFeeMutation(unitFee);
              const result = await this.deleteUnitFee(command).toPromise();
              if ((result.data as IDeleteUnitFeeDataResponse).deleteUnitFee.status !== 'SUCCESS') {
                (response.success = false),
                  (response.errorMessage = `${(result.data as IDeleteUnitFeeDataResponse).deleteUnitFee.failureReason}\n`);
              }
            }
          }
        } else if (overrideRentCalcExisting[feeTableType].length > 0) {
          //delete all existing
          for (const unitFee of overrideRentCalcExisting[feeTableType]) {
            const command = this.buildDeleteUnitFeeMutation(unitFee);
            const result = await this.deleteUnitFee(command).toPromise();
            if ((result.data as IDeleteUnitFeeDataResponse).deleteUnitFee.status !== 'SUCCESS') {
              (response.success = false),
                (response.errorMessage = `${(result.data as IDeleteUnitFeeDataResponse).deleteUnitFee.failureReason}\n`);
            }
          }
        }
      }
    } else {
      for (const feeTableType of feeTableTypes) {
        if (overrideRentCalc[feeTableType].length > 0) {
          for (const unitFee of overrideRentCalc[feeTableType]) {
            const command = this.buildCreateUnitFeeMutation(unitToSave, unitFee, this.coreService.capitalizeFirstLetter(feeTableType));
            const result = await this.createUnitFee(command).toPromise();
            if ((result.data as ICreateUnitFeeDataResponse).createUnitFee.status !== 'SUCCESS') {
              (response.success = false),
                (response.errorMessage = `${(result.data as ICreateUnitFeeDataResponse).createUnitFee.failureReason}\n`);
            }
          }
        }
      }
    }
    return response;
  }

  // deleteUnit(id: string): Observable<KansoActionResult<Unit>> {
  //   return this.apiDelete<KansoActionResult<Unit>>(`${this.apiPath}/accounting/api/units/${id}`);
  // }

  //   createDeposit(deposit: Deposit) {
  //     return this.apiPost('/api/accounting/deposits', deposit)
  //   }

  //   deleteDeposit(depositId: string): Observable<MongoResults<Deposit>> {
  //     return this.apiDelete('/api/accounting/deposits/' + depositId());
  //   }

  //   getDeposit(depositId: string): Observable<MongoResults<Deposit>> {
  //     return this.apiGet('/api/accounting/deposits/' + depositId());
  //   }

  //   updateDeposit(depositId: string, deposit: Deposit): Observable<MongoResults<Deposit>> {
  //     return this.apiPut('/api/accounting/deposits/' + depositId(), deposit);
  //   }

  //   queryDeposits(query: any): Observable<MongoResults<Deposit>> {
  //     return this.apiPost<MongoResults<Deposit>>(this.apiPath + '/deposits/query', query);
  //   }

  ///
  // General Ledger Accounts
  ///

  //queryGeneralLedgerAccounts(query: any): Observable<KansoActionResult<MongoResults<GeneralLedgerAccount>>> {
  //  return this.apiPost<KansoActionResult<MongoResults<GeneralLedgerAccount>>>(
  //    `${this.apiPath}/accounting/api/externalaccounts/query`,
  //    query
  //  );
  //}

  createGeneralLedgerAccount(account: any): Observable<KansoActionResult<GeneralLedgerAccount>> {
    return this.apiPost<KansoActionResult<GeneralLedgerAccount>>(`${this.apiPath}/accounting/api/externalaccounts`, account);
  }

  getAllGeneralLedgerAccounts(): Observable<KansoActionResult<GeneralLedgerAccount[]>> {
    return this.apiGet<KansoActionResult<GeneralLedgerAccount[]>>(`${this.apiPath}/accounting/api/externalaccounts`);
  }

  getGeneralLedgerAccounts(id: string): Observable<KansoActionResult<GeneralLedgerAccount>> {
    return this.apiGet<KansoActionResult<GeneralLedgerAccount>>(`${this.apiPath}/accounting/api/externalaccounts/${id}`);
  }

  updateGeneralLedgerAccount(account: GeneralLedgerAccount): Observable<KansoActionResult<GeneralLedgerAccount>> {
    return this.apiPut<KansoActionResult<GeneralLedgerAccount>>(`${this.apiPath}/accounting/api/externalaccounts/${account._id}`, account);
  }

  deleteGeneralLedgerAccount(account: GeneralLedgerAccount): Observable<KansoActionResult<GeneralLedgerAccount>> {
    return this.apiDelete<KansoActionResult<GeneralLedgerAccount>>(`${this.apiPath}/accounting/api/externalaccounts/${account._id}`);
  }

  getActionForms(actionFormQuery: ActionFormQuery): Observable<KansoActionResult<ActionFormsArray>> {
    return this.apiPost<KansoActionResult<ActionFormsArray>>(`${this.apiPath}/accounting/api/actionforms`, actionFormQuery);
  }
}
