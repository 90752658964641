import {ProgramType, AutoChargeConfiguration, EnhancedRentTransactions} from 'src/app/custom/housing-core/services/housing-models';
import {AutoChargeConfigurationValues, FeeTableValue, ProgramFeeTable, ProgramModel, ProgramSettings} from './program.model';
import {AccountActivityRule, AccountDefinition, DefaultTerms, ImportKey} from 'src/app/custom/accounting/service/accounting-models';

export class ProgramSettingsModal implements ProgramSettings {
  id?: string;
  programId: string;
  calcKey: string;
  assistanceCalcKey?: any;
  elderlyDeductionAge: number;
  allowableDependentDeduction: number;
  totalTenantPaymentPercentage?: any;
  nearElderAge: number;
  observeNahasda30Percent: boolean;
  minTTP: number;
  incomeLimitAreaId: string;
  taxCreditIncomeLimitAreaId?: any;
  rentLimitAreaId?: any;
  paymentStandardId?: string;
  requireHotmaUpdates: boolean;
  isUtilityAllowanceCalculations: boolean;
  imputedAssetRate?: number;
  monthOfFiscalYearEnd?: number;
  fundingSource?: string;
  allowableElderlyDisabilityDeduction?: number;
  programFeeTable?: ProgramFeeTable[];
  constructor() {
    this.id = null;
    this.calcKey = 'rentcalc.default';
    this.elderlyDeductionAge = 62;
    this.allowableDependentDeduction = 480;
    this.allowableElderlyDisabilityDeduction = 400;
    this.totalTenantPaymentPercentage = 0.3;
    this.nearElderAge = 60;
    this.observeNahasda30Percent = true;
    this.imputedAssetRate = 0.0006;
    this.requireHotmaUpdates = false;
    this.isUtilityAllowanceCalculations = false;
    this.programFeeTable = [];
  }
}

export enum ProgramFeeStatus {
  Create = 0,
  CreateAndModified,
  Modified,
  Delete,
}

export class ProgramFeeTableObject implements ProgramFeeTable {
  id: string;
  programSettingsId: string;
  effectiveDate: Date;
  feeTableType: string;
  feeTableValues: FeeTableValue[];
  status?: number | undefined;
  constructor(feetableType: string) {
    this.id = null;
    this.programSettingsId = null;
    this.effectiveDate = new Date();
    this.feeTableType = feetableType;
    this.feeTableValues = [
      new FeeTableValueObject(0),
      new FeeTableValueObject(1),
      new FeeTableValueObject(2),
      new FeeTableValueObject(3),
      new FeeTableValueObject(4),
      new FeeTableValueObject(5),
      new FeeTableValueObject(6),
      new FeeTableValueObject(7),
      new FeeTableValueObject(8),
      new FeeTableValueObject(9),
      new FeeTableValueObject(10),
    ];
    this.status = ProgramFeeStatus.Create;
  }
}

export class FeeTableValueObject implements FeeTableValue {
  id: string;
  feeTableId: string;
  householdSize: number;
  amount: number;
  constructor(houseHoldSize: number) {
    this.id = null;
    this.feeTableId = null;
    this.householdSize = houseHoldSize;
    this.amount = 0;
  }
}

export class ProgramObjectModal implements ProgramModel {
  id?: string;
  customerId?: string;
  siteId?: string;
  name: string;
  programCode: string;
  phaCode?: string;
  isRecertificationRequired?: boolean;
  recertPeriodUnit?: 'Days' | 'Months' | 'Years';
  requires58PicForm: boolean;
  enhancedRentToggle?: boolean;
  recertificationPeriod?: number;
  createdBy?: string;
  isHousingProgram?: boolean;
  chartOfAccountsId?: string;
  housingAuthorityId?: string;
  aelValue?: number;
  importKey?: string;
  programType?: ProgramType;
  accountDefinitions?;
  chartOfAccounts?: string;
  customAttributes?;
  isProjectBasedAccounting?: boolean;
  housingAuthority?: string;
  autoChargeConfigurations?: AutoChargeConfiguration[];
  enhancedRentTransactions?: EnhancedRentTransactions[];
  programSettings?: ProgramSettings;
  recertPeriod?: number;
  enhancedRents: EnhancedRentTransactions[];
  autoChargeConfigurationValues?: AutoChargeConfigurationValues[];
  constructor() {
    this.name = '';
    this.programCode = '';
    this.phaCode = '';
    this.enhancedRentToggle = false;
    this.requires58PicForm = false;
    this.recertPeriodUnit = null;
    this.recertificationPeriod = null;
    this.isRecertificationRequired = true;
    this.isHousingProgram = false;
    this.programType = null;
    this.programSettings = new ProgramSettingsModal();
    this.accountDefinitions = [];
    this.enhancedRentTransactions = [];
    this.customAttributes;
  }
}

export class AccountDefinitionModel implements AccountDefinition {
  id?: string;
  name: string;
  accountDefinitionType?: string;
  vendorType?: string;
  chartOfAccounts?: string;
  subledgerMappingId?: string;
  chartOfAccountsName?: string;
  isAutoPostDisabled?: boolean;
  principalChargeSubCategory?: string;
  interestChargeSubCategory?: string;
  principalReductionSubCategory?: string;
  adjustmentToEstablishPrincipalBalanceSubCategory?: string;
  lateFeeSubCategory?: string;
  assistancePaymentSubCategory?: string;
  equityAccountDefinitions?: string[];
  mepaAccountDefinition?: string;
  activityRules?: AccountActivityRule[];
  isArAccount?: boolean;
  isAssistance?: boolean;
  isPayableAccount?: boolean;
  isPrimary?: boolean;
  isPrincipal?: boolean;
  isDepositAccount?: boolean;
  isRepaymentAccount?: boolean;
  isBadDebtAccount?: boolean;
  creditAllocationPriority?: number;
  importKey?: ImportKey;
  defaultTerms?: DefaultTerms;
  payableAchSubCategory?: string;
  payableCheckSubCategory?: string;
  payableOtherSubCategory?: string;
  utilityAllowanceSubcategory?: string;
  toDelete?: boolean;
  constructor() {
    this.name = 'NEW ACCOUNT DEFINITION';
    this.vendorType = 'TENANT';
    this.chartOfAccounts = null;
    this.subledgerMappingId = null;
    this.chartOfAccountsName = null;
    this.isAutoPostDisabled = false;
    this.principalChargeSubCategory = null;
    this.interestChargeSubCategory = null;
    this.principalReductionSubCategory = null;
    this.adjustmentToEstablishPrincipalBalanceSubCategory = null;
    this.lateFeeSubCategory = null;
    this.assistancePaymentSubCategory = null;
    this.equityAccountDefinitions = null;
    this.mepaAccountDefinition = null;
    this.activityRules = [];
    this.isArAccount = true;
    this.isAssistance = false;
    this.isPayableAccount = false;
    this.isPrimary = true;
    this.isPrincipal = false;
    this.isDepositAccount = false;
    this.isRepaymentAccount = false;
    this.isBadDebtAccount = false;
    this.creditAllocationPriority = 0;
    this.payableAchSubCategory = null;
    this.payableCheckSubCategory = null;
    this.payableOtherSubCategory = null;
    this.utilityAllowanceSubcategory = null;
  }
}
