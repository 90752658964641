<div class="section-body section-wording program-rent-calc-container" has-permission="'admin'">
    <div class="layout-row layout-align-start-center">
        <mat-form-field appearance="outline"
            *ngIf="programType !== 'Rural Development'">
            <mat-label>Total Tenant Payment Percent</mat-label>
            <input
                matInput
                id="programTTPPercentageInput" 
                name="programTTPPercentageInput"
                type="number"
                aria-label="Total tenant payment percent"
                [(ngModel)]="rentCalc.totalTenantPaymentPercentage"
                #programTTPPercentageInput="ngModel"
                >
                <mat-hint>Input as a decimal, e.g. 0.3 for 30%</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Passbook Rate</mat-label>
            <input
                matInput
                type="text"
                id="imputedAssetRate"
                name="imputedAssetRate"
                aria-label="Passbook Rate"
                [(ngModel)]="rentCalc.imputedAssetRate"
                #imputedAssetRate="ngModel"
                min="0"
                max="0.9999"
                step="0.0001"
                pattern="^\d+(\.\d{1,4})?$"
                (keypress)="validateInput($event)"
                >
            <mat-error *ngIf="imputedAssetRate.hasError('min')">
                <div>Passbook rate must be greater than or equal to 0</div>
            </mat-error>
            <mat-error *ngIf="imputedAssetRate.hasError('max')">
                <div>Passbook rate must be less than or equal to 0.9999</div>
            </mat-error>
            <mat-error *ngIf="imputedAssetRate.hasError('pattern')">
                <div>Passbook rate is limited to four decimal places</div>
            </mat-error>
            
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Allowable Dependent Deduction</mat-label>
            <input
                matInput
                type="number"
                id="programAllowableDependentDeductionInput"
                name="programAllowableDependentDeductionInput" 
                aria-label="Allowable dependent deduction"
                [(ngModel)]="rentCalc.allowableDependentDeduction"
                #programAllowableDependentDeductionInput="ngModel"
                monetary-input>
        </mat-form-field>
    </div>

    <div class="layout-row layout-align-start-center">
        <mat-form-field appearance="outline">
            <mat-label>Allowable Elderly/Disability Deduction</mat-label>
            <input 
                matInput
                type="number"
                id="programAllowableElderlyDeductionInput"
                name="programAllowableElderlyDeductionInput"
                aria-label="Allowable elderly deduction"
                [(ngModel)]="rentCalc.allowableElderlyDisabilityDeduction"
                #programAllowableElderlyDeductionInput="ngModel"
                monetary-input>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Age to Qualify for Elderly Deduction</mat-label>
            <input
                matInput
                type="number"
                id="programElderlyDeductionAgeInput"
                name="programElderlyDeductionAgeInput"
                [(ngModel)]="rentCalc.elderlyDeductionAge"
                #programElderlyDeductionAgeInput="ngModel"
                aria-label="Elderly deduction age">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Age to Qualify for Near Elder Status</mat-label>
            <input 
                matInput
                type="number" 
                id="programNearElderAgeInput"
                aria-label="Near elder age"
                [(ngModel)]="rentCalc.nearElderAge"
                #programNearElderAgeInput="ngModel"
                name="programRentCalcNearElderAge"> 
        </mat-form-field>
    </div>

    <div class="layout-row layout-align-start-center">
        <mat-form-field appearance="outline" *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
            <mat-label>Income Limits Area</mat-label>
            <mat-select 
                aria-label="Select income limit area for this Low Rent program"
                id="incomeLimitDropdown"
                [(ngModel)]="rentCalc.incomeLimitAreaId"
                #incomeLimitDropdown="ngModel"
                name="incomeLimitDropdown">
                <mat-option [value] ="null" id ="none"> none </mat-option>
                <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.incomeLimitAreas"
                    id="{{a.name}}">{{a.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="housingAuthority && housingAuthority.incomeLimitAreas">
            <mat-label>Tax Credit Income Limits Area</mat-label>
            <mat-select
                aria-label="Select income limit area for this Tax Credit program"
                id="taxCreditIncomeLimitDropdown"
                name="taxCreditIncomeLimitDropdown"
                [(ngModel)]="rentCalc.taxCreditIncomeLimitAreaId"
                #taxCreditIncomeLimitDropdown="ngModel"
                [disabled]="programType !== 'Tax Credit'"
                >
                <mat-option [value]="null" id="none"> none </mat-option>
                <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.incomeLimitAreas">
                    {{a.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  *ngIf="housingAuthority && housingAuthority.rentLimitAreas">
            <mat-label>Rent Limits Area</mat-label>
            <mat-select
                aria-label="Select rent limit area for this Low Rent program"
                id="rentLimitDropdown"
                [(ngModel)]="rentCalc.rentLimitAreaId"
                #rentLimitDropdown="ngModel"
                name="rentLimitDropdown">
                <mat-option [value]="null" id="none"> none </mat-option>
                <mat-option value="{{a._id}}" *ngFor="let a of housingAuthority.rentLimitAreas"
                    id="{{a.name}}">{{a.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="layout-row layout-align-start-center">
        <mat-form-field appearance="outline">
            <mat-label>Calc Key</mat-label>
            <input 
                matInput
                type="text"
                id="rentCalcInput"
                name="rentCalcInput"
                [(ngModel)]="rentCalc.calcKey"
                #rentCalcInput="ngModel"
                aria-label="Calc key">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Assistance Calc Key</mat-label>
            <input 
                matInput
                type="text"
                id="assistanceRentCalcInput"
                name="assistanceRentCalcInput"
                [(ngModel)]="rentCalc.assistanceCalcKey"
                #assistanceRentCalcInput="ngModel"
                aria-label="Calc key">
        </mat-form-field>
        <mat-form-field appearance="outline"  *ngIf="housingAuthority && housingAuthority.paymentStandards">
            <mat-label>Payment Standards</mat-label>
            <mat-select
                aria-label="Select payment standard for this Low Rent program"
                id="paymentStandardDropdown"
                [(ngModel)]="rentCalc.paymentStandardId"
                #paymentStandardDropdown="ngModel"
                name="paymentStandardDropdown">
                <mat-option [value] = "null" id = "none"> none </mat-option>
                <mat-option value="{{paymentStandard._id}}"
                    *ngFor="let paymentStandard of housingAuthority.paymentStandards"
                    id="{{paymentStandard.name}}">{{paymentStandard.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="layout-row layout-align-start-center">
        <mat-form-field appearance="outline" *ngIf="programType == 'Public Housing'">
            <mat-label>Min TTP (50058 Form, 9h)</mat-label>
            <input 
                matInput
                type="text"
                id="minTTP"
                name="minTTP"
                [(ngModel)]="rentCalc.minTTP"
                #minTTP="ngModel"
                min="0"
                max="51"
                step="1"
                pattern="^(?:[0-9]|[1-4][0-9]|5[01])$"
                required
                (keypress)="validateInput($event)"
                aria-label="Min TTP">
                <mat-error *ngIf="minTTP.hasError('min')">
                    <div>Min TTP must be between 0-51</div>
                </mat-error>
                <mat-error *ngIf="minTTP.hasError('max')">
                    <div>Min TTP must be between 0-51</div>
                </mat-error>
                <mat-error *ngIf="minTTP.hasError('pattern')">
                    <div>Min TTP must be a whole number</div>
                </mat-error>
                <mat-error *ngIf="minTTP.hasError('required')">
                    <div>Min TTP is required. Please enter a number between 0-51</div>
                </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Fiscal Year End Month</mat-label>
            <mat-select 
                placeholder="Choose One"
                aria-label="Fiscal Year-End Month"
                id="fiscalYearEndDropdown"
                [(ngModel)]="rentCalc.monthOfFiscalYearEnd"
                #fiscalYearEndDropdown="ngModel"
                name="fiscalYearEndDropdown">
                <mat-option [value]="null" id="0">None</mat-option>
                <mat-option [value]="1" id="1">January</mat-option>
                <mat-option [value]="2" id="2">February</mat-option>
                <mat-option [value]="3" id="3">March</mat-option>
                <mat-option [value]="4" id="4">April</mat-option>
                <mat-option [value]="5" id="5">May</mat-option>
                <mat-option [value]="6" id="6">June</mat-option>
                <mat-option [value]="7" id="7">July</mat-option>
                <mat-option [value]="8" id="8">August</mat-option>
                <mat-option [value]="9" id="9">September</mat-option>
                <mat-option [value]="10" id="10">October</mat-option>
                <mat-option [value]="11" id="11">November</mat-option>
                <mat-option [value]="12" id="12">December</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="housingAuthority && housingAuthority.fundingSources">
            <mat-label>Source of Funding</mat-label>
            <mat-select 
                aria-label="Funding Source"
                id="fundingSourceDropdown"
                [(ngModel)]="rentCalc.fundingSource"
                #fundingSourceDropdown="ngModel"
                name="fundingSourceDropdown">
                <mat-option [value]="null" id ="none">none</mat-option>
                <mat-option [value]="source" *ngFor="let source of housingAuthority.fundingSources"
                    id="{{source}}">{{source}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="layout-row layout-align-start-center">
        <div class="checkboxPadding section-body">
            <mat-checkbox
                id="programNahasda30PercentInput"
                name="programNahasda30PercentInput"
                [(ngModel)]="rentCalc.observeNahasda30Percent"
                #programNahasda30PercentInput="ngModel"
                aria-label="does this program observe the nahasda 30 percent rule?" >
            </mat-checkbox>
            <mat-label class="wordPadding">Observe NAHASDA 30% Rule</mat-label>      
        </div>
    </div>

    <div class="section-body layout-row layout-align-start-center">
        <div class="requiresHotmaUpdates wordPaddingHotma" appearance="outline">Does this program require<br/>Hotma Updates?</div>
        <mat-radio-group
            aria-label="Does this program require Hotma Updates?"
            id="programRequiresHotmaUpdates"
            [(ngModel)]="rentCalc.requireHotmaUpdates"
            #programRequiresHotmaUpdates="ngModel"
            name="programRequiresHotmaUpdates">
            <mat-radio-button [value]="true" class="md-primary leftPadding" id="hotmaUpdateRequired">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="md-primary leftPadding" id="hotmaUpdateNotRequired">No</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="section-body layout-row layout-align-start-center">
        <mat-radio-group 
            aria-label="Does this program require utilityAllowance calculations?"
            class="layout-row"
            id="isUtilityAllowanceCalculations"
            [(ngModel)]="rentCalc.isUtilityAllowanceCalculations"
            #isUtilityAllowanceCalculations="ngModel"
            name="isUtilityAllowanceCalculations">
            <mat-radio-button [value]="true" class="md-primary leftPadding" id="utilityAllowanceCalculations">Utility Allowance Calculation</mat-radio-button>
            <mat-radio-button [value]="false" class="md-primary leftPadding" id="flatRateutilityCalculations"> Flat Rate Utility Allowance</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="layout-row layout-align-center-start">
        <div class="section-body">
            <mat-form-field appearance="outline">
                <mat-label>RentCalc Options</mat-label>
                <mat-select 
                [(ngModel)]="selectedRentCalcOption"
                (selectionChange)="changeDisplayedRentCalcOption()"
                name="rentCalcOption"
                >
                    <mat-option value=AdminFees id="adminFees">Admin Fee</mat-option>
                    <mat-option value=FlatRents id="flatRents">Flat Rent/Charge</mat-option>
                    <mat-option value=FairMarketRents id="fairMarketRents">Fair Market Rent</mat-option>
                    <mat-option value=MinimumCharges id="minimumCharges">Minimun Rent/Charge</mat-option>
                    <mat-option value=MaximumCharges id="maximumCharges">Maximum Rent/Charge</mat-option>
                    <mat-option value=UtilityAllowances id="utilityAllowances">Utility Allowance</mat-option>
                    <mat-option value=RentLimits id="rentLimits">Rent Limits (Max Gross Rent - Tax Credits)</mat-option>
                    <mat-option value=BasicRents id="basicRents">Basic Rent (Rural Development Only)</mat-option>
                    <mat-option value=NoteRateRents id="noteRateRents">Note Rate Rent (Rural Development Only)</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="fee-table-container" *ngIf="selectedRentCalcOption && selectedRentCalcFeeTables">
        <div class="inner-padding">
            <button
                mat-flat-button 
                class="hds_button" 
                class="marg-left-auto hds_button" 
                (click)="newRentCalcOption()"
                >Add New Entry
            </button>
            <p class="leftPadding" *ngIf="!selectedRentCalcFeeTables || selectedRentCalcFeeTables && selectedRentCalcFeeTables.length === 0">No values defined</p>
        </div>
        <table mat-table [dataSource]="selectedRentCalcFeeTables">

            <ng-container matColumnDef="effectiveDate">
              <th mat-header-cell *matHeaderCellDef> Effective Date </th>
              <td mat-cell *matCellDef="let element"> {{ element.effectiveDate | date: 'yyyy-MM-dd' }} </td>
            </ng-container>

            <ng-container *ngFor="let size of bedroomSizes" [matColumnDef]="'bedroom' + size">
              <th mat-header-cell *matHeaderCellDef>  {{size}} Bedrooms </th>
              <td mat-cell *matCellDef="let element"> {{ getAmount(element, size) | currency}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <a style="font-size:18px;" 
                    (click)="openRentCalcOptionsDialog(i)"><mat-icon>edit</mat-icon>
                    </a>
                    <a style="font-size:18px;margin-left: 10px;" 
                        (click)="deleteRentCalcOptions(i)"><mat-icon>delete</mat-icon>
                    </a>  
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>