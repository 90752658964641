import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from 'projects/material/src/public_api';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {AccountingRoutingModule} from './accounting-routing.module';
import {PayoutsComponent} from './payouts/payouts.component';
import {PayablesHistoryComponent} from './payouts/payables-history/payables-history.component';
import {PayableDetailComponent} from './payouts/payable-detail/payable-detail.component';
import {PostAdjustmentComponent} from './payouts/post-adjustment/post-adjustment.component';
import {PayablesNotesComponent} from './payouts/payables-notes/payables-notes.component';
import {PayablePostedOnComponent} from './payouts/payable-detail/payable-posted-on/payable-posted-on.component';
import {PayablesWizardComponent} from './payouts/payables-wizard/payables-wizard.component';
import {ComponentsModule} from 'src/app/kanso-common/core/components/components.module';
import {PayableWizardTableComponent} from './payouts/payables-wizard/payable-wizard-table/payable-wizard-table.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {CoreModule} from 'src/app/core';
import {MonthEndComponent} from './month-end/month-end.component';
// import { SubledgerMappingEditFullComponent } from './subledger-mapping-edit-full/subledger-mapping-edit-full.component';

@NgModule({
  declarations: [
    // SubledgerMappingEditFullComponent
    PayoutsComponent,
    PayablesHistoryComponent,
    PayableDetailComponent,
    PostAdjustmentComponent,
    PayablesNotesComponent,
    PayablePostedOnComponent,
    PayablesWizardComponent,
    PayableWizardTableComponent,
    MonthEndComponent,
  ],
  imports: [
    CommonModule,
    AccountingRoutingModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatIconModule,
    MatInputModule,
    SharedModule,
    CoreModule,
  ],
})
export class AccountingModule {}
