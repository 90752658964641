import {Component, Injector} from '@angular/core';
import {FormControl} from '@angular/forms';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {Program, ProgramModalData, UnitUiSections} from '../../housing-core/services/housing-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {GlobalModalService} from 'src/app/kanso-common/core/service/globalmodal.service';
import {ProgramSetupComponent} from '../program-setup/program-setup.component';
import {map, startWith} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {CoreService} from 'src/app/core/service/core.service';
import {ProgramsService} from 'src/app/shared/services/programs.service';
import lodash from 'lodash';
import {ProgramModel} from 'src/app/shared/services/program.model';
import {ComplexSaveResult, CustomAttribute} from 'src/app/core/service/core-models';
import {ProgramObjectModal} from 'src/app/shared/services/program.object';

@Component({
  selector: 'program-setup-list',
  templateUrl: './program-setup-list.component.html',
  styleUrls: ['./program-setup-list.component.scss'],
})
export class ProgramSetupListComponent {
  programs: ProgramModel[];
  program: ProgramModel;
  filteredPrograms$: Observable<ProgramModel[]>;
  programFilter = new FormControl('');
  programSort = new FormControl('');
  newProgram: ProgramModel;
  selectedProgram?: ProgramModel;
  error = false;
  errorMsg = '';
  selectedprogramsIndex: number;
  selectedprogramSnapshot: ProgramModel;
  isBusy = false;
  busyText: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  loggedInUser;
  selectedProgramSnapshot: ProgramModel;
  isNewRecord = false;
  globalModalParent: any;
  unitPathCustomAttributeFlag: string;

  constructor(
    public housingServices: HousingServices,
    public accountingService: AccountingService,
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public coreService: CoreService,
    public programsService: ProgramsService
  ) {}

  ngOnInit(): void {
    this.getPrograms();
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  getPrograms() {
    this.setBusy('Loading Programs');
    this.programsService.getPrograms().subscribe(
      response => {
        this.programs = response;
        this.filteredPrograms$ = combineLatest([
          this.programFilter.valueChanges.pipe(
            startWith(null),
            map(value => value)
          ),
          this.programSort.valueChanges.pipe(
            startWith('name'),
            map(value => value)
          ),
        ]).pipe(map(([aName, aSort]) => this._filterPrograms(aSort, aName)));
        this.isBusy = false;
      },
      error => {
        console.log(`Error fetching accounts: ${error}`);
        this.errorMsg = 'An unexpected error occurred fetching program records. Please try again.';
        this.isBusy = false;
        this.error = true;
      }
    );
  }

  private _filterPrograms(sortValue: string, filterValue?: string): ProgramModel[] {
    return filterValue
      ? this._sortByProperty(
          this.programs.filter(program => program.name.toLowerCase().includes(filterValue.toLowerCase())),
          sortValue
        )
      : this._sortByProperty(this.programs, sortValue);
  }

  private _sortByProperty(accounts: ProgramModel[], propertyName: string) {
    return accounts.sort((a: ProgramModel, b: ProgramModel) => {
      const aName = a[propertyName].toLowerCase();
      const bName = b[propertyName].toLowerCase();
      switch (true) {
        case aName > bName:
          return 1;
        case aName < bName:
          return -1;
        default:
          return 0;
      }
    });
  }

  handleUpdateProgram = async () => {
    this.setBusy('Loading Program Details');
    try {
      const result: ComplexSaveResult = await this.programsService.saveProgram(this.selectedProgram, this.selectedProgramSnapshot);
      if (!result) {
        throw new Error('Result is undefined');
      }
      if (result.success) {
        this.getPrograms();
      } else {
        this.coreService.displayError(result.errorMessage || 'There was an error saving the program');
      }
    } catch (error) {
      this.coreService.displayError('There was an error saving the program');
      console.error('There was an error saving the program', error);
    } finally {
      this.isBusy = false;
    }
  };

  cancel = () => {
    this.globalModalParent.closePopup();
  };

  async editProgram(program: ProgramModel = {} as ProgramModel, i?: number) {
    this.selectedProgram = program;
    const programExists = Array.isArray(this.programs) && i !== undefined && i >= 0 && i < this.programs.length;
    if (programExists) {
      this.selectedProgramSnapshot = lodash.cloneDeep(this.programs[i]);
    } else {
      this.selectedProgramSnapshot = {
        customerId: this.CUSTOMER_ID,
        siteId: this.SITE_ID,
        name: '',
        programCode: '',
        phaCode: '',
        enhancedRentToggle: false,
        requires58PicForm: false,
        recertPeriodUnit: null,
        recertificationPeriod: null,
        isRecertificationRequired: false,
        isHousingProgram: false,
        createdBy: this.loggedInUser,
        programType: null,
        programSettings: null,
        enhancedRents: [],
      };
      this.selectedProgram = new ProgramObjectModal();
      this.selectedProgram.isRecertificationRequired = true;
      this.selectedProgram.recertificationPeriod = 12;
      this.selectedProgram.recertPeriodUnit = 'Months';
    }
    const isNew = !program.id;
    this.globalModalService.openModal<ProgramModalData>(
      ProgramSetupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        openPanel: this.unitPathCustomAttributeFlag ? UnitUiSections.CustomAttributes : UnitUiSections.GeneralInfo,
        cancelFunction: this.cancel,
        program: this.selectedProgram,
        selectedProgram: this.selectedProgram,
        editMode: !isNew,
        updateFunction: this.handleUpdateProgram,
      }
    );
  }
}
