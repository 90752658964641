import {NgModule} from '@angular/core';
import {FeatureConfigurationService, UserService, SitePropertiesService} from './services';
import {CommonModule} from '@angular/common';
import {WidgetTitleComponent} from './widget-title/widget-title.component';
import {MaterialModule} from '../../../projects/material/src/public_api';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HasPermissionDirective} from './directives/has-permission.directive';
import {HttpClientModule} from '@angular/common/http';
import {ForceInitComponent} from './force-init/force-init.component';
import {IntegrationsService} from './services/integrations-service';

@NgModule({
  declarations: [WidgetTitleComponent, HasPermissionDirective, ForceInitComponent],
  providers: [FeatureConfigurationService, UserService, SitePropertiesService, IntegrationsService],
  exports: [WidgetTitleComponent, MaterialModule, HasPermissionDirective, ForceInitComponent],
  imports: [CommonModule, MaterialModule, BrowserAnimationsModule, HttpClientModule],
})
export class SharedModule {}
