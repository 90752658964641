<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>{{(editMode ? 'Edit':'Add')}} - Activity Rule for {{selectedAccountDefinition.name}}</span>
            <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #editActivityRulesForm="ngForm" novalidate>
        <mat-card-content>
            <div class="layout-row">
              <span> Source Account: {{selectedAccountDefinition.name}}</span>
            </div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Source Transaction Type:</mat-label>
                <mat-select matInput
                  #sourceTransactionType="ngModel" 
                  name="sourceTransactionType"
                  [(ngModel)]="editActivityRules.sourceTransactionType"
                  (selectionChange)="changeSourceTransactionType(sourceTransactionType.control.value)"
                  placeholder="Select Type"
                  [compareWith]="compareText"
                  required>
                    <mat-option  class="hds-option" value="Credit">Credit</mat-option>
                    <mat-option  class="hds-option" value="Charge">Charge</mat-option>
                    <mat-option  class="hds-option" value="Credit Adjustment">Credit Adjustment</mat-option>
                    <mat-option  class="hds-option" value="Charge Adjustment">Charge Adjustment</mat-option>
                  </mat-select>
                  <mat-error *ngIf="sourceTransactionType.hasError('required')">
                    <div ng-message="required">You must select a Source Transaction Type</div>
                  </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Source Transaction Sub-Category:</mat-label>
                <mat-select matInput ngModel
                  #sourceSubCategoryType="ngModel" name="sourceSubCategoryType"
                  [(ngModel)]="editActivityRules.sourceTransactionSubCategory"
                  placeholder="Select Sub-Category Type"
                  [compareWith]="compareValue"
                  required>
                  <mat-optgroup *ngFor="let cat of this.selectableSourceSubCat" [label]="cat.name">
                    <mat-option *ngFor="let subcat of cat.subCategories" [value]="subcat" >
                        {{ subcat.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="sourceSubCategoryType.hasError('required')">
                  <div ng-message="required">You select a Source Sub Category</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Destination Account:</mat-label>
                <mat-select matInput
                  #destinationAccount="ngModel"
                  name="destinationAccount"
                  [(ngModel)]="editActivityRules.destinationAccount"
                  (selectionChange)="changeDestinationSource(destinationAccount.control.value)"
                  placeholder="Select Type"
                  required>
                  <mat-option [value]="account" *ngFor="let account of this.accountDefinitions">
                    {{ account.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="destinationAccount.hasError('required')">
                  <div ng-message="required">You select a Destination Account</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Destination Adjustment Type:</mat-label>
                <mat-select matInput ngModel
                  #adjustmentTransactionType="ngModel"name="adjustmentTransactionType"
                  [(ngModel)]="editActivityRules.adjustmentTransactionType"
                  [compareWith]="compareText"
                  placeholder="Select Type"
                  required>
                    <mat-option  class="hds-option" value="Credit">Credit</mat-option>
                    <mat-option  class="hds-option" value="Charge">Charge</mat-option>
                  </mat-select>
                  <mat-error *ngIf="adjustmentTransactionType.hasError('required')">
                    <div ng-message="required">You select a Destination Transaction Type</div>
                  </mat-error>
              </mat-form-field>
            </div>
            <div>
              <span>:</span>
              <mat-form-field appearance="outline">
                <mat-label>Destination Adjustment Sub-Category:</mat-label>
                <mat-select matInput ngModel
                  #adjustmentTransactionSubCategory="ngModel" name="adjustmentTransactionSubCategory"
                  [(ngModel)]="editActivityRules.adjustmentTransactionSubCategory"
                  placeholder="Select Sub-Category Type"
                  [compareWith]="compareValue"
                  required>
                  <mat-optgroup *ngFor="let cat of this.selectableDestinationSubCat" [label]="cat.name">
                    <mat-option *ngFor="let subcat of cat.subCategories" [value]="subcat">
                        {{ subcat.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="adjustmentTransactionSubCategory.hasError('required')">
                  <div ng-message="required">You select a Destination Sub Category</div>
                </mat-error>
              </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button class="hds_button cancel" (click)="cancel()" id="cancelButton">
              Cancel
            </button>
            <button mat-flat-button class="hds_button delete" (click)="delete()" id="cancelButton">
              Delete
            </button>
            <button 
                mat-flat-button 
                class="hds_button" 
                (click)="onSubmit()"
                [disabled]="!editActivityRulesForm.valid || editActivityRulesForm.pristine"
                id="saveButton">
                Save
            </button>
        </mat-card-actions>
    </form>
  </mat-card>