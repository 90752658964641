import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {dataWithPlainTextKey, encryptedKeyObject, decryptInputObject, encryptArrayObject} from './encryption-model';
import {CoreService} from 'src/app/core/service/core.service';
import {BaseService} from 'src/app/kanso-common/core/service';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService extends BaseService {
  private headers: any;
  private encryptionUrl: string;
  private encoder: TextEncoder;
  private encryptedArray: encryptArrayObject = {
    plainTextArray: [],
  };
  private dataVector: string;
  constructor(public http: HttpClient, public coreService: CoreService) {
    super(http);
    this.headers = new HttpHeaders({
      'content-type': 'application/json',
      'x-api-key': sessionStorage.getItem('DATA_KEY_SERVICE_API_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    });
    this.encryptionUrl = sessionStorage.getItem('DATA_KEY_SERVICE_URI');
    this.encoder = new TextEncoder();
  }

  async encryptData(plaintext: string) {
    try {
      const kmsDataKey = await this.generateDataKey(1);
      const encryptedDataObject = await this.encryptTextData(plaintext, kmsDataKey[0].plainTextKey);
      return {
        encryptedData: encryptedDataObject.cipherText,
        dataKey: kmsDataKey[0].encryptedKey,
        dataVector: encryptedDataObject.vector,
      };
    } catch (error) {
      this.coreService.displayError('there was error when encrypting data');
      console.log(error);
    }
  }

  async encryptArrayData(plainTextDataArray: string[]) {
    try {
      const kmsDataKey = await this.generateDataKey(1);
      const encryptedDataObject = await this.encryptTextDataArray(plainTextDataArray, kmsDataKey[0].plainTextKey);
      return {
        encryptedData: encryptedDataObject.cipherTexts,
        dataKey: kmsDataKey[0].encryptedKey,
        dataVector: encryptedDataObject.vector,
      };
    } catch (error) {
      this.coreService.displayError('there was error when encrypting data');
      console.log(error);
    }
  }

  async decryptArrayData(encryptedData: string[], encryptedKey: string, vector: string) {
    const encryptedKeyObjectListBodyRequest: encryptedKeyObject[] = [
      {
        plainTextKey: '',
        encryptedKey: encryptedKey,
      },
    ];
    const encryptedKeyObjectCollection: encryptedKeyObject[] = await this.decryptDataKeys(encryptedKeyObjectListBodyRequest);
    for (const encryptedText of encryptedData) {
      const decryptedText = await this.decryptTextData(encryptedText, encryptedKeyObjectCollection[0].plainTextKey, vector);
      this.encryptedArray.plainTextArray.push(decryptedText);
    }
    return this.encryptedArray;
  }

  async decryptData(encryptedData: string, encryptedKey: string, vector: string) {
    const encryptedKeyObjectListBodyRequest: encryptedKeyObject[] = [
      {
        plainTextKey: '',
        encryptedKey: encryptedKey,
      },
    ];
    const encryptedKeyObjectCollection: encryptedKeyObject[] = await this.decryptDataKeys(encryptedKeyObjectListBodyRequest);
    return this.decryptTextData(encryptedData, encryptedKeyObjectCollection[0].plainTextKey, vector);
  }

  async decryptDataCollection(decryptInputList: decryptInputObject[]): Promise<dataWithPlainTextKey[]> {
    const encryptedKeyObjectList: encryptedKeyObject[] = decryptInputList.map(decryptInput => ({
      plainTextKey: '',
      encryptedKey: decryptInput.dataKey,
    }));

    const encryptedKeyObjectCollection: encryptedKeyObject[] = await this.decryptDataKeys(encryptedKeyObjectList);

    const dataWithPlainTextKey: dataWithPlainTextKey[] = decryptInputList
      .map(decryptInput => {
        const encryptedKeyObject = encryptedKeyObjectCollection.find(keyObject => keyObject.encryptedKey === decryptInput.dataKey);
        if (encryptedKeyObject) {
          return {
            data: decryptInput.encryptedData,
            plainTextKey: encryptedKeyObject.plainTextKey,
            dataVector: decryptInput.dataVector,
          };
        }
        return null;
      })
      .filter(item => item !== null);

    for (const encryptData of dataWithPlainTextKey) {
      encryptData.data = await this.decryptTextData(encryptData.data, encryptData.plainTextKey, encryptData.dataVector);
    }

    return dataWithPlainTextKey;
  }

  //KMS Service functions
  private async generateDataKey(amountOfKeys: number): Promise<encryptedKeyObject[]> {
    return this.http
      .get<HttpResponse<encryptedKeyObject[]>>(this.encryptionUrl + `/datakey/${amountOfKeys}`, {headers: this.headers})
      .toPromise()
      .then(res => {
        return res.body;
      });
  }

  private async decryptDataKeys(encryptedKeyObjectList: encryptedKeyObject[]): Promise<encryptedKeyObject[]> {
    return this.http
      .post<HttpResponse<encryptedKeyObject[]>>(this.encryptionUrl + `/datakey/decrypt`, encryptedKeyObjectList, {headers: this.headers})
      .toPromise()
      .then(res => {
        return res.body;
      });
  }
  //End KMS Service functions

  //Crypto functions
  private async encryptTextData(cleartext: string, unencryptedMasterKey: string) {
    // Generate an initialization vector
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encodedData = new TextEncoder().encode(cleartext);
    const cryptoKey: CryptoKey = await this.importKey(unencryptedMasterKey);

    // Encrypt the data
    const encryptedBuffer = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      cryptoKey,
      encodedData
    );

    // Convert the encrypted buffer to a Uint8Array and then to a Base64 string
    const encryptedArray = new Uint8Array(encryptedBuffer);
    const encryptedText = this.uint8ArrayToBase64(encryptedArray);

    return {
      cipherText: encryptedText,
      vector: this.uint8ArrayToBase64(iv),
    };
  }

  private async encryptTextDataArray(cleartextArray: string[], unencryptedMasterKey: string) {
    // Generate an initialization vector
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const cryptoKey: CryptoKey = await this.importKey(unencryptedMasterKey);

    // Function to encrypt a single piece of text
    const encryptSingleText = async (cleartext: string) => {
      const encodedData = new TextEncoder().encode(cleartext);

      // Encrypt the data
      const encryptedBuffer = await window.crypto.subtle.encrypt(
        {
          name: 'AES-GCM',
          iv: iv, // Use the same IV for each encryption
        },
        cryptoKey,
        encodedData
      );

      // Convert the encrypted buffer to a Uint8Array and then to a Base64 string
      const encryptedArray = new Uint8Array(encryptedBuffer);
      return this.uint8ArrayToBase64(encryptedArray);
    };

    // Encrypt each cleartext in the array
    const encryptedArray = await Promise.all(cleartextArray.map(encryptSingleText));

    // Return the array of encrypted texts and the IV
    return {
      cipherTexts: encryptedArray, // Array of encrypted data
      vector: this.uint8ArrayToBase64(iv), // The IV used for encryption
    };
  }

  private async decryptTextData(cipherText: string, unencryptedMasterKey: string, vector: string): Promise<string> {
    try {
      const iv: Uint8Array = this.base64ToUint8Array(vector);
      const cryptoKey: CryptoKey = await this.importKey(unencryptedMasterKey);
      const encryptedArray: Uint8Array = this.base64ToUint8Array(cipherText);
      const decryptedData: ArrayBuffer = await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: iv,
        },
        cryptoKey,
        encryptedArray
      );
      const decoder = new TextDecoder();
      return decoder.decode(decryptedData);
    } catch (error) {
      console.log(error);
    }
  }
  private async importKey(base64Key: string): Promise<CryptoKey> {
    const rawKey = Uint8Array.from(atob(base64Key), c => c.charCodeAt(0)).buffer;
    return await window.crypto.subtle.importKey('raw', rawKey, {name: 'AES-GCM'}, true, ['encrypt', 'decrypt']);
  }
  //End Crypto functions

  //Conversion Functions
  private uint8ArrayToBase64(uint8Array: Uint8Array): string {
    let binaryString = '';
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binaryString);
  }

  private base64ToUint8Array(base64String: string): Uint8Array {
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const uint8Array = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    return uint8Array;
  }
  //End Conversion Functions
}
