<section class="tabs__panel layout-column housing-authority-setup-container">
    <busy-notice
        [isBusy]="this.isBusy"
        [busyText]="this.busyText"
        headerText="KANSO">
    </busy-notice>
    <div class="page-header">
        <h2>Housing Authority</h2>
    </div>
    <div *ngIf="error" class="layout-row">
        {{errorMsg}}
    </div>
    <div *ngIf="!loading && !error"> 
        <div class="section-header layout-row removeSectionHeader">
            <a class="account__details-collapse">
                <mat-icon class="collapse-icon" 
                    [ngClass]="{'collapse-icon--open' : generalInfoPanelOpenState}"
                    (click)="generalInfoPanelOpenState = !generalInfoPanelOpenState">chevron_right
                </mat-icon>
            </a>
            <div class="rowPaddingLeft">
                General Information
            </div>
        </div>
            <form #editHousingAuthorityForm="ngForm">
                <mat-expansion-panel [expanded]="generalInfoPanelOpenState" class="hidePanel">
                    <div class="layout-row rowPaddingLeft">
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="largeInputBox">
                                <mat-label>Housing Authority Name</mat-label>
                                <input 
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.name"
                                    #authorityName="ngModel"
                                    name="authorityName"
                                    id="authorityNameInput" 
                                    required 
                                    aria-label="Name">
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Federal Tax ID</mat-label>
                                <input 
                                    type = number
                                    matInput 
                                    placeholder= "Only numbers allowed" 
                                    ngModel
                                    [(ngModel)]="authorityCopy.fedTaxId"
                                    #authorityTaxId="ngModel"
                                    name="authorityTaxId"
                                    id="authorityTaxIdInput" 
                                    aria-label="Federal Tax ID">
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Month of Fiscal Year End</mat-label>
                                <mat-select 
                                    name="monthOfFiscalYearEnd"
                                    [(ngModel)]="authorityCopy.rentCalc.monthOfFiscalYearEnd"
                                    id="monthOfFiscalYearEndInput"
                                    aria-label="Month Of Fiscal Year End" >
                                        <mat-option [value]="0" id="NA">NA</mat-option>    
                                        <mat-option [value]="1" id="January">January</mat-option>
                                        <mat-option [value]="2" id="February">February</mat-option>
                                        <mat-option [value]="3" id="March">March</mat-option>
                                        <mat-option [value]="4" id="April">April</mat-option>
                                        <mat-option [value]="5" id="May">May</mat-option>
                                        <mat-option [value]="6" id="June">June</mat-option>
                                        <mat-option [value]="7" id="July">July</mat-option>
                                        <mat-option [value]="8" id="August">August</mat-option>
                                        <mat-option [value]="9" id="September">September</mat-option>
                                        <mat-option [value]="10" id="October">October</mat-option>
                                        <mat-option [value]="11" id="November">November</mat-option>
                                        <mat-option [value]="12" id="December">December</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="layout-row rowPaddingLeft">
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="largeInputBox">
                                <mat-label>Address</mat-label>
                                <input 
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.address[0].streetAddress"
                                    #authorityAddress="ngModel"
                                    name="authorityAddress"
                                    id="authorityAddressInput" 
                                    required 
                                    aria-label="Address">
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Unit</mat-label>
                                <input 
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.address[0].unit"
                                    #authorityUnit="ngModel"
                                    name="authorityUnit"
                                    id="authorityUnitInput" 
                                    aria-label="Unit">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="layout-row rowPaddingLeft">
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>City</mat-label>
                                <input 
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.address[0].city"
                                    #authorityCity="ngModel"
                                    name="authorityCity"
                                    id="authorityCityInput" 
                                    required 
                                    aria-label="City">
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>State</mat-label>
                                <input
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.address[0].state"
                                    #authorityState="ngModel"
                                    name="authorityState"
                                    id="authorityStateInput" 
                                    required
                                    aria-label="State"
                                    (keypress)="stateValidation($event)"
                                    minlength="2"
                                    maxlength="2">
                                    <mat-error *ngIf="authorityState.hasError('minlength')">
                                        <div>Minimum length of 2 letters.</div>
                                    </mat-error>
                                    <mat-error *ngIf="authorityState.hasError('maxlength')">
                                        <div>Maximum length of 2 letters.</div>
                                    </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Postal Code</mat-label>
                                <input
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.address[0].postalCode"
                                    #authorityPostalCode="ngModel"
                                    name="authorityPostalCode"
                                    id="authorityPostalCodeInput" 
                                    required
                                    aria-label="Postal Code"
                                    (keypress)="numericOnly($event)"
                                    minlength="5"
                                    maxlength="5">
                                    <mat-error *ngIf="authorityPostalCode.hasError('minlength')">
                                        <div>Minimum length of 5</div>
                                    </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="layout-row rowPaddingLeft">
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Phone Number</mat-label>
                                <input 
                                    matInput 
                                    placeholder="xxx-xxx-xxxx" 
                                    ngModel
                                    [(ngModel)]="authorityCopy.phone"
                                    #authorityPhoneNumber="ngModel"
                                    name="authorityPhoneNumber"
                                    id="authorityPhoneInput" 
                                    aria-label="Authority Phone Number">
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Fax</mat-label>
                                <input 
                                    matInput 
                                    placeholder="xxx-xxx-xxxx" 
                                    ngModel
                                    [(ngModel)]="authorityCopy.fax"
                                    #authorityFaxNumber="ngModel"
                                    name="authorityFaxNumber"
                                    id="authorityFaxInput" 
                                    aria-label="Authority Fax Number">
                            </mat-form-field>
                        </div>
                        <div class="layout-align-start-center">
                            <mat-form-field appearance="outline" class="medInputBox">
                                <mat-label>Email</mat-label>
                                <input 
                                    matInput 
                                    ngModel
                                    [(ngModel)]="authorityCopy.email"
                                    #authorityEmail="ngModel"
                                    name="authorityEmail"
                                    id="authorityEmailInput" 
                                    aria-label="Email">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="layout-align-start-center rowPaddingLeft">
                        <mat-form-field hintLabel="Max 5 characters" appearance="outline" class="medInputBox" >
                            <mat-label>PHA Code</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="authorityCopy.phaCode" 
                                #authorityPhaCode="ngModel"
                                name="authorityPhaCode"
                                id="authorityPhaCodeInput"
                                (input)="phaCodeValidation($event.target.value)"
                                pattern="[a-zA-Z]{2}[0-9]{3}"
                                minlength="5"
                                maxlength="5"                                aria-label="PHA Code">
                            <mat-error *ngIf="authorityPhaCode.hasError('minlength')">
                                <div>Minimum length of 5</div>
                            </mat-error>
                            <mat-error *ngIf="authorityPhaCode.hasError('pattern')">
                                <div>PHA Code must be in the format AA999.  Two letters then three numbers.</div>
                            </mat-error>
                            <mat-hint align ="end">{{authorityCopy.phaCode?.length || 0}}/5</mat-hint>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            
                <div class="section-header layout-row layout-align-start-center removeSectionHeader">
                    <a class="account__details-collapse topPadding">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : officeLocationPanelOpenState}"
                            (click)="officeLocationPanelOpenState = !officeLocationPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                        <div>Office Locations</div>
                        <button mat-flat-button 
                            type="button"
                            class="hds_button" 
                            (click)="editOfficeLocation('add', editHousingAuthorityForm)" 
                            id="addAuthorityOfficeLocationButton"
                            aria-label="Add">
                            Add
                        </button>
                    </div>
                </div>
                <mat-expansion-panel [expanded]="officeLocationPanelOpenState" class="hidePanel">
                    <table class="altRows">
                        <thead>
                            <tr class="centerData" >
                                <th>Name</th>
                                <th>Address</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData" *ngFor="let location of authorityCopy.officeLocations; let $index=index">
                            <td (click)="editOfficeLocation(location, editHousingAuthorityForm)"><a>{{location.name}}</a></td>
                            <td>{{location.address[0].streetAddress}} {{location.address[0].unit}} {{location.address[0].city}}, {{location.address[0].state}} {{location.address[0].postalCode}}</td>
                            <td >
                                <a (click)="editOfficeLocation(location, editHousingAuthorityForm)">
                                    <mat-icon>create</mat-icon>
                                </a>
                            </td>
                            <td>
                                <a (click)="removeOfficeLocation($index, editHousingAuthorityForm)">
                                    <mat-icon>delete_outline</mat-icon>
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel>
                <div class="section-header layout-row moveButton removeSectionHeader">
                    <a class="account__details-collapse topPadding">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : departmentsPanelOpenState}"
                            (click)="departmentsPanelOpenState = !departmentsPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                        <div>Departments</div>
                        <button mat-flat-button 
                            (click)="editDepartment('add', editHousingAuthorityForm)" 
                            class="hds_button" 
                            id="addNewDepartment"
                            aria-label="Add Department">
                            Add
                        </button>
                    </div>
                </div>
                <mat-expansion-panel [expanded]="departmentsPanelOpenState">
                    <table class="altRows">
                        <thead>
                            <tr class="centerData" >
                                <th>Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData" *ngFor="let dept of authorityCopy.departments; let $index=index" >
                            <td (click)="editDepartment(dept, editHousingAuthorityForm)"><a>{{dept.name}}</a></td>
                            <td class="maxWidth">
                                <a (click)="editDepartment(dept, editHousingAuthorityForm)">
                                    <mat-icon>create</mat-icon>
                                </a>
                            </td>
                            <td class="maxWidth">
                                <a (click)="deleteDepartment($index, editHousingAuthorityForm)">
                                    <mat-icon>delete_outline</mat-icon>
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel> 
                <div class="section-header layout-row moveButton removeSectionHeader">
                    <a class="account__details-collapse topPadding">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : fundingSourcePanelOpenState}"
                            (click)="fundingSourcePanelOpenState = !fundingSourcePanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                        <div>Funding Sources</div>
                        <button mat-flat-button 
                            (click)="editFundingSource($index,'add', editHousingAuthorityForm)" 
                            class="hds_button" 
                            id="addNewFundingSourceButton"
                            aria-label="Add">
                            Add
                        </button>
                    </div>
                </div>
                <mat-expansion-panel [expanded]="fundingSourcePanelOpenState">
                    <table class="altRows">
                        <thead>
                            <tr class="centerData" >
                                <th>Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData" *ngFor="let source  of authorityCopy.fundingSources; let $index=index">
                            <td><a (click)="editFundingSource($index,source, editHousingAuthorityForm)">{{source}}</a></td>
                            <td class="maxWidth">
                                <a (click)="editFundingSource($index,source, editHousingAuthorityForm)">
                                    <mat-icon>create</mat-icon>
                                </a>
                            </td>
                            <td class="maxWidth">
                                <a (click)="deleteFundingSource($index, editHousingAuthorityForm)">
                                    <mat-icon>delete_outline</mat-icon>
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel>
                <div class="section-header layout-row moveButton removeSectionHeader">
                    <a class="account__details-collapse topPadding">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : incomeLimitPanelOpenState}"
                            (click)="incomeLimitPanelOpenState = !incomeLimitPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                        <div>Income Limit Areas</div>
                        <button mat-flat-button 
                            (click)="addNewIncomeLimitArea()" 
                            class="hds_button" 
                            id="addNewIncomeLimit"
                            aria-label="Add">
                            Add
                        </button>
                    </div>
                </div>
                <mat-expansion-panel [expanded]="incomeLimitPanelOpenState">
                    <table class="altRows">
                        <thead>
                            <tr class="centerData" >
                                <th>Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData" *ngFor="let area of authorityCopy.incomeLimitAreas; let $index=index">
                            <td><a (click)="editIncomeLimitArea(area, false, $index)">{{area.name}}</a></td>
                            <td class="maxWidth">
                                <a (click)="editIncomeLimitArea(area, false, $index)">
                                    <mat-icon>create</mat-icon>
                                </a>
                            </td>
                            <td class="maxWidth">
                                <a (click)="deleteIncomeLimitArea($index)">
                                    <mat-icon>delete_outline</mat-icon>
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel>
                <div class="section-header layout-row moveButton removeSectionHeader">
                    <a class="account__details-collapse topPadding">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : regionalRentLimitPanelOpenState}"
                            (click)="regionalRentLimitPanelOpenState = !regionalRentLimitPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                        <div>Regional Rent Limits</div>
                        <button mat-flat-button 
                            (click)="addNewRegionalRentLimit()" 
                            class="hds_button" 
                            id="addRegionalRentLimitButton"
                            aria-label="Add">
                            Add
                        </button>
                    </div>
                </div>
                <mat-expansion-panel [expanded]="regionalRentLimitPanelOpenState">
                    <table class="altRows">
                        <thead>
                            <tr class="centerData" >
                                <th>Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData" *ngFor="let rentlimit of authorityCopy.rentLimitAreas; let $index=index">
                            <td><a (click)="editRegionalRentLimit(rentlimit, false, index)">{{rentlimit.name}}</a></td>
                            <td class="maxWidth">
                                <a (click)="editRegionalRentLimit(rentlimit, false, index)">
                                    <mat-icon>create</mat-icon>
                                </a>
                            </td>
                            <td class="maxWidth">
                                <a (click)="deleteRegionalRentLimit($index)">
                                    <mat-icon>delete_outline</mat-icon>
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-expansion-panel>

            <div class="section-header layout-row moveButton removeSectionHeader">
                <a class="account__details-collapse topPadding">
                    <mat-icon class="collapse-icon" 
                        [ngClass]="{'collapse-icon--open' : paymentStandardsPanelOpenState}"
                        (click)="paymentStandardsPanelOpenState = !paymentStandardsPanelOpenState">chevron_right
                    </mat-icon>
                </a>
                <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                    <div>Payment Standards</div>
                    <button mat-flat-button 
                        (click)="addNewPaymentStandard(editHousingAuthorityForm)" 
                        class="hds_button" 
                        id="addAuthorityFundingSourceButton"
                        aria-label="Add">
                        Add
                    </button>
                </div>
            </div>
        
            <mat-expansion-panel [expanded]="paymentStandardsPanelOpenState">
                <div class ="rowPaddingLeft" *ngIf="!authorityCopy.paymentStandards.length>0">
                    No Payment Standards
                </div>
                <div *ngIf="authorityCopy.paymentStandards.length>0">
                    <table class="altRows">
                        <thead>
                            <tr class="centerData" >
                                <th>Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tr class="centerData" *ngFor="let paymentStandard of authorityCopy.paymentStandards; let $index=index" >
                            <td><a (click)="editPaymentStandard(paymentStandard, false, $index, editHousingAuthorityForm)">{{paymentStandard.name}}</a></td>
                            <td class="maxWidth">
                                <a (click)="editPaymentStandard(paymentStandard, false, $index, editHousingAuthorityForm)">
                                    <mat-icon>create</mat-icon>
                                </a>
                            </td>
                            <td class="maxWidth">
                                <a (click)="removePaymentStandard($index, editHousingAuthorityForm)">
                                    <mat-icon>delete_outline</mat-icon>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>

            <div class="section-header layout-row moveButton removeSectionHeader">
                <a class="account__details-collapse topPadding">
                    <mat-icon class="collapse-icon" 
                        [ngClass]="{'collapse-icon--open' : miscellaneousPanelOpenState}"
                        (click)="miscellaneousPanelOpenState = !miscellaneousPanelOpenState">chevron_right
                    </mat-icon>
                </a>
                <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                    <div>Miscellaneous</div>
                </div>
            </div>
            <mat-expansion-panel [expanded]="miscellaneousPanelOpenState">
                <div *ngIf="!landlordList || !chartOfAccounts" class="layout-row">
                    <mat-spinner diameter="25" > </mat-spinner>
                    <div class="rowPaddingLeft"> Loading Chart of Accounts...</div>
                </div>
                <div class="layout-row layout-align-start-center rowPaddingLeft" *ngIf="chartOfAccounts && landlordList">
                    <mat-form-field appearance="outline" class="largeInputBox">
                        <mat-label>Chart of Accounts</mat-label>
                        <mat-select 
                            name="authorityMiscCOA"
                            [(ngModel)]="selectedChartOfAccounts"
                            aria-label="Subledger Mapping"
                            (ngModelChange)="changeSelectedAccountDefinition()"
                            [compareWith]="compareCOA">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let coa of chartOfAccounts, let $index=index" [value]="coa">{{coa.name}}</mat-option>
                        </mat-select> 
                    </mat-form-field>
                    <mat-form-field  appearance="outline" class="largeInputBox">
                        <mat-label>Landlord</mat-label>
                         <mat-select 
                            name="landlordId"
                            ngModel
                            [(ngModel)]="authorityCopy.landlordId"
                            #authorityLandlord="ngModel"
                            name="authorityLandlord"
                            id="landlordId"
                            aria-label="Which landlord record represents this Housing Authority?"
                            >
                            <mat-option [value]="null">None</mat-option>
                            <mat-option class="hds-option" *ngFor="let ll of landlordList" [value]="ll.id">
                                <span *ngIf="ll.companyName">{{ll.companyName}}</span>
                                <span *ngIf="!ll.companyName">{{ll.lastName}}</span>
                                <span *ngIf="ll.firstName && !ll.companyName">, </span>
                                <span *ngIf="ll.firstName && !ll.companyName">{{ll.firstName}}</span>
                            </mat-option>
                        </mat-select> 
                    </mat-form-field>       
                </div> 
                <div *ngIf="achLoading" class="layout-row">
                    <mat-spinner diameter="25" > </mat-spinner>
                    <div class="rowPaddingLeft"> Loading ACH Information...</div>
                </div>
                <div *ngIf="!achLoading">
                    <div class="layout-row layout-align-start-center rowPaddingLeft">
                        <mat-form-field appearance="outline" class="largeInputBox">
                            <mat-label>ACH Bank Name</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="authorityCopy.odfiName" 
                                #odfiName="ngModel"
                                name="odfiName"
                                id="odfiNameInput" 
                                aria-label="ACH odfi Name"
                                >
                        </mat-form-field>
                        <mat-form-field  appearance="outline" class="largeInputBox">
                            <mat-label>ACH Routing Number</mat-label> 
                            <input matInput
                                ngModel
                                [(ngModel)]="authorityCopy.routingNumber"
                                #routingNumber="ngModel"
                                name="routingNumber"
                                id="routingNumberInput" 
                                aria-label="ACH Routing Number"
                                maxlength="9"
                                >
                        </mat-form-field>       
                    </div> 
                    <div class="layout-row layout-align-start-center rowPaddingLeft">
                        <mat-form-field appearance="outline" class="largeInputBox">
                            <mat-label>ACH Bank Token</mat-label>
                                <input 
                                    matInput
                                    ngModel
                                    [(ngModel)]="authorityCopy.accountNumber" 
                                    #accountNumber="ngModel"
                                    name="accountNumber"
                                    id="accountNumberInput" 
                                    aria-label="ACH Account Number"
                                    maxlength="10"
                                    [disabled]="!showAccountInfo || encryptedAccountLoading"
                                    >
                                    <div *ngIf="showAccountInfo && encryptedAccountLoading " class="layout-row">
                                        <mat-spinner diameter="25" > </mat-spinner>
                                        <div class="rowPaddingLeft"> Loading Account Number...</div>
                                    </div>
                                    <span>
                                        <span  *ngIf="hasPermission" class="hideGlyphRight glyphicon" [ngClass]="{'glyphicon-eye-close': !showAccountInfo, 'glyphicon-eye-open': showAccountInfo}" (click)="toggleAccountVisibility()"></span>
                                    </span>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-card-actions>
                    <button
                        mat-flat-button 
                        class="hds_button cancel" 
                        (click)="cancelGeneralInformationForm(editHousingAuthorityForm)" 
                        [disabled]="editHousingAuthorityForm.pristine"
                        id="cancelButton">
                        Cancel
                    </button>
                    <button 
                        mat-flat-button 
                        class="hds_button"
                        (click)="saveGeneralInformationForm(editHousingAuthorityForm)"
                        [disabled]="!editHousingAuthorityForm.valid || editHousingAuthorityForm.pristine"
                        id="saveButton">
                        Save
                    </button>
            </mat-card-actions>
        </form>
    </div>
</section>