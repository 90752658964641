export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  Uuid: any;
};

export type AccountDefinition = {
  __typename?: 'AccountDefinition';
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subLedgerMappingId?: Maybe<Scalars['String']>;
  isAutoPostDisabled: Scalars['Boolean'];
  principalChargeSubCategory?: Maybe<Scalars['String']>;
  interestChargeSubCategory?: Maybe<Scalars['String']>;
  principalReductionSubCategory?: Maybe<Scalars['String']>;
  adjustmentToEstablishPrincipalBalanceSubCategory?: Maybe<Scalars['String']>;
  lateFeeSubCategory?: Maybe<Scalars['String']>;
  assistancePaymentSubCategory?: Maybe<Scalars['String']>;
  equityAccountDefinition?: Maybe<Scalars['String']>;
  mepaAccountDefinition?: Maybe<Scalars['String']>;
  isSystemOnlyAccount: Scalars['Boolean'];
  isArAccount: Scalars['Boolean'];
  isPayableAccount: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  isPrincipalAccount: Scalars['Boolean'];
  isDepositAccount: Scalars['Boolean'];
  isBadDebtAccount: Scalars['Boolean'];
};

export type AccountDefinitionFilterInput = {
  and?: Maybe<Array<AccountDefinitionFilterInput>>;
  or?: Maybe<Array<AccountDefinitionFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  programId?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  subLedgerMappingId?: Maybe<StringOperationFilterInput>;
  isAutoPostDisabled?: Maybe<BooleanOperationFilterInput>;
  principalChargeSubCategory?: Maybe<StringOperationFilterInput>;
  interestChargeSubCategory?: Maybe<StringOperationFilterInput>;
  principalReductionSubCategory?: Maybe<StringOperationFilterInput>;
  adjustmentToEstablishPrincipalBalanceSubCategory?: Maybe<StringOperationFilterInput>;
  lateFeeSubCategory?: Maybe<StringOperationFilterInput>;
  assistancePaymentSubCategory?: Maybe<StringOperationFilterInput>;
  equityAccountDefinition?: Maybe<StringOperationFilterInput>;
  mepaAccountDefinition?: Maybe<StringOperationFilterInput>;
  isSystemOnlyAccount?: Maybe<BooleanOperationFilterInput>;
  isArAccount?: Maybe<BooleanOperationFilterInput>;
  isPayableAccount?: Maybe<BooleanOperationFilterInput>;
  isPrimary?: Maybe<BooleanOperationFilterInput>;
  isPrincipalAccount?: Maybe<BooleanOperationFilterInput>;
  isDepositAccount?: Maybe<BooleanOperationFilterInput>;
  isBadDebtAccount?: Maybe<BooleanOperationFilterInput>;
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export enum CommandStatus {
  Issued = 'ISSUED',
  Accepted = 'ACCEPTED',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export type ComparableByteOperationFilterInput = {
  eq?: Maybe<Scalars['Byte']>;
  neq?: Maybe<Scalars['Byte']>;
  in?: Maybe<Array<Scalars['Byte']>>;
  nin?: Maybe<Array<Scalars['Byte']>>;
  gt?: Maybe<Scalars['Byte']>;
  ngt?: Maybe<Scalars['Byte']>;
  gte?: Maybe<Scalars['Byte']>;
  ngte?: Maybe<Scalars['Byte']>;
  lt?: Maybe<Scalars['Byte']>;
  nlt?: Maybe<Scalars['Byte']>;
  lte?: Maybe<Scalars['Byte']>;
  nlte?: Maybe<Scalars['Byte']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  nin?: Maybe<Array<Scalars['Decimal']>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: Maybe<Scalars['Uuid']>;
  neq?: Maybe<Scalars['Uuid']>;
  in?: Maybe<Array<Scalars['Uuid']>>;
  nin?: Maybe<Array<Scalars['Uuid']>>;
  gt?: Maybe<Scalars['Uuid']>;
  ngt?: Maybe<Scalars['Uuid']>;
  gte?: Maybe<Scalars['Uuid']>;
  ngte?: Maybe<Scalars['Uuid']>;
  lt?: Maybe<Scalars['Uuid']>;
  nlt?: Maybe<Scalars['Uuid']>;
  lte?: Maybe<Scalars['Uuid']>;
  nlte?: Maybe<Scalars['Uuid']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  nin?: Maybe<Array<Scalars['Int']>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type ComparableSingleOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type CreateVoucherCommandInput = {
  fundingProgramId?: Maybe<Scalars['String']>;
  fundingProjectId?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  specialType?: Maybe<Scalars['String']>;
  authorizedBedrooms: Scalars['Byte'];
  voucherNumber?: Maybe<Scalars['String']>;
  issuedOn: Scalars['DateTime'];
  expiresOn?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  householdId?: Maybe<Scalars['String']>;
  voucherType: VoucherType;
  messageIdentifier?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  messageGroupId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  statusDocumentLocation?: Maybe<Scalars['String']>;
};

export type EditVoucherCommandInput = {
  voucherId: Scalars['Uuid'];
  fundingProgramId?: Maybe<Scalars['String']>;
  fundingProjectId?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  specialType?: Maybe<Scalars['String']>;
  authorizedBedrooms?: Maybe<Scalars['Byte']>;
  voucherNumber?: Maybe<Scalars['String']>;
  issuedOn?: Maybe<Scalars['DateTime']>;
  expiresOn?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  voucherType?: Maybe<VoucherType>;
  messageIdentifier?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  messageGroupId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  statusDocumentLocation?: Maybe<Scalars['String']>;
};

export type EntityCommandStateOfVoucher = {
  __typename?: 'EntityCommandStateOfVoucher';
  commandName?: Maybe<Scalars['String']>;
  status: CommandStatus;
  issuedOn: Scalars['DateTime'];
  acceptedOn?: Maybe<Scalars['DateTime']>;
  succeededOn?: Maybe<Scalars['DateTime']>;
  failedOn?: Maybe<Scalars['DateTime']>;
  failureReason?: Maybe<Scalars['String']>;
  affectedEntity?: Maybe<Voucher>;
};

export type IncomeLimitArea = {
  __typename?: 'IncomeLimitArea';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  housingAuthorityId?: Maybe<Scalars['String']>;
  incomeLimitTables?: Maybe<Array<Maybe<IncomeLimitTable>>>;
};

export type IncomeLimitAreaFilterInput = {
  and?: Maybe<Array<IncomeLimitAreaFilterInput>>;
  or?: Maybe<Array<IncomeLimitAreaFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  housingAuthorityId?: Maybe<StringOperationFilterInput>;
  incomeLimitTables?: Maybe<ListFilterInputTypeOfIncomeLimitTableFilterInput>;
};

export type IncomeLimitAreaSortInput = {
  id?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  housingAuthorityId?: Maybe<SortEnumType>;
};

export type IncomeLimitTable = {
  __typename?: 'IncomeLimitTable';
  id?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  medianIncome: Scalars['Int'];
  incomeLimitTableLevels?: Maybe<Array<Maybe<IncomeLimitTableLevel>>>;
};

export type IncomeLimitTableFilterInput = {
  and?: Maybe<Array<IncomeLimitTableFilterInput>>;
  or?: Maybe<Array<IncomeLimitTableFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  effectiveDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  medianIncome?: Maybe<ComparableInt32OperationFilterInput>;
  incomeLimitTableLevels?: Maybe<ListFilterInputTypeOfIncomeLimitTableLevelFilterInput>;
};

export type IncomeLimitTableLevel = {
  __typename?: 'IncomeLimitTableLevel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentageOfMedianIncome: Scalars['Int'];
  incomeLimitTableValues?: Maybe<Array<Maybe<IncomeLimitTableValue>>>;
};

export type IncomeLimitTableLevelFilterInput = {
  and?: Maybe<Array<IncomeLimitTableLevelFilterInput>>;
  or?: Maybe<Array<IncomeLimitTableLevelFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  percentageOfMedianIncome?: Maybe<ComparableInt32OperationFilterInput>;
  incomeLimitTableValues?: Maybe<ListFilterInputTypeOfIncomeLimitTableValueFilterInput>;
};

export type IncomeLimitTableValue = {
  __typename?: 'IncomeLimitTableValue';
  id?: Maybe<Scalars['String']>;
  householdSize: Scalars['Int'];
  amount: Scalars['Decimal'];
};

export type IncomeLimitTableValueFilterInput = {
  and?: Maybe<Array<IncomeLimitTableValueFilterInput>>;
  or?: Maybe<Array<IncomeLimitTableValueFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  householdSize?: Maybe<ComparableInt32OperationFilterInput>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
};

export type Landlord = {
  __typename?: 'Landlord';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  paymentPreference?: Maybe<Scalars['String']>;
  address?: Maybe<LandlordAddress>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<LandlordNote>>>;
  units?: Maybe<Array<Maybe<Unit>>>;
};

export type LandlordAddress = {
  __typename?: 'LandlordAddress';
  id?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  landlordId?: Maybe<Scalars['String']>;
};

export type LandlordAddressFilterInput = {
  and?: Maybe<Array<LandlordAddressFilterInput>>;
  or?: Maybe<Array<LandlordAddressFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  streetAddress?: Maybe<StringOperationFilterInput>;
  unit?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StringOperationFilterInput>;
  postalCode?: Maybe<StringOperationFilterInput>;
  landlordId?: Maybe<StringOperationFilterInput>;
};

export type LandlordAddressSortInput = {
  id?: Maybe<SortEnumType>;
  streetAddress?: Maybe<SortEnumType>;
  unit?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  postalCode?: Maybe<SortEnumType>;
  landlordId?: Maybe<SortEnumType>;
};

export type LandlordFilterInput = {
  and?: Maybe<Array<LandlordFilterInput>>;
  or?: Maybe<Array<LandlordFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  companyName?: Maybe<StringOperationFilterInput>;
  phoneNumber?: Maybe<StringOperationFilterInput>;
  emailAddress?: Maybe<StringOperationFilterInput>;
  taxId?: Maybe<StringOperationFilterInput>;
  vendorId?: Maybe<StringOperationFilterInput>;
  paymentPreference?: Maybe<StringOperationFilterInput>;
  address?: Maybe<LandlordAddressFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  modifiedBy?: Maybe<StringOperationFilterInput>;
  deletedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<ListFilterInputTypeOfLandlordNoteFilterInput>;
  units?: Maybe<ListFilterInputTypeOfUnitFilterInput>;
};

export type LandlordNote = {
  __typename?: 'LandlordNote';
  id?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  landlordId?: Maybe<Scalars['String']>;
  landlord?: Maybe<Landlord>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
};

export type LandlordNoteFilterInput = {
  and?: Maybe<Array<LandlordNoteFilterInput>>;
  or?: Maybe<Array<LandlordNoteFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  body?: Maybe<StringOperationFilterInput>;
  landlordId?: Maybe<StringOperationFilterInput>;
  landlord?: Maybe<LandlordFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  deletedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
};

export type LandlordSortInput = {
  id?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  companyName?: Maybe<SortEnumType>;
  phoneNumber?: Maybe<SortEnumType>;
  emailAddress?: Maybe<SortEnumType>;
  taxId?: Maybe<SortEnumType>;
  vendorId?: Maybe<SortEnumType>;
  paymentPreference?: Maybe<SortEnumType>;
  address?: Maybe<LandlordAddressSortInput>;
  customerId?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  modifiedBy?: Maybe<SortEnumType>;
  deletedOn?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
};

export type ListFilterInputTypeOfAccountDefinitionFilterInput = {
  all?: Maybe<AccountDefinitionFilterInput>;
  none?: Maybe<AccountDefinitionFilterInput>;
  some?: Maybe<AccountDefinitionFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfIncomeLimitTableFilterInput = {
  all?: Maybe<IncomeLimitTableFilterInput>;
  none?: Maybe<IncomeLimitTableFilterInput>;
  some?: Maybe<IncomeLimitTableFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfIncomeLimitTableLevelFilterInput = {
  all?: Maybe<IncomeLimitTableLevelFilterInput>;
  none?: Maybe<IncomeLimitTableLevelFilterInput>;
  some?: Maybe<IncomeLimitTableLevelFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfIncomeLimitTableValueFilterInput = {
  all?: Maybe<IncomeLimitTableValueFilterInput>;
  none?: Maybe<IncomeLimitTableValueFilterInput>;
  some?: Maybe<IncomeLimitTableValueFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfLandlordNoteFilterInput = {
  all?: Maybe<LandlordNoteFilterInput>;
  none?: Maybe<LandlordNoteFilterInput>;
  some?: Maybe<LandlordNoteFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfProjectFilterInput = {
  all?: Maybe<ProjectFilterInput>;
  none?: Maybe<ProjectFilterInput>;
  some?: Maybe<ProjectFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfRentLimitFilterInput = {
  all?: Maybe<RentLimitFilterInput>;
  none?: Maybe<RentLimitFilterInput>;
  some?: Maybe<RentLimitFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfRentLimitTableFilterInput = {
  all?: Maybe<RentLimitTableFilterInput>;
  none?: Maybe<RentLimitTableFilterInput>;
  some?: Maybe<RentLimitTableFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfRentLimitValueFilterInput = {
  all?: Maybe<RentLimitValueFilterInput>;
  none?: Maybe<RentLimitValueFilterInput>;
  some?: Maybe<RentLimitValueFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfRequestForTenancyApprovalFilterInput = {
  all?: Maybe<RequestForTenancyApprovalFilterInput>;
  none?: Maybe<RequestForTenancyApprovalFilterInput>;
  some?: Maybe<RequestForTenancyApprovalFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfUnitFilterInput = {
  all?: Maybe<UnitFilterInput>;
  none?: Maybe<UnitFilterInput>;
  some?: Maybe<UnitFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfUnitStatusChangedEventFilterInput = {
  all?: Maybe<UnitStatusChangedEventFilterInput>;
  none?: Maybe<UnitStatusChangedEventFilterInput>;
  some?: Maybe<UnitStatusChangedEventFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfVoucherStateChangeEventFilterInput = {
  all?: Maybe<VoucherStateChangeEventFilterInput>;
  none?: Maybe<VoucherStateChangeEventFilterInput>;
  some?: Maybe<VoucherStateChangeEventFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createVoucher?: Maybe<EntityCommandStateOfVoucher>;
  editVoucher?: Maybe<EntityCommandStateOfVoucher>;
};

export type MutationCreateVoucherArgs = {
  command?: Maybe<CreateVoucherCommandInput>;
};

export type MutationEditVoucherArgs = {
  command?: Maybe<EditVoucherCommandInput>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  programCode?: Maybe<Scalars['String']>;
  isHousingProgram: Scalars['Boolean'];
  chartOfAccountsId?: Maybe<Scalars['String']>;
  housingAuthorityId?: Maybe<Scalars['String']>;
  isRecertificationRequired: Scalars['Boolean'];
  recertificationPeriod: Scalars['Byte'];
  recertificationPeriodUnit: RecertificationPeriodUnit;
  recertPeriodUnit?: Maybe<Scalars['String']>;
  programOverrides?: Maybe<ProgramOverrides>;
  projects?: Maybe<Array<Maybe<Project>>>;
  accountDefinitions?: Maybe<Array<Maybe<AccountDefinition>>>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  aelValue?: Maybe<Scalars['Decimal']>;
};

export type ProgramFilterInput = {
  and?: Maybe<Array<ProgramFilterInput>>;
  or?: Maybe<Array<ProgramFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  programType?: Maybe<StringOperationFilterInput>;
  programCode?: Maybe<StringOperationFilterInput>;
  isHousingProgram?: Maybe<BooleanOperationFilterInput>;
  chartOfAccountsId?: Maybe<StringOperationFilterInput>;
  housingAuthorityId?: Maybe<StringOperationFilterInput>;
  isRecertificationRequired?: Maybe<BooleanOperationFilterInput>;
  recertificationPeriod?: Maybe<ComparableByteOperationFilterInput>;
  recertificationPeriodUnit?: Maybe<RecertificationPeriodUnitOperationFilterInput>;
  recertPeriodUnit?: Maybe<StringOperationFilterInput>;
  programOverrides?: Maybe<ProgramOverridesFilterInput>;
  projects?: Maybe<ListFilterInputTypeOfProjectFilterInput>;
  accountDefinitions?: Maybe<ListFilterInputTypeOfAccountDefinitionFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  aelValue?: Maybe<ComparableNullableOfDecimalOperationFilterInput>;
};

export type ProgramOverrides = {
  __typename?: 'ProgramOverrides';
  id: Scalars['Uuid'];
  programId?: Maybe<Scalars['String']>;
  incomeLimitAreaId?: Maybe<Scalars['String']>;
  taxCreditIncomeLimitAreaId?: Maybe<Scalars['String']>;
  rentLimitAreaId?: Maybe<Scalars['String']>;
  incomeLimitArea?: Maybe<IncomeLimitArea>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitArea>;
  rentLimitArea?: Maybe<RentLimitArea>;
};

export type ProgramOverridesFilterInput = {
  and?: Maybe<Array<ProgramOverridesFilterInput>>;
  or?: Maybe<Array<ProgramOverridesFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  programId?: Maybe<StringOperationFilterInput>;
  incomeLimitAreaId?: Maybe<StringOperationFilterInput>;
  taxCreditIncomeLimitAreaId?: Maybe<StringOperationFilterInput>;
  rentLimitAreaId?: Maybe<StringOperationFilterInput>;
  incomeLimitArea?: Maybe<IncomeLimitAreaFilterInput>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitAreaFilterInput>;
  rentLimitArea?: Maybe<RentLimitAreaFilterInput>;
};

export type ProgramOverridesSortInput = {
  id?: Maybe<SortEnumType>;
  programId?: Maybe<SortEnumType>;
  incomeLimitAreaId?: Maybe<SortEnumType>;
  taxCreditIncomeLimitAreaId?: Maybe<SortEnumType>;
  rentLimitAreaId?: Maybe<SortEnumType>;
  incomeLimitArea?: Maybe<IncomeLimitAreaSortInput>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitAreaSortInput>;
  rentLimitArea?: Maybe<RentLimitAreaSortInput>;
};

export type ProgramSortInput = {
  id?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  programType?: Maybe<SortEnumType>;
  programCode?: Maybe<SortEnumType>;
  isHousingProgram?: Maybe<SortEnumType>;
  chartOfAccountsId?: Maybe<SortEnumType>;
  housingAuthorityId?: Maybe<SortEnumType>;
  isRecertificationRequired?: Maybe<SortEnumType>;
  recertificationPeriod?: Maybe<SortEnumType>;
  recertificationPeriodUnit?: Maybe<SortEnumType>;
  recertPeriodUnit?: Maybe<SortEnumType>;
  programOverrides?: Maybe<ProgramOverridesSortInput>;
  createdOn?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  aelValue?: Maybe<SortEnumType>;
};

export type Project = {
  __typename?: 'Project';
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectNumber?: Maybe<Scalars['String']>;
  projectCode?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  program?: Maybe<Program>;
  dateOfFullAvailability?: Maybe<Scalars['DateTime']>;
  projectSegmentCode?: Maybe<Scalars['String']>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  projectOverrides?: Maybe<ProjectOverrides>;
};

export type ProjectFilterInput = {
  and?: Maybe<Array<ProjectFilterInput>>;
  or?: Maybe<Array<ProjectFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  projectNumber?: Maybe<StringOperationFilterInput>;
  projectCode?: Maybe<StringOperationFilterInput>;
  programId?: Maybe<StringOperationFilterInput>;
  program?: Maybe<ProgramFilterInput>;
  dateOfFullAvailability?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  projectSegmentCode?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  projectOverrides?: Maybe<ProjectOverridesFilterInput>;
};

export type ProjectOverrides = {
  __typename?: 'ProjectOverrides';
  id: Scalars['Uuid'];
  projectId?: Maybe<Scalars['String']>;
  incomeLimitAreaId?: Maybe<Scalars['String']>;
  taxCreditIncomeLimitAreaId?: Maybe<Scalars['String']>;
  rentLimitAreaId?: Maybe<Scalars['String']>;
  incomeLimitArea?: Maybe<IncomeLimitArea>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitArea>;
  rentLimitArea?: Maybe<RentLimitArea>;
};

export type ProjectOverridesFilterInput = {
  and?: Maybe<Array<ProjectOverridesFilterInput>>;
  or?: Maybe<Array<ProjectOverridesFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  projectId?: Maybe<StringOperationFilterInput>;
  incomeLimitAreaId?: Maybe<StringOperationFilterInput>;
  taxCreditIncomeLimitAreaId?: Maybe<StringOperationFilterInput>;
  rentLimitAreaId?: Maybe<StringOperationFilterInput>;
  incomeLimitArea?: Maybe<IncomeLimitAreaFilterInput>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitAreaFilterInput>;
  rentLimitArea?: Maybe<RentLimitAreaFilterInput>;
};

export type ProjectOverridesSortInput = {
  id?: Maybe<SortEnumType>;
  projectId?: Maybe<SortEnumType>;
  incomeLimitAreaId?: Maybe<SortEnumType>;
  taxCreditIncomeLimitAreaId?: Maybe<SortEnumType>;
  rentLimitAreaId?: Maybe<SortEnumType>;
  incomeLimitArea?: Maybe<IncomeLimitAreaSortInput>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitAreaSortInput>;
  rentLimitArea?: Maybe<RentLimitAreaSortInput>;
};

export type ProjectSortInput = {
  id?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  projectNumber?: Maybe<SortEnumType>;
  projectCode?: Maybe<SortEnumType>;
  programId?: Maybe<SortEnumType>;
  program?: Maybe<ProgramSortInput>;
  dateOfFullAvailability?: Maybe<SortEnumType>;
  projectSegmentCode?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  projectOverrides?: Maybe<ProjectOverridesSortInput>;
};

export type Query = {
  __typename?: 'Query';
  voucherById?: Maybe<Voucher>;
  vouchers?: Maybe<VoucherConnection>;
  requestForTenancyApprovalById?: Maybe<RequestForTenancyApproval>;
  requestsForTenancyApproval?: Maybe<RequestForTenancyApprovalConnection>;
  stateChangeById?: Maybe<VoucherStateChangeEvent>;
  voucherStateChangeEvents?: Maybe<VoucherStateChangeEventConnection>;
};

export type QueryVoucherByIdArgs = {
  id: Scalars['Uuid'];
};

export type QueryVouchersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<VoucherFilterInput>;
  order?: Maybe<Array<VoucherSortInput>>;
};

export type QueryRequestForTenancyApprovalByIdArgs = {
  id: Scalars['Uuid'];
};

export type QueryRequestsForTenancyApprovalArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<RequestForTenancyApprovalFilterInput>;
  order?: Maybe<Array<RequestForTenancyApprovalSortInput>>;
};

export type QueryStateChangeByIdArgs = {
  id: Scalars['Uuid'];
};

export type QueryVoucherStateChangeEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<VoucherStateChangeEventFilterInput>;
  order?: Maybe<Array<VoucherStateChangeEventSortInput>>;
};

export enum RecertificationPeriodUnit {
  Days = 'DAYS',
  Months = 'MONTHS',
  Years = 'YEARS',
}

export type RecertificationPeriodUnitOperationFilterInput = {
  eq?: Maybe<RecertificationPeriodUnit>;
  neq?: Maybe<RecertificationPeriodUnit>;
  in?: Maybe<Array<RecertificationPeriodUnit>>;
  nin?: Maybe<Array<RecertificationPeriodUnit>>;
};

export type RentLimit = {
  __typename?: 'RentLimit';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentageOfRent: Scalars['Int'];
  rentLimitValues?: Maybe<Array<Maybe<RentLimitValue>>>;
};

export type RentLimitArea = {
  __typename?: 'RentLimitArea';
  id?: Maybe<Scalars['String']>;
  housingAuthorityId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rentLimitTables?: Maybe<Array<Maybe<RentLimitTable>>>;
};

export type RentLimitAreaFilterInput = {
  and?: Maybe<Array<RentLimitAreaFilterInput>>;
  or?: Maybe<Array<RentLimitAreaFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  housingAuthorityId?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  rentLimitTables?: Maybe<ListFilterInputTypeOfRentLimitTableFilterInput>;
};

export type RentLimitAreaSortInput = {
  id?: Maybe<SortEnumType>;
  housingAuthorityId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
};

export type RentLimitFilterInput = {
  and?: Maybe<Array<RentLimitFilterInput>>;
  or?: Maybe<Array<RentLimitFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  percentageOfRent?: Maybe<ComparableInt32OperationFilterInput>;
  rentLimitValues?: Maybe<ListFilterInputTypeOfRentLimitValueFilterInput>;
};

export type RentLimitTable = {
  __typename?: 'RentLimitTable';
  id?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rentLimits?: Maybe<Array<Maybe<RentLimit>>>;
};

export type RentLimitTableFilterInput = {
  and?: Maybe<Array<RentLimitTableFilterInput>>;
  or?: Maybe<Array<RentLimitTableFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  effectiveDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  rentLimits?: Maybe<ListFilterInputTypeOfRentLimitFilterInput>;
};

export type RentLimitValue = {
  __typename?: 'RentLimitValue';
  id?: Maybe<Scalars['String']>;
  bedroomSize: Scalars['Int'];
  amount: Scalars['Decimal'];
};

export type RentLimitValueFilterInput = {
  and?: Maybe<Array<RentLimitValueFilterInput>>;
  or?: Maybe<Array<RentLimitValueFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  bedroomSize?: Maybe<ComparableInt32OperationFilterInput>;
  amount?: Maybe<ComparableDecimalOperationFilterInput>;
};

export type RequestForTenancyApproval = {
  __typename?: 'RequestForTenancyApproval';
  id: Scalars['Uuid'];
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  voucherId: Scalars['Uuid'];
  voucher?: Maybe<Voucher>;
  unitId?: Maybe<Scalars['String']>;
  landlordId?: Maybe<Scalars['String']>;
  requestStartOn: Scalars['DateTime'];
  requestStopOn?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  resultComments?: Maybe<Scalars['String']>;
  finalizedOn?: Maybe<Scalars['DateTime']>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  archivedOn?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type RequestForTenancyApprovalConnection = {
  __typename?: 'RequestForTenancyApprovalConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<RequestForTenancyApprovalEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RequestForTenancyApproval>>>;
};

/** An edge in a connection. */
export type RequestForTenancyApprovalEdge = {
  __typename?: 'RequestForTenancyApprovalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RequestForTenancyApproval>;
};

export type RequestForTenancyApprovalFilterInput = {
  and?: Maybe<Array<RequestForTenancyApprovalFilterInput>>;
  or?: Maybe<Array<RequestForTenancyApprovalFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  voucherId?: Maybe<ComparableGuidOperationFilterInput>;
  voucher?: Maybe<VoucherFilterInput>;
  unitId?: Maybe<StringOperationFilterInput>;
  landlordId?: Maybe<StringOperationFilterInput>;
  requestStartOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  requestStopOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  result?: Maybe<StringOperationFilterInput>;
  resultComments?: Maybe<StringOperationFilterInput>;
  finalizedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  modifiedBy?: Maybe<StringOperationFilterInput>;
  archivedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  archivedBy?: Maybe<StringOperationFilterInput>;
};

export type RequestForTenancyApprovalSortInput = {
  id?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  voucherId?: Maybe<SortEnumType>;
  voucher?: Maybe<VoucherSortInput>;
  unitId?: Maybe<SortEnumType>;
  landlordId?: Maybe<SortEnumType>;
  requestStartOn?: Maybe<SortEnumType>;
  requestStopOn?: Maybe<SortEnumType>;
  result?: Maybe<SortEnumType>;
  resultComments?: Maybe<SortEnumType>;
  finalizedOn?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  modifiedBy?: Maybe<SortEnumType>;
  archivedOn?: Maybe<SortEnumType>;
  archivedBy?: Maybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum State {
  Issued = 'ISSUED',
  Leased = 'LEASED',
  ShopMode = 'SHOP_MODE',
  TerminatedWithOutLeasing = 'TERMINATED_WITH_OUT_LEASING',
  Terminated = 'TERMINATED',
}

export type StateOperationFilterInput = {
  eq?: Maybe<State>;
  neq?: Maybe<State>;
  in?: Maybe<Array<State>>;
  nin?: Maybe<Array<State>>;
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type Unit = {
  __typename?: 'Unit';
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  ppuCode?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  entranceNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['Int']>;
  postalCodePlus4?: Maybe<Scalars['Int']>;
  county?: Maybe<Scalars['String']>;
  numberOfBedrooms: Scalars['Byte'];
  numberOfBathrooms: Scalars['Float'];
  isHandicapAccessible: Scalars['Boolean'];
  squareFootage?: Maybe<Scalars['Int']>;
  yearConstructed?: Maybe<Scalars['Int']>;
  is1937Unit: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  photoUrl?: Maybe<Scalars['String']>;
  convertedFromProgramId?: Maybe<Scalars['String']>;
  convertedFromProjectId?: Maybe<Scalars['String']>;
  isNonInventoryUnit: Scalars['Boolean'];
  landlordId?: Maybe<Scalars['String']>;
  landlord?: Maybe<Landlord>;
  unitStatusChangedEvents?: Maybe<Array<Maybe<UnitStatusChangedEvent>>>;
  dceThresholdExceededOn?: Maybe<Scalars['DateTime']>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  unitOverrides?: Maybe<UnitOverrides>;
};

export type UnitFilterInput = {
  and?: Maybe<Array<UnitFilterInput>>;
  or?: Maybe<Array<UnitFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  programId?: Maybe<StringOperationFilterInput>;
  projectId?: Maybe<StringOperationFilterInput>;
  ppuCode?: Maybe<StringOperationFilterInput>;
  unitNumber?: Maybe<StringOperationFilterInput>;
  bin?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  streetAddress?: Maybe<StringOperationFilterInput>;
  apartmentNumber?: Maybe<StringOperationFilterInput>;
  entranceNumber?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  state?: Maybe<StringOperationFilterInput>;
  postalCode?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  postalCodePlus4?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  county?: Maybe<StringOperationFilterInput>;
  numberOfBedrooms?: Maybe<ComparableByteOperationFilterInput>;
  numberOfBathrooms?: Maybe<ComparableSingleOperationFilterInput>;
  isHandicapAccessible?: Maybe<BooleanOperationFilterInput>;
  squareFootage?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  yearConstructed?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  is1937Unit?: Maybe<BooleanOperationFilterInput>;
  latitude?: Maybe<ComparableNullableOfDoubleOperationFilterInput>;
  longitude?: Maybe<ComparableNullableOfDoubleOperationFilterInput>;
  photoUrl?: Maybe<StringOperationFilterInput>;
  convertedFromProgramId?: Maybe<StringOperationFilterInput>;
  convertedFromProjectId?: Maybe<StringOperationFilterInput>;
  isNonInventoryUnit?: Maybe<BooleanOperationFilterInput>;
  landlordId?: Maybe<StringOperationFilterInput>;
  landlord?: Maybe<LandlordFilterInput>;
  unitStatusChangedEvents?: Maybe<ListFilterInputTypeOfUnitStatusChangedEventFilterInput>;
  dceThresholdExceededOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  modifiedBy?: Maybe<StringOperationFilterInput>;
  unitOverrides?: Maybe<UnitOverridesFilterInput>;
};

export type UnitOverrides = {
  __typename?: 'UnitOverrides';
  id: Scalars['Uuid'];
  unitId?: Maybe<Scalars['String']>;
  incomeLimitAreaId?: Maybe<Scalars['String']>;
  taxCreditIncomeLimitAreaId?: Maybe<Scalars['String']>;
  rentLimitAreaId?: Maybe<Scalars['String']>;
  incomeLimitArea?: Maybe<IncomeLimitArea>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitArea>;
  rentLimitArea?: Maybe<RentLimitArea>;
};

export type UnitOverridesFilterInput = {
  and?: Maybe<Array<UnitOverridesFilterInput>>;
  or?: Maybe<Array<UnitOverridesFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  unitId?: Maybe<StringOperationFilterInput>;
  incomeLimitAreaId?: Maybe<StringOperationFilterInput>;
  taxCreditIncomeLimitAreaId?: Maybe<StringOperationFilterInput>;
  rentLimitAreaId?: Maybe<StringOperationFilterInput>;
  incomeLimitArea?: Maybe<IncomeLimitAreaFilterInput>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitAreaFilterInput>;
  rentLimitArea?: Maybe<RentLimitAreaFilterInput>;
};

export type UnitOverridesSortInput = {
  id?: Maybe<SortEnumType>;
  unitId?: Maybe<SortEnumType>;
  incomeLimitAreaId?: Maybe<SortEnumType>;
  taxCreditIncomeLimitAreaId?: Maybe<SortEnumType>;
  rentLimitAreaId?: Maybe<SortEnumType>;
  incomeLimitArea?: Maybe<IncomeLimitAreaSortInput>;
  taxCreditIncomeLimitArea?: Maybe<IncomeLimitAreaSortInput>;
  rentLimitArea?: Maybe<RentLimitAreaSortInput>;
};

export type UnitSortInput = {
  id?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  programId?: Maybe<SortEnumType>;
  projectId?: Maybe<SortEnumType>;
  ppuCode?: Maybe<SortEnumType>;
  unitNumber?: Maybe<SortEnumType>;
  bin?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  streetAddress?: Maybe<SortEnumType>;
  apartmentNumber?: Maybe<SortEnumType>;
  entranceNumber?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  postalCode?: Maybe<SortEnumType>;
  postalCodePlus4?: Maybe<SortEnumType>;
  county?: Maybe<SortEnumType>;
  numberOfBedrooms?: Maybe<SortEnumType>;
  numberOfBathrooms?: Maybe<SortEnumType>;
  isHandicapAccessible?: Maybe<SortEnumType>;
  squareFootage?: Maybe<SortEnumType>;
  yearConstructed?: Maybe<SortEnumType>;
  is1937Unit?: Maybe<SortEnumType>;
  latitude?: Maybe<SortEnumType>;
  longitude?: Maybe<SortEnumType>;
  photoUrl?: Maybe<SortEnumType>;
  convertedFromProgramId?: Maybe<SortEnumType>;
  convertedFromProjectId?: Maybe<SortEnumType>;
  isNonInventoryUnit?: Maybe<SortEnumType>;
  landlordId?: Maybe<SortEnumType>;
  landlord?: Maybe<LandlordSortInput>;
  dceThresholdExceededOn?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  modifiedBy?: Maybe<SortEnumType>;
  unitOverrides?: Maybe<UnitOverridesSortInput>;
};

export type UnitStatusChangedEvent = {
  __typename?: 'UnitStatusChangedEvent';
  id?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  householdId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type UnitStatusChangedEventFilterInput = {
  and?: Maybe<Array<UnitStatusChangedEventFilterInput>>;
  or?: Maybe<Array<UnitStatusChangedEventFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  unitId?: Maybe<StringOperationFilterInput>;
  householdId?: Maybe<StringOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  status?: Maybe<StringOperationFilterInput>;
  comment?: Maybe<StringOperationFilterInput>;
  effectiveDate?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  modifiedBy?: Maybe<StringOperationFilterInput>;
};

export type Voucher = {
  __typename?: 'Voucher';
  canTransitionTo: Scalars['Boolean'];
  id: Scalars['Uuid'];
  customerId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  householdId?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  unit?: Maybe<Unit>;
  programId?: Maybe<Scalars['String']>;
  program?: Maybe<Program>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  voucherType: VoucherType;
  specialType?: Maybe<Scalars['String']>;
  voucherNumber?: Maybe<Scalars['String']>;
  authorizedBedrooms: Scalars['Byte'];
  state: State;
  stateDisplay?: Maybe<Scalars['String']>;
  issuedOn: Scalars['DateTime'];
  leasedOn?: Maybe<Scalars['DateTime']>;
  expiresOn?: Maybe<Scalars['DateTime']>;
  tenancyRequests?: Maybe<Array<Maybe<RequestForTenancyApproval>>>;
  voucherStateChangeEvents?: Maybe<Array<Maybe<VoucherStateChangeEvent>>>;
  createdOn: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  deletedOn?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  canMoveIn: Scalars['Boolean'];
  canMoveOut: Scalars['Boolean'];
  canTerminate: Scalars['Boolean'];
  canTerminateWithoutLease: Scalars['Boolean'];
  canChangeDateIssued: Scalars['Boolean'];
  canChangeUnit: Scalars['Boolean'];
  enhancedVoucher?: Maybe<Scalars['Boolean']>;
  protectionVoucher?: Maybe<Scalars['Boolean']>;
  enhancedMinimumRent?: Maybe<Scalars['Int']>;
};

export type VoucherCanTransitionToArgs = {
  state: State;
};

/** A connection to a list of items. */
export type VoucherConnection = {
  __typename?: 'VoucherConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<VoucherEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Voucher>>>;
};

/** An edge in a connection. */
export type VoucherEdge = {
  __typename?: 'VoucherEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Voucher>;
};

export type VoucherFilterInput = {
  and?: Maybe<Array<VoucherFilterInput>>;
  or?: Maybe<Array<VoucherFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  customerId?: Maybe<StringOperationFilterInput>;
  siteId?: Maybe<StringOperationFilterInput>;
  householdId?: Maybe<StringOperationFilterInput>;
  unitId?: Maybe<StringOperationFilterInput>;
  unit?: Maybe<UnitFilterInput>;
  programId?: Maybe<StringOperationFilterInput>;
  program?: Maybe<ProgramFilterInput>;
  projectId?: Maybe<StringOperationFilterInput>;
  project?: Maybe<ProjectFilterInput>;
  voucherType?: Maybe<VoucherTypeOperationFilterInput>;
  specialType?: Maybe<StringOperationFilterInput>;
  voucherNumber?: Maybe<StringOperationFilterInput>;
  authorizedBedrooms?: Maybe<ComparableByteOperationFilterInput>;
  state?: Maybe<StateOperationFilterInput>;
  stateDisplay?: Maybe<StringOperationFilterInput>;
  issuedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  leasedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  expiresOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  tenancyRequests?: Maybe<ListFilterInputTypeOfRequestForTenancyApprovalFilterInput>;
  voucherStateChangeEvents?: Maybe<ListFilterInputTypeOfVoucherStateChangeEventFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  modifiedBy?: Maybe<StringOperationFilterInput>;
  deletedOn?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deletedBy?: Maybe<StringOperationFilterInput>;
  canMoveIn?: Maybe<BooleanOperationFilterInput>;
  canMoveOut?: Maybe<BooleanOperationFilterInput>;
  canTerminate?: Maybe<BooleanOperationFilterInput>;
  canTerminateWithoutLease?: Maybe<BooleanOperationFilterInput>;
  canChangeDateIssued?: Maybe<BooleanOperationFilterInput>;
  canChangeUnit?: Maybe<BooleanOperationFilterInput>;
};

export type VoucherSortInput = {
  id?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  siteId?: Maybe<SortEnumType>;
  householdId?: Maybe<SortEnumType>;
  unitId?: Maybe<SortEnumType>;
  unit?: Maybe<UnitSortInput>;
  programId?: Maybe<SortEnumType>;
  program?: Maybe<ProgramSortInput>;
  projectId?: Maybe<SortEnumType>;
  project?: Maybe<ProjectSortInput>;
  voucherType?: Maybe<SortEnumType>;
  specialType?: Maybe<SortEnumType>;
  voucherNumber?: Maybe<SortEnumType>;
  authorizedBedrooms?: Maybe<SortEnumType>;
  state?: Maybe<SortEnumType>;
  stateDisplay?: Maybe<SortEnumType>;
  issuedOn?: Maybe<SortEnumType>;
  leasedOn?: Maybe<SortEnumType>;
  expiresOn?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  modifiedBy?: Maybe<SortEnumType>;
  deletedOn?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  canMoveIn?: Maybe<SortEnumType>;
  canMoveOut?: Maybe<SortEnumType>;
  canTerminate?: Maybe<SortEnumType>;
  canTerminateWithoutLease?: Maybe<SortEnumType>;
  canChangeDateIssued?: Maybe<SortEnumType>;
  canChangeUnit?: Maybe<SortEnumType>;
};

export type VoucherStateChangeEvent = {
  __typename?: 'VoucherStateChangeEvent';
  id: Scalars['Uuid'];
  voucherId: Scalars['Uuid'];
  voucher?: Maybe<Voucher>;
  changedOn: Scalars['DateTime'];
  changedBy?: Maybe<Scalars['String']>;
  stateValue: State;
  stateDisplay?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type VoucherStateChangeEventConnection = {
  __typename?: 'VoucherStateChangeEventConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<VoucherStateChangeEventEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<VoucherStateChangeEvent>>>;
};

/** An edge in a connection. */
export type VoucherStateChangeEventEdge = {
  __typename?: 'VoucherStateChangeEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<VoucherStateChangeEvent>;
};

export type VoucherStateChangeEventFilterInput = {
  and?: Maybe<Array<VoucherStateChangeEventFilterInput>>;
  or?: Maybe<Array<VoucherStateChangeEventFilterInput>>;
  id?: Maybe<ComparableGuidOperationFilterInput>;
  voucherId?: Maybe<ComparableGuidOperationFilterInput>;
  voucher?: Maybe<VoucherFilterInput>;
  changedOn?: Maybe<ComparableDateTimeOperationFilterInput>;
  changedBy?: Maybe<StringOperationFilterInput>;
  stateValue?: Maybe<StateOperationFilterInput>;
  stateDisplay?: Maybe<StringOperationFilterInput>;
};

export type VoucherStateChangeEventSortInput = {
  id?: Maybe<SortEnumType>;
  voucherId?: Maybe<SortEnumType>;
  voucher?: Maybe<VoucherSortInput>;
  changedOn?: Maybe<SortEnumType>;
  changedBy?: Maybe<SortEnumType>;
  stateValue?: Maybe<SortEnumType>;
  stateDisplay?: Maybe<SortEnumType>;
};

export enum VoucherType {
  TenantBased = 'TENANT_BASED',
  ProjectBased = 'PROJECT_BASED',
  HomeOwnership = 'HOME_OWNERSHIP',
}

export type VoucherTypeOperationFilterInput = {
  eq?: Maybe<VoucherType>;
  neq?: Maybe<VoucherType>;
  in?: Maybe<Array<VoucherType>>;
  nin?: Maybe<Array<VoucherType>>;
};
