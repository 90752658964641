<mat-card class="knso-card account-setup-container">
    <mat-card-header>
        <div>
            <mat-toolbar class="layout-row">
                <span>{{(editMode ? 'Edit':'Add')}} Account Definition</span>
                <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
            </mat-toolbar>
        </div>
    </mat-card-header>
    
    <mat-card-content>
        <form #accountForm="ngForm" class="modal-body">
            <mat-card-header>General Information</mat-card-header>
            <div class="layout-row layout-align-start-center">
                <mat-form-field class="name topPadding" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput ngModel
                        [(ngModel)]="editAccountSetup.name"
                        #accountName="ngModel"
                        name="accountName"
                        id="accountName"
                        aria-label="Account name"
                        type="text"
                        required>
                        <mat-error *ngIf="accountName.hasError('required')">
                            <div ng-message="required">{{getErrorMessage(accountName,'accountName')}}</div>
                        </mat-error>
                </mat-form-field>
                <mat-form-field class="accountType topPadding" appearance="outline">
                    <mat-label>Account Type</mat-label>
                    <mat-select ngModel 
                        [(ngModel)]="uniqueAccountType"
                        required
                        name="accountType"
                        id="accountType"
                        #accountType="ngModel"
                        [compareWith]="compareFlags"
                        (selectionChange)="uniqueAccountTypeChange(uniqueAccountType)">
                        <mat-option *ngFor="let type of accountTypeOptions;" [value]="type">{{type.label}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="accountType.hasError('required')">
                        <div ng-message="required">{{getErrorMessage(accountType,'accountType')}}</div>
                    </mat-error>
                </mat-form-field>
            </div> 
            <div class="layout-row layout-align-start-center">
                <mat-form-field class="allocationPriority" appearance="outline">
                    <mat-label>Allocation Priority</mat-label>
                    <input matInput ngModel
                        type="number"
                        min="1"
                        aria-label="Allocation Priorty"
                        name="allocationPriortiy"
                        id="allocationPriortiy"
                        #allocationPriortiy="ngModel"
                        (change)="onAllocationPriorityChange(editAccountSetup.creditAllocationPriority)"
                        [(ngModel)]="editAccountSetup.creditAllocationPriority">
                </mat-form-field>
                <div *ngIf="allocationWarning">
                    Priority already exists in account definition: {{allocationWarning}}
                </div>
            </div>
            <div class="layout-row layout-align-start-center">
                <div class="leftPadding section-body">
                    <mat-checkbox ngModel 
                        [(ngModel)]="editAccountSetup.isAutoPostDisabled" 
                        name="disableMonthlyAutoPost"
                        id="disableMonthlyAutoPost"
                        #disableMonthlyAutoPost="ngModel">
                    </mat-checkbox>
                    <mat-label class="wordPadding">Disable Auto Post of Transactions</mat-label>
                </div>
            </div>
            <div *ngIf="uniqueAccountType.flag !== 'isOther'" class="layout-row layout-align-start-center">
                <div class="leftPadding section-body">
                    <mat-checkbox ngModel 
                        [(ngModel)]="editAccountSetup.isAssistance"
                        name="isAssistanceCheck"
                        id="isAssistanceCheck"
                        #isAssistanceCheck="ngModel">
                    </mat-checkbox>
                    <mat-label class="wordPadding">Assistance Account</mat-label>
                </div>
            </div>

            <mat-card-header>Accounting Details</mat-card-header>
            <mat-form-field class="subledgerMapping topPadding" appearance="outline">
                <mat-label>Subledger Mapping</mat-label>
                <mat-select matInput ngModel 
                    [(ngModel)]="editAccountSetup.chartOfAccounts"
                    label="chartOfAccountsName"
                    name="chartOfAccountsName"
                    #chartOfAccountsName="ngModel"
                    [compareWith]="compareValues"
                    (selectionChange)="onChartOfAccountsSelected($event.value)"
                    placeholder="Select Subledger Mapping"
                    required>
                        <mat-option id="ChartOfAccounts{{$index}}" *ngFor="let coa of chartOfAccounts; let $index=index;" [value]="coa">
                            {{ coa.name }}
                        </mat-option>
                </mat-select>
                    <mat-error *ngIf="chartOfAccountsName.hasError('required')">
                        <div ng-message="required">{{getErrorMessage(chartOfAccountsName,'chartOfAccountsName')}}</div>
                    </mat-error>
            </mat-form-field>

            <mat-card-header *ngIf="uniqueAccountType.flag !== 'isOther'">Type Specific Information </mat-card-header>
            <span *ngIf="uniqueAccountType.flag === 'N/A'">No account type selected</span>
            <div *ngIf="uniqueAccountType.flag ==='isRepayment'">
                <div class="layout-row layout-align-start-center">
                    <mat-form-field class="adjustmentEstablishPrincipal" appearance="outline">
                        <mat-label>Adjustment to Establish Principal Balance Sub-Category</mat-label>
                        <mat-select matInput ngModel
                            placeholder="Sub-Category"
                            name="repaymentPrincipalBalanceSubCat"
                            #repaymentPrincipalAdjustmentSubCat="ngModel"
                            [(ngModel)]="editAccountSetup.adjustmentToEstablishPrincipalBalanceSubCategory">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup *ngFor="let cat of this.selectedChartOfAccount.chargeCategories; let $index=index" [label]="cat.name">
                                <mat-option *ngFor="let subcat of cat.subCategories" [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row layout-align-start-center">
                    <mat-form-field class="monthlyReductionSubCat" appearance="outline">
                        <mat-label>Monthly Reduction Sub-Category</mat-label>
                        <mat-select matInput ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.principalReductionSubCategory"
                            name="repaymentPrincipalReductionSubcat"
                            #repaymentPrincipalReductionSubcat="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup *ngFor="let cat of this.selectedChartOfAccount.chargeCategories; let $index=index" [label]="cat.name">
                                <mat-option *ngFor="let subcat of cat.subCategories" [value]="subcat._id">{{ subcat.name }}</mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="defaultTerm" appearance="outline">
                        <mat-label>Default Term</mat-label>
                        <input matInput ngModel
                            type="number"
                            name="repaymentDefaultTerm" 
                            ng-min="0"
                            [(ngModel)]="editAccountSetup.defaultTerms.term" 
                            aria-label="term"
                            #repaymentDefaultTerm="ngModel">
                        <mat-error *ngIf="repaymentDefaultTerm.hasError('number')">
                            <div ng-message="number">{{getErrorMessage(repaymentDefaultTerm,'term')}}</div>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="uniqueAccountType.flag === 'isAR'">
                <div class="layout-column layout-align-start-start" *ngIf="ledgerType == 0">
                    <div class="leftPadding section-body">
                        <mat-checkbox ngModel
                            [(ngModel)]="editAccountSetup.isPrimary"
                            name="isPrimaryCheck"
                            #isPrimaryCheck="ngModel">
                        </mat-checkbox>
                        <mat-label class="wordPadding">Primary Account</mat-label>
                    </div>
                    <div>
                        <span class="headerPadding">The primary account is the main account. This dictates where rent charges will be posted.
                            <br>
                            <strong class="headerPadding">A Program should have at most 1 primary assistance account and 1 primary non-assistance account.</strong>
                        </span>
                    </div>
                </div>
     
                <hr />
                <div class="layout-row layout-align-start-center">
                    <mat-form-field class="lateFeeSubCat" appearance="outline">
                        <mat-label>Late Fee Charge Sub-Category</mat-label>
                        <mat-select matInput ngModel 
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.lateFeeSubCategory"
                            name="repaymentLateFee"
                            [compareWith]="compareValues"
                            #repaymentLateFee="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup 
                                *ngFor="let cat of this.selectedChartOfAccount.chargeCategories let $index=index" 
                                [label]="cat.name">
                                <mat-option 
                                    *ngFor="let subcat of cat.subCategories" 
                                    [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="uniqueAccountType.flag ==='isAccountsPayable'">
                <div class="layout-column layout-align-start-start" *ngIf="ledgerType == 0">
                    <div class="leftPadding section-body">
                        <mat-checkbox ngModel
                            [(ngModel)]="editAccountSetup.isPrimary"
                            name="isPrimaryCheck"
                            #isPrimaryCheck="ngModel">
                        </mat-checkbox>
                        <mat-label class="wordPadding">Primary Account</mat-label>
                    </div>
                    <div>
                        <span class="headerPadding">The primary account is the main account. This dictates where rent charges will be posted.
                            <br>
                            <strong class="headerPadding">A Program should have at most 1 primary assistance account and 1 primary non-assistance account.</strong>
                        </span>
                    </div>
                </div>
         
                <hr />
                <div *ngIf="ledgerType == 1">
                    <mat-form-field class="hapPaymentSubCat" appearance="outline">
                        <mat-label>HAP Payment Sub-Category (if applicable)</mat-label>
                        <mat-select ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.assistancePaymentSubCategory"
                            name="assistanceHapSubact"
                            #assistanceHapSubact="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup 
                                *ngFor="let cat of this.selectedChartOfAccount.chargeCategories let $index=index" 
                                [label]="cat.name">
                                <mat-option 
                                    *ngFor="let subcat of cat.subCategories" 
                                    [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <a (click)="editAccountSetup.assistancePaymentSubCategory = null" class="marg-left-1">clear</a>
                </div>
                <div *ngIf="ledgerType == 2">
                    <mat-form-field class="hapPaymentSubCat" appearance="outline">
                        <mat-label>Utility Allowance Subcategory</mat-label>
                        <mat-select ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.utilityAllowanceSubCategory"
                            name="utilityAllowanceSubCat"
                            #utilityAllowanceSubCat="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup 
                                *ngFor="let cat of this.selectedChartOfAccount.chargeCategories let $index=index" 
                                [label]="cat.name">
                                <mat-option 
                                    *ngFor="let subcat of cat.subCategories" 
                                    [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <a (click)="editAccountSetup.utilityAllowanceSubCategory = null" class="marg-left-1">clear</a>
                </div>
                <div *ngIf="ledgerType != 0">
                    <mat-form-field class="hapPaymentSubCat" appearance="outline">
                        <mat-label>ACH Subcategory</mat-label>
                        <mat-select ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.payableAchSubCategory"
                            name="payableACHSubCat"
                            #payableACHSubCat="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup 
                                *ngFor="let cat of this.selectedChartOfAccount.chargeCategories let $index=index" 
                                [label]="cat.name">
                                <mat-option 
                                    *ngFor="let subcat of cat.subCategories" 
                                    [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <a (click)="editAccountSetup.payableAchSubCategory = null" class="marg-left-1">clear</a>
                </div>
                <div *ngIf="ledgerType != 0">
                    <mat-form-field class="hapPaymentSubCat" appearance="outline">
                        <mat-label>Check Subcategory</mat-label>
                        <mat-select ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.payableCheckSubCategory"
                            name="payableCheckSubCat"
                            #payableCheckSubCat="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup 
                                *ngFor="let cat of this.selectedChartOfAccount.chargeCategories let $index=index" 
                                [label]="cat.name">
                                <mat-option 
                                    *ngFor="let subcat of cat.subCategories" 
                                    [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <a (click)="editAccountSetup.payableCheckSubCategory = null" class="marg-left-1">clear</a>
                </div>
                <div *ngIf="ledgerType != 0">
                    <mat-form-field class="hapPaymentSubCat" appearance="outline">
                        <mat-label>Other/Export Subcategory</mat-label>
                        <mat-select ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.payableOtherSubCategory"
                            name="payableOtherSubCat"
                            #payableOtherSubCat="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup 
                                *ngFor="let cat of this.selectedChartOfAccount.chargeCategories let $index=index" 
                                [label]="cat.name">
                                <mat-option 
                                    *ngFor="let subcat of cat.subCategories" 
                                    [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <a (click)="editAccountSetup.payableOtherSubCategory = null" class="marg-left-1">clear</a>
                </div>
            </div>

            <div *ngIf="uniqueAccountType.flag === 'isPrincipal'">
                <hr class="throughTout" />
                <div class="layout-row layout-align-start-center">
                    <div class="leftPadding section-body">
                        <mat-checkbox ngModel 
                            (click)="editAccountSetup.isDeposit = false; editAccountSetup.isBadDebt = false;"
                            [(ngModel)]="editAccountSetup.isPrincipal"
                            name="principalCheck"
                            #principalCheck="ngModel">
                        </mat-checkbox>
                        <mat-label class="wordPadding">Principal Account</mat-label>
                    </div>
                </div>
                <div class="layout-row layout-align-start-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Post Principal Charge To</mat-label>
                        <mat-select matInput ngModel
                            [(ngModel)]="principalChargeDestination"
                            placeholder="Select Account"
                            name="principalChargePostDestination"
                            (selectionChange)="onPrincipleChargeAccountSelected(principalChargeDestination.chartOfAccounts)"
                            #principalChargePostDestination="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let ad of this.program.accountDefinitions" [value]="ad">{{ ad.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="principalChargeDestination">
                        <mat-label>Post Principal Charge Category</mat-label>
                        <mat-select matInput ngModel
                            [(ngModel)]="principalChargeCategory"
                            placeholder="Select Category"
                            name="principalPostChargeCategory"
                            #principalPostChargeCategory="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let ad of this.possiblePrincipalChargeCategories" [value]="ad">{{ ad.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="principalChargeCategory">
                        <mat-label>Principal Charge Sub-Category</mat-label>
                        <mat-select matInput ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.principalChargeSubCategory"
                            name="principalChargeSubcat"
                            #principalChargePostSubcat="ngModel">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let subcat of principalChargeCategory.subCategories" [value]="subcat._id">{{ subcat.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row layout-align-start-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Post Interest Charges To</mat-label>
                        <mat-select matInput ngModel
                            [(ngModel)]="interestChargeDestination"
                            placeholder="Select Account" 
                            name="interestChargePostDestination"
                            (selectionChange)="onInterestChargeAccountSelected(interestChargeDestination.chartOfAccounts)"                     
                            #interestChargePostDestination="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let ad of this.program.accountDefinitions" [value]="ad">{{ ad.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="interestChargeDestination">
                        <mat-label>Post Interest Charges Category</mat-label>
                        <mat-select matInput ngModel
                            [(ngModel)]="interestChargeCategory"
                            placeholder="Select Account" 
                            name="interestChargePostCategory"
                            #interestChargePostCategory="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let ad of this.possibleInterestChargeCategories" [value]="ad">{{ ad.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="interestChargeCategory">
                        <mat-label>Interest Charge Sub-Category</mat-label>
                        <mat-select matInput ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.interestChargeSubCategory"
                            name="interestChargePostSubcat"
                            #interestChargePostSubcat="ngModel">
                            <mat-option label="None">
                                <mat-option [value]="null">None</mat-option>
                            </mat-option>
                                <mat-option *ngFor="let subcat of interestChargeCategory.subCategories" [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row layout-align-start-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Auto Principal Reduction Sub-Category</mat-label>
                        <mat-select ngModel
                            placeholder="Sub-Category"
                            [(ngModel)]="editAccountSetup.principalReductionSubCategory"
                            name="principalAutoReductionSubcat"
                            #principalAutoReductionSubcat="ngModel">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup *ngFor="let cat of this.selectedChartOfAccount.chargeCategories" [label]="cat.name">
                                <mat-option *ngFor="let subcat of cat.subCategories" [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Adjustment to Establish Principal Balance Sub-Category</mat-label>
                        <mat-select matInput ngModel
                            placeholder="Sub-Category"
                            name="principalBalanceAjustmentSubcat"
                            #principalBalanceAjustmentSubcat="ngModel"
                            [(ngModel)]="editAccountSetup.adjustmentToEstablishPrincipalBalanceSubCategory">
                            <mat-option [value]="null">None</mat-option>
                            <mat-optgroup *ngFor="let cat of this.selectedChartOfAccount.chargeCategories" [label]="cat.name">
                                <mat-option *ngFor="let subcat of cat.subCategories" [value]="subcat._id">
                                    {{ subcat.name }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row layout-align-start-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Equity Accounts</mat-label>
                        <mat-select ngModel
                            multiple="true"
                            [(ngModel)]="editAccountSetup.equityAccountDefinitions"
                            name="principalEquityAccount"
                            #principalEquityAccount="ngModel">
                            <mat-option *ngFor="let def of this.program.accountDefinitions; let $index=index" [value]="def._id">{{def.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Mepa Account</mat-label>
                        <mat-select ngModel
                            [(ngModel)]="editAccountSetup.mepaAccountDefinition"
                            placeholder="Select Account"
                            name="principalMepaAccount"
                            #principalMepaAccount="ngModel">
                            <mat-option *ngFor="let def of this.program.accountDefinitions; let $index=index" [value]="def._id">{{def.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="layout-row layout-align-start-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Default Interest Rate</mat-label>
                        <input matInput ngModel
                            type="number"
                            name="interestRate"
                            #interestRate="ngModel"
                            ng-min="0"
                            [(ngModel)]="editAccountSetup.defaultTerms.rate"
                            aria-label="interestRate amount">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Default Term</mat-label>
                        <input matInput ngModel
                            type="number"
                            name="principleTerm"
                            #principleTerm="ngModel"
                            ng-min="0"
                            [(ngModel)]="editAccountSetup.defaultTerms.term"
                            aria-label="term">
                    </mat-form-field>
                </div>
            </div>
        
            <mat-card-actions>
                <button mat-flat-button class="hds_button cancel" (click)="cancel()" id="cancelButton">Cancel</button>
                <button mat-flat-button class="hds_button" (click)="update()" id="saveButton"
                [disabled]="!accountForm.valid || accountForm.pristine">Save</button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>