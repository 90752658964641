import {Component, OnInit, OnDestroy, Injector} from '@angular/core';
import {slideUpFade} from '../../shared/animations/div-slide';
import {VoucherState, VoucherRFTA} from '../vouchers-models/voucher';
import {MatDialog} from '@angular/material/dialog';
import {VouchersAddEditComponent} from '../vouchers-addedit/vouchers-addedit.component';
import {VouchersAddEditrftaComponent} from '../vouchers-addeditrfta/vouchers-addeditrfta.component';
import {VouchersIssueWizardComponent} from '../vouchers-issue-wizard/vouchers-issue-wizard.component';
import * as moment from 'moment';
import {VoucherService} from '../vouchers-services/vouchers.service';
import {VendorService} from 'src/app/custom/vendor/vendor.service';
import {Query, RequestForTenancyApproval, Voucher} from 'src/generated/graphql';
import {IVoucherStates, VoucherStates} from './interfaces';
import {FeatureConfigurationService} from 'src/app/shared/services';
import {from, Subscription} from 'rxjs';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {CoreService} from 'src/app/core/service/core.service';
@Component({
  selector: 'app-vouchers-overview',
  templateUrl: './vouchers-overview.component.html',
  styleUrls: ['./vouchers-overview.component.scss'],
  animations: [slideUpFade],
})
export class VouchersOverviewComponent implements OnInit, OnDestroy {
  customerId: string;
  siteId: string;
  private subscriptions: Subscription = new Subscription();
  title = 'Vouchers Overview';
  showpanel = 'overview';
  showpanelview = 'details';
  selectedVoucher: Voucher = null;
  tenancyRequests: RequestForTenancyApproval[] = [];
  currentVouchers: Voucher[] = [];
  showEditVoucherButton = false;
  VoucherState = VoucherState;
  householdId = null;
  voucherHasUnit = false;
  issuedVoucher = false;
  voucherList = [];
  voucherEventHistory = [];
  voucherExpired = false;
  fundingPrograms = [];
  userPermissions = [];
  failure = false;
  overlay = false;
  spinnerCheck = false;
  loading = false;
  enableSetup2: boolean;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  loggedInUser;

  columnsSelectedVoucherRFTA = [
    {
      columnDef: 'unit',
      header: 'Unit',
      cell: (): string | null => `${this.selectedVoucher.unit ? this.selectedVoucher.unit.streetAddress : null}`,
    },
    {
      columnDef: 'landlord',
      header: 'Landlord',
      cell: (): string =>
        `${this.selectedVoucher.unit?.landlord?.companyName ? this.selectedVoucher.unit.landlord.companyName : ''} 
          ${this.selectedVoucher.unit?.landlord?.firstName ? this.selectedVoucher.unit.landlord.firstName : ''} ${
          this.selectedVoucher.unit?.landlord?.lastName ? this.selectedVoucher.unit.landlord.lastName : ''
        }`,
    },
    {
      columnDef: 'requestStartOn',
      header: 'RFTA Start',
      cell: (element: {requestStartOn: string}): string =>
        `${moment(element.requestStartOn)
          .add(1, 'days')
          .format('MM-DD-YYYY')}`,
    },
    {
      columnDef: 'requestStopOn',
      header: 'RFTA Stop',
      cell: (element: {requestStopOn: string}): string =>
        `${
          element.requestStopOn
            ? moment(element.requestStopOn)
                .add(1, 'days')
                .format('MM-DD-YYYY')
            : 'N/A'
        }`,
    },
    {
      columnDef: 'result',
      header: 'Result',
      cell: (element: {result: 'AWAITING_APPROVAL' | 'APPROVED' | 'DENIED'}): string => {
        if (element.result === 'AWAITING_APPROVAL') {
          return `Waiting`;
        } else if (element.result === 'APPROVED') {
          return `Approved`;
        } else if (element.result === 'DENIED') {
          return `Denied`;
        } else {
          return `${element.result}`;
        }
      },
    },
    {
      columnDef: 'days',
      header: 'Days',
      cell: (element: {requestStartOn: Date; requestStopOn: Date}): number =>
        this.updateIssuedDays(element.requestStartOn, element.requestStopOn),
    },
    {
      columnDef: 'modifiedOn',
      header: 'Modified On',
      cell: (element: {modifiedOn: string; createdOn: string}): string =>
        `${element.modifiedOn ? moment(element.modifiedOn).format('MM-DD-YYYY') : moment(element.createdOn).format('MM-DD-YYYY')}`,
    },
    {
      columnDef: 'resultComments',
      header: 'Reason',
      cell: (element: {resultComments: string | null}): string => `${element.resultComments ? element.resultComments : ''}`,
    },
  ];

  displayedColumnsSelectedVoucherRFTA = this.columnsSelectedVoucherRFTA.map(c => c.columnDef);
  rftaList: VoucherRFTA[];

  /**
   * Function that assists in determining what the display text should look like for the displayed text
   *
   * @param stateValue {string} Voucher State Value
   * @returns {string} Display string for given voucher history
   */
  determineVoucherDisplayText(stateValue: string): string {
    const displayTexts = {
      [VoucherStates.ISSUED]: 'Issued',
      [VoucherStates.LEASED]: 'Leased',
      [VoucherStates.SHOP_MODE]: 'Shop Mode',
      [VoucherStates.TERMINATED_WITH_OUT_LEASING]: 'Terminated Without Leasing',
      [VoucherStates.TERMINATED]: 'Terminated',
      default: stateValue,
    };

    return displayTexts[stateValue] || displayTexts.default;
  }

  columnsSelectedVoucherHistory = [
    {
      columnDef: 'stateValue',
      header: 'Status',
      cell: (element: {stateValue: IVoucherStates}): string => this.determineVoucherDisplayText(element.stateValue),
    },
    {
      columnDef: 'effectiveDate',
      header: 'Date',
      cell: (element: {effectiveDate: string}): string => `${moment.utc(element.effectiveDate).format('MM-DD-YYYY')}`,
    },
    {columnDef: 'changedBy', header: 'User', cell: (element: {changedBy: string}): string => `${element.changedBy}`},
  ];

  displayedColumnsSelectedVoucherHistory = this.columnsSelectedVoucherHistory.map(c => c.columnDef);

  columnsHouseholdVoucherHistory = [
    {columnDef: 'voucherNumber', header: 'Voucher Number', cell: (element: {voucherNumber: string}): string => `${element.voucherNumber}`},
    {
      columnDef: 'issuedDate',
      header: 'Issued Date',
      cell: (element: {issuedOn: string}): string =>
        `${moment(element.issuedOn)
          .add(1, 'days')
          .format('MM/DD/YYYY')}`,
    },
    {
      columnDef: 'state',
      header: 'Status',
      cell: (element: {state: IVoucherStates}): string => this.determineVoucherDisplayText(element.state),
    },
  ];

  displayedHouseholdVoucherHistory = this.columnsHouseholdVoucherHistory.map(c => c.columnDef) as Array<any>;
  rates: any;
  error: any;

  constructor(
    public dialog: MatDialog,
    private voucherService: VoucherService,
    private vendorService: VendorService,
    private coreService: CoreService,
    private featureConfigService: FeatureConfigurationService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
  }

  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.userPermissions = JSON.parse(localStorage.getItem('UserPermissions'));
    // parse the url to get the household ID
    const urlArray = window.location.href.split('/');
    this.householdId = urlArray[4]; //5th item in array is unit id
    this.updateUI();
    this.displayedHouseholdVoucherHistory.push('actions'); //NOTE: This has to be defined after the other columns are added since it is not a part of the forloop in html
    this.displayedColumnsSelectedVoucherRFTA.push('actions');
    this.checkConfiguration();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  selectHistoryVoucher(voucher: Voucher): void {
    this.spinnerCheck = true;
    this.overlay = true;
    this.tenancyRequests.length = 0;
    this.selectedVoucher = voucher;
    this.voucherEventHistory = voucher.voucherStateChangeEvents;
    const voucherCreatedEntry = {
      changedBy: voucher.createdBy,
      changedOn: moment(voucher.issuedOn)
        .add(1, 'days')
        .format('MM-DD-YYYY'),
      stateDisplay: 'Issued',
      stateValue: 'ISSUED',
      effectiveDate: moment(voucher.issuedOn)
        .add(1, 'days')
        .format('MM-DD-YYYY'),
    };
    this.voucherEventHistory = this.voucherEventHistory.sort((a, b) => +moment(a.effectiveDate) - +moment(b.effectiveDate));
    this.voucherEventHistory.push(voucherCreatedEntry);
    if (this.selectedVoucher.unitId) {
      this.voucherService.getUnit(this.selectedVoucher.unitId).subscribe((result: any) => {
        this.selectedVoucher = this.getUnitData(this.selectedVoucher, result);
        this.filterTenacyRequestsByDeleted(voucher);
        this.showEditVoucherButton = false;
        this.spinnerCheck = false;
        this.overlay = false;
        this.showpanel = 'viewvoucher';
      });
    } else {
      this.filterTenacyRequestsByDeleted(voucher);
      this.showEditVoucherButton = false;
      this.overlay = false;
      this.spinnerCheck = false;
      this.showpanel = 'viewvoucher';
    }
  }

  selectedIssuedVoucher(voucher: Voucher): void {
    this.overlay = true;
    this.spinnerCheck = true;
    this.tenancyRequests.length = 0;
    this.selectedVoucher = voucher;
    this.issuedVoucher = true;
    this.voucherEventHistory = voucher.voucherStateChangeEvents;
    const voucherCreatedEntry = {
      changedBy: voucher.createdBy,
      changedOn: moment(voucher.issuedOn)
        .add(1, 'days')
        .format('MM-DD-YYYY'),
      stateDisplay: 'Issued',
      stateValue: 'ISSUED',
      effectiveDate: moment(voucher.issuedOn)
        .add(1, 'days')
        .format('MM-DD-YYYY'),
      displayOnly: true,
    };
    this.voucherEventHistory = this.voucherEventHistory.sort((b, a) => +moment(a.effectiveDate) - +moment(b.effectiveDate));
    const createdEntryCheck = this.voucherEventHistory.find(history => history.displayOnly === true);
    if (!createdEntryCheck) {
      this.voucherEventHistory.push(voucherCreatedEntry);
    }
    if (this.selectedVoucher.unitId) {
      this.voucherHasUnit = true;
      this.voucherService.getUnit(this.selectedVoucher.unitId).subscribe((result: any) => {
        this.selectedVoucher = this.getUnitData(this.selectedVoucher, result);
        this.filterTenacyRequestsByDeleted(voucher);
        this.showEditVoucherButton = true;
        this.overlay = false;
        this.spinnerCheck = false;
        this.showpanel = 'viewvoucher';
      });
    } else {
      this.voucherHasUnit = false;
      this.filterTenacyRequestsByDeleted(voucher);
      this.showEditVoucherButton = true;
      this.overlay = false;
      this.spinnerCheck = false;
      this.showpanel = 'viewvoucher';
    }
  }

  checkConfiguration() {
    this.featureConfigService.getConfig('enableNewSetup').subscribe(
      configObject => {
        this.enableSetup2 = configObject.response.value;
      },
      error => {
        console.log('there was an error checking setup 2.0 configuration', error);
      }
    );
  }

  getUnitData(selectedVoucher, result) {
    const unitData = result.data.units.nodes[0];
    if (unitData.landlordId) {
      this.getLandlordData(selectedVoucher, unitData);
    } else {
      selectedVoucher.unit.landlord = null;
    }
    return selectedVoucher;
  }

  async getLandlordData(selectedVoucher, unit) {
    if (this.enableSetup2) {
      await this.vendorService.getSelectedVendor(unit.landlordId).subscribe((result: any) => {
        selectedVoucher.unit.landlord = result;
      });
    } else {
      await this.voucherService.getLandlordInfo(unit.landlordId).subscribe((result: any) => {
        selectedVoucher.unit.landlord = result.data.landlordById;
      });
    }
    return selectedVoucher;
  }

  filterTenacyRequestsByDeleted(voucher: Voucher): void {
    this.tenancyRequests = [];
    for (const request of voucher.tenancyRequests) {
      if (!request.archivedOn) {
        this.tenancyRequests.push(request as any);
      }
    }
  }

  getCurrentUsersLogInCookie(): string {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    return arrayOfCookieStrings[1];
  }

  deleteRFTA(rfta: RequestForTenancyApproval): void {
    const command = {
      rftaId: rfta.id,
      deletedBy: this.getCurrentUsersLogInCookie(),
      customerId: this.customerId,
      siteId: this.siteId,
    };

    this.voucherService.deleteRFTA(command).subscribe(
      (result: any) => {
        if (result.data.deleteRfta.status === 'SUCCESS') {
          //lets try to return the entire voucher in teh future
          // this.selectedVoucher = null;
          // this.selectedVoucher = result.data.deleteRfta.affectedEntity.voucher;
          // why do we do all this? because page reloads suck
          // create a temp array of rftas
          const rftasArray = this.selectedVoucher.tenancyRequests;
          //find it by index
          const index = rftasArray.findIndex(function(o) {
            return o.id === rfta.id;
          });
          //remove from the temp array
          if (index !== -1) {
            rftasArray.splice(index, 1);
          }
          //reset tenancy requests for that voucher
          this.selectedVoucher.tenancyRequests = [];
          //reset the array wihtout the deleted item
          this.selectedVoucher.tenancyRequests = rftasArray;
          //create the fake rfta with the archived flag (deleted)
          const fakeRfta = {...rfta, archivedOn: new Date()};
          //push it into the new array
          this.selectedVoucher.tenancyRequests.push(fakeRfta);
          //filter the deleted flag for the UI
          this.filterTenacyRequestsByDeleted(this.selectedVoucher);
        }
      },
      error => {
        console.log('there was an error sending the mutation', error);
      }
    );
  }

  async updateUI(): Promise<void> {
    this.loading = true;
    this.voucherService.getVoucherByHouseHoldId(this.householdId).subscribe(
      async (result: any) => {
        const query: Query = await result?.data;
        const historyArray = [];
        const edges = query.vouchers.edges;
        for (const voucherEdge of edges) {
          if (voucherEdge.node.state === 'ISSUED' || voucherEdge.node.state === 'LEASED' || voucherEdge.node.state === 'SHOP_MODE') {
            //set current active voucher
            this.currentVouchers.push(voucherEdge.node);
          } else {
            //make the historical list
            historyArray.push(voucherEdge.node);
          }
        }
        // add the array to the datasource for the historical view
        this.voucherList = [...historyArray];

        this.loading = false;
      },
      (error: any) => {
        console.log(error);
        this.failure = true;
        this.loading = false;
      }
    );

    //prefetch this because it takes way to long on the add/edit screen
    this.voucherService.getProgramsAndProjects().subscribe(async (result: any) => {
      this.fundingPrograms = await result.data.programs.edges;
    });
  }

  /**
   * Function that generates when a voucher is considered historical.
   *
   * This is leveraged to handle finding out if the write action buttons for vouchers
   * should be disabled or not. Check CSS class isDisabled.
   *
   * @param state voucher's state from the data
   * @returns {boolean} If the voucher is considered historical or current
   */
  isHistoricalVoucher(state: IVoucherStates): boolean {
    const currentVoucherStates: IVoucherStates[] = [VoucherStates.ISSUED, VoucherStates.LEASED];

    return !currentVoucherStates.includes(state);
  }

  /**
   * Function that generates a predicate when a new RFTA record button should not be enabled for a given voucher.
   *
   * Reaching for the current ticket consideration:
   * - Add new RFTA button is grayed out after one has already been added
   * - One open RFTA at a time. If the RFTA has a result/end date then it is considered "complete" and the user can add a new one.
   *
   * @returns {boolean} If the Adding New RFTA Record button needs to be disabled.
   */
  shouldDisableAddingNewRFTARecord(): boolean {
    if (this.tenancyRequests.filter(request => request.result === 'AWAITING_APPROVAL').length > 0) {
      return true;
    }
    if (!this.voucherHasUnit) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function that generates a predicate to ascertain what is the latest RFTA present on a given voucher.
   * 
   * The function sorts all tenancy requests by "createdOn" descending 
   * Finds the index of a given voucher inside the array and then validates if the foundRFTA's index matches the zeroth position.
   * 
   * This function is only present currently on the "edit" state of the requests and not "delete"
   * 
   * @param rfta {VoucherRFTA} Tenancy Approval Request

   * @returns {boolean}
   */
  isLatestRTFA(rfta: VoucherRFTA): boolean {
    const sortedByCreatedOnDateTenancyRequestsIds = this.tenancyRequests.sort((a, b) => a.createdOn - b.createdOn).map(record => record.id);

    const foundRFTA = sortedByCreatedOnDateTenancyRequestsIds.findIndex(entry => entry === rfta.id);

    return foundRFTA === 0;
  }

  updateIssuedDays(IssuedDate: 'undefined' | null | Date, ExpirationDate: 'undefined' | null | Date): number {
    let issuedDays = 0;

    if (typeof IssuedDate == 'undefined' || IssuedDate == null) {
      return 0;
    }
    if (typeof ExpirationDate == 'undefined' || ExpirationDate == null) {
      return 0;
    }

    const date1 = moment(IssuedDate).startOf('day'); //use start of day for calculation (round down)
    const date2 = moment(ExpirationDate);

    if (date1.isSameOrAfter(date2)) {
      return 0;
    }

    issuedDays = date2.diff(date1, 'days');

    return issuedDays;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showEditVoucher(newOrEdit: any): void {
    //used for autoincrementing voucher number
    const totalVouchersForHousehold = this.voucherList.length + 1;
    const dialogRef = this.dialog.open(VouchersAddEditComponent, {
      width: '100%',
      data: {
        Voucher: newOrEdit == 'new' ? {} : (this.selectedVoucher as Voucher),
        totalVouchers: totalVouchersForHousehold,
        houseHoldId: this.householdId,
        Init: newOrEdit,
        programs: this.fundingPrograms,
      },
      disableClose: true, //just for disabling the outside click to close a dialog, that way they don't accidenally lose their data
    });

    //after it closes start the component over
    dialogRef.afterClosed().subscribe(() => {
      this.currentVouchers = [];
      this.voucherList.length = 0;
      this.showpanel = 'overview';
      this.updateUI();
    });
  }

  issueVoucherWizard(): void {
    //used for autoincrementing voucher number
    const totalVouchersForHousehold = this.voucherList.length + 1;
    this.globalModalService.openModal<any>(VouchersIssueWizardComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      totalVouchers: totalVouchersForHousehold,
      householdId: this.householdId,
      programs: this.fundingPrograms,
      updateFunction: this.issueNewVoucher,
    });
  }

  issueNewVoucher = voucherFormValue => {
    this.loading = true;
    if (voucherFormValue.voucherType === 'Tenant Based') {
      voucherFormValue.voucherType = 'TENANT_BASED';
    }
    if (voucherFormValue.voucherType === 'Project Based') {
      voucherFormValue.voucherType = 'PROJECT_BASED';
    }
    if (voucherFormValue.voucherType === 'Homeownership') {
      voucherFormValue.voucherType = 'HOME_OWNERSHIP';
    }
    const command = {
      fundingProgramId: voucherFormValue.fundingProgram.id,
      siteId: sessionStorage.getItem('SITEID'),
      customerId: sessionStorage.getItem('CUSTOMERID'),
      fundingProjectId: voucherFormValue.fundingProject.id,
      voucherNumber: voucherFormValue.voucherNumber,
      householdId: this.householdId, //always set
      issuedOn: moment(voucherFormValue.issuedOn).format('MM-DD-YYYY'),
      expiresOn: moment(voucherFormValue.expiresOn).format('MM-DD-YYYY'),
      createdBy: this.loggedInUser ? this.loggedInUser : 'voucher form', //we need to save the user in cookies or session storage to pull this value
      authorizedBedrooms: parseInt(voucherFormValue.authorizedBedrooms),
      unitId: voucherFormValue.unit?.node?.id,
      voucherType: voucherFormValue.voucherType,
      specialType: voucherFormValue.specialType,
      enhancedVoucher: voucherFormValue.enhancedVoucher,
      protectionVoucher: voucherFormValue.protectionVoucher,
      enhancedMinimumRent: voucherFormValue.enhancedMinimumRent,
    };
    this.voucherService.createVoucher(command).subscribe(
      (result: any) => {
        if (result.data.createVoucher.status === 'SUCCESS') {
          this.currentVouchers = [];
          this.voucherList.length = 0;
          this.updateUI();
        } else {
          this.coreService.displayError('There was an error creating new Voucher');
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.coreService.displayError('There was an error creating new Voucher');
        console.log('there was an error sending the mutation', error);
      }
    );
  };

  checkIfVouchersAreExpired(voucher: Voucher): boolean {
    //gotta loop through the vouchers here and set expired values now
    const daysLeftUntilExpired = this.updateIssuedDays(new Date(), moment(this.dateToDisplay(voucher.expiresOn)).toDate());

    //we dont set a status, only tell the user its expired
    if (daysLeftUntilExpired == 0) {
      return true;
    } else {
      return false;
    }
  }

  dateToDisplay(date: string): string {
    if (date) {
      return moment(date)
        .add(1, 'days')
        .format('MM/DD/yyyy')
        .toString();
    } else {
      return 'N/A';
    }
  }

  unitNameDisplay(unit) {
    if (unit && unit.streetAddress) {
      let fullAddress: string = unit.streetAddress;
      if (unit.apartmentNumber) {
        fullAddress = fullAddress.concat(', Apt: ' + unit.apartmentNumber);
      }
      if (unit.unitNumber) {
        fullAddress = fullAddress.concat(', Unit: ' + unit.unitNumber);
      }
      return fullAddress;
    } else {
      return '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showEditRFTADialog(data: any, isEditMode: boolean): void {
    const dialogRef = this.dialog.open(VouchersAddEditrftaComponent, {
      width: '80%',
      data: {RFTA: data, voucher: this.selectedVoucher, editMode: isEditMode},
      disableClose: true, //just for disabling the outside click to close a dialog, that way they don't accidenally lose their data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const daystoIncrease = result.daysToIncrease;
        if (daystoIncrease) {
          const command = {
            voucherId: this.selectedVoucher.id,
            customerId: this.customerId,
            siteId: this.siteId,
            fundingProjectId: this.selectedVoucher.project.id,
            fundingProgramId: this.selectedVoucher.program.id,
            voucherNumber: this.selectedVoucher.voucherNumber,
            unitId: this.selectedVoucher.unitId,
            expiresOn: moment(this.selectedVoucher.expiresOn)
              .add(daystoIncrease + 1, 'days')
              .format('MM-DD-YYYY'),
            modifiedBy: this.getCurrentUsersLogInCookie(), //we need to save the user in cookies or session storage to pull this value
          };
          this.voucherService.editVoucher(command).subscribe(
            (result: any) => {
              if (result.data.editVoucher.status === 'SUCCESS') {
                this.showpanel = 'overview';
                //reset the current vouchers after save otherwise we get a duplicate
                this.currentVouchers.length = 0;
                this.updateUI();
              }
            },
            error => {
              console.log('there was an error sending the mutation', error);
            }
          );
        }

        const createdRFTA: RequestForTenancyApproval = result.rfta;
        // reset the selected voucher for the UI
        if (!isEditMode) {
          this.selectedVoucher.tenancyRequests = [createdRFTA, ...this.selectedVoucher.tenancyRequests];
        } else {
          let i = 0;
          for (const tenancyRequests of this.selectedVoucher.tenancyRequests) {
            if (data.id === tenancyRequests.id) {
              //find and take the old tentacy request out of the array
              this.selectedVoucher.tenancyRequests.splice(i, 1);
            }
            i++;
          }
          //we took the old unedited item out now lets put the new one in
          this.selectedVoucher.tenancyRequests = [createdRFTA, ...this.selectedVoucher.tenancyRequests];
        }
        this.filterTenacyRequestsByDeleted(this.selectedVoucher);
      }
    });
  }
}
